import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-my-wallet-assets',
  templateUrl: './my-wallet-assets.component.html',
  styleUrls: ['./my-wallet-assets.component.css']
})
export class MyWalletAssetsComponent implements OnInit {
  profiledetails: any;
  loginHistoryList: any;

  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.convertSmallAssets()
  }


  // Get login history List Functionality
  getLoginHistory() {
    var url = `wallet/wallet/Usd-Amount-List`;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.loginHistoryList = res['data']

      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }
  totalAmount: any
  convertDataArray: any = []
  amntTotal: any
  convertSmallAssets() {
    let url = `wallet/wallet/Usd-Amount`
    this.service.postApi(url, {}).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.convertDataArray = res["data"];
          // let sum =0 
          // for(let i =0 ;i<this.convertDataArray.length;i++){
          //  sum = sum+this.convertDataArray[i].usdAmount

          // }
          // console.log(sum);
          // this.amntTotal = sum

          // this.totalAmount  = sum*0.00000017
          // console.log(this.totalAmount);

          // this.router.navigate(['/assets'])
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Server error");
      }
    );
  }
  stectedInput: any = []
  selectedCoin: any = []
  selectCoin(isChecked, coin) {
    console.log(isChecked);
    console.log(coin);
    let coinName = coin.coinName
    if (isChecked) {
      this.selectedCoin.push(coinName)

    } else {
      let index = this.selectedCoin.indexOf(coinName);
      if (index > -1) {

        this.selectedCoin.splice(index, 1);
      }
    }
    console.log(this.selectedCoin);
    this.calculateTotalPrice(isChecked, coin)
  }
  selectAllCoin(isChecked) {
    this.selectedCoin = []
    if (isChecked) {
      let i = 0
      for (let item of this.convertDataArray) {
        this.selectedCoin.push(item.coinName)
        this.stectedInput[i] = true
        i++
      }
    }
    else {
      let i = 0
      for (let item of this.convertDataArray) {

        this.stectedInput[i] = false
        i++
      }
    }
    this.calculateTotalPriceSelectAll();

  }

  convertIntoXIBNDIA() {
    let url = `wallet/wallet/Usd-Amount-Total-ParticularUser`
    let usd = {
      coinName: this.selectedCoin
    }
    this.service.showSpinner();
    this.service.postApi(url, this.selectedCoin).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.showSuccessMessage('Convert small assets to XINDIA token successfully');

this.convertSmallAssets()
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }
    )
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }


  total = 0;
  totalWithXIndia = 0;
  calculateTotalPrice(isChecked, coinDetails) {

    console.log(coinDetails);
    if (isChecked) {
      this.total = this.total + coinDetails.usdAmount
    }
    else {
      this.total = this.total - coinDetails.usdAmount
    }
    console.log(this.total);
    this.totalWithXIndia = this.total * 0.00000017
    console.log(this.totalWithXIndia);

  }

  calculateTotalPriceSelectAll() {
    this.total = 0
    this.totalWithXIndia = 0
    for (let i = 0; i < this.convertDataArray.length; i++) {
      this.total = this.total + this.convertDataArray[i].usdAmount
    }
    console.log(this.total);
    this.totalWithXIndia = this.total * 0.00000017
    console.log(this.totalWithXIndia);
  }
}
