import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-career-jobs-details',
  templateUrl: './career-jobs-details.component.html',
  styleUrls: ['./career-jobs-details.component.css']
})
export class CareerJobsDetailsComponent implements OnInit {
careerSubmitForm:FormGroup;
imageUrl : any
subTeamId: any;

  constructor(private activatedroute: ActivatedRoute,public service : ServiceService, private router:Router) { 
    window.scrollTo(0,0)

  }

  ngOnInit() {
    this.careerFormValidationForm()
    this.activatedroute.queryParams.subscribe((res) => {
      this.subTeamId = res.subTeamId;
    })
    this.viewBlog()
  }
careerFormValidationForm(){
  this.careerSubmitForm = new FormGroup({
    'description': new FormControl(''),
    'image': new FormControl(''),
    'fullName' : new FormControl('',Validators.required),
    'email' :new FormControl('',Validators.required),
    'mobileNumber'  :new FormControl('',Validators.required),
    'currentCompany' :new FormControl('',Validators.required),
    'links' : new FormControl('') 
  })
}


  addSwatches() {
  
    // this.router.navigate(['/swatches/list-swatches'])
    let url = `static/add-sub-category-form`
    let data = {
      // 'author': this.addSwatchesForm.value.author,
      'additionalInformation': this.careerSubmitForm.value.description,
      // 'resume' :this.careerSubmitForm.value.description,
      'currentCompany':this.careerSubmitForm.value.currentCompany,
      'email':this.careerSubmitForm.value.email,
      'mobileNumber':this.careerSubmitForm.value.mobileNumber,
      'fullName':this.careerSubmitForm.value.fullName,
      'resumeurl':this.careerSubmitForm.value.links,
      
      // 'description': this.addSwatchesForm.value.description,
      // 'quantity': this.addSwatchesForm.value.quantity,
      'resume' : this.imageUrl,
      // "careerStatus": "ACTIVE"
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms(url,data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.service.showSuccessMessage(res.message);
        this.router.navigate(['/carrier-data'])
      } else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    })
  }



 

   // Image Functionality Start Here
   uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        // this.service.onLogout();
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }


  subCategoryArray:any = []
  totalItems:any
   // -------------------- get category list --------------------- //
   // edit faq english
 viewBlog(){
  this.service.showSpinner()
this.service.getCandyPixelForms("static/get-details-by-subCategoryId?subTeamId="+this.subTeamId).subscribe((res:any)=>{
  if (res.status=200) {
    console.log('jjh', res);
    this.subCategoryArray=res.data[0];
    this.service.hideSpinner()

  }
 },err=>{

   this.service.hideSpinner();
   if(err['status']=='401'){
    //  this.service.onLogout();
     this.service.showErrorMessage('Unauthorized Access');
   }else{
   this.service.showErrorMessage('Something Went Wrong');
}
 })

} 
}
