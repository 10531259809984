import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-user-admin-chat',
  templateUrl: './user-admin-chat.component.html',
  styleUrls: ['./user-admin-chat.component.css']
})
export class UserAdminChatComponent implements OnInit {
 @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  showDispute: boolean = false;
  tradeId: any;
  toUserId: any;
  cancelReason: boolean = false;
  feedbackForm: FormGroup;
  myId: any;
  // userName: string;
  tradeType: any;
  minutes: number;
  peerToPeerExchangeId: any;

  obj: any = {};
  myImage: any;
  currentTime: any
  x: any;

  exchangeDetails: any
  tradePartner: any;
  profileData: any;

  cancelPurchaseReasonForm: FormGroup;
  cancelPurchaseSectionStatus: boolean = false;
  updateFeedbackForm: FormGroup;
  feedbackId: any

  currentDate: Date;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public server: ServiceService, public appC: AppComponent) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.exchangeDetails = this.router.getCurrentNavigation().extras.state.exchangeDetails;
        localStorage.setItem('exchangeDetails', this.exchangeDetails)
        this.exchangeDetails = JSON.parse(this.exchangeDetails)
      } else {
        this.exchangeDetails = JSON.parse(localStorage.getItem('exchangeDetails'))
      }
    });


    this.server.getMessage().subscribe((res) => {
      this.scrollToBottom();
      if (res.text.message) {
        if (res.text.message.includes("MADE THE PAYMENT") && this.tradeType == 'BUY') {
          this.showDispute = true;
        } else if (res.text.message.includes("CANCELLED THE TRADE")) {
          this.server.showErrorMessage('TRADE CANCELLED');
          this.router.navigate(['/p2p-data'])
        } else if (res.text.message.includes("DISPUTED THE TRADE")) {
          this.server.showErrorMessage('DISPUTED THE TRADE');
          this.router.navigate(['/p2p-data'])
        }
      }
    })
    // this.getPreviousChat()
  }

  ngOnInit() {
    this.server.initSocketChat(); //chat
    window.scrollTo(0, 0)
    this.getPreviousChat()
    // this.feedbackFormValidation();
    // this.updatefeedbackFormValidation();
    // this.cancelPurchaseReasonFormValidation();
    this.myId = localStorage.getItem('userId')
    this.profileData = JSON.parse(localStorage.getItem('profiledata'))
    if (this.exchangeDetails.route == 'user-chat') {
      this.toUserId = 4
    } else {
      this.toUserId = 4
    }
    // this.tradeId = this.exchangeDetails.tradeId
    this.tradeId = '3948659ebed44a4da2d22c50dcc4c843'
    
    // this.userName = this.exchangeDetails.userName
    this.tradePartner = this.exchangeDetails.tradePartner
    this.tradeType = this.exchangeDetails.orderType
    this.minutes = this.exchangeDetails.paymentWindow
    this.peerToPeerExchangeId = this.exchangeDetails.peerToPeerExchangeId

    this.checkStatus();
    this.server.getMessage().subscribe((res) => {
      if (res.text == 'online' || localStorage.getItem('chat-type') == 'p-p') {
        this.getPreviousChat()
      }
    })
    this.currentDate = new Date()
  
  }

  getPreviousChat() {

    this.server.getCandyPixelForms(`notification/get-chat-data-for-admin?tradeId=3948659ebed44a4da2d22c50dcc4c843`).subscribe((res) => {

      if (res['status'] == 1620) {
        //alert(this.server.socketChat)
        this.server.chatArr = res['data']
        this.scrollToBottom()
      }
    })
  }

  // check status
  checkStatus() {
    let data = {}
    this.server.postApi(`p2p-exchange/trade-status-check?peerToPeerExchangeId=${Number(this.peerToPeerExchangeId)}`, {}).subscribe((res) => {
      if (res['status'] == 200) {
        this.currentTime = new Date(res['data']['timeStamp'])
        // this.toShowTimer(res['data']['timeStamp'])
      } else {
        this.server.showErrorMessage('Trade Expired')
      }
    }, err => {
      this.server.showErrorMessage('Trade Expired')
    })
  }

  

  
  // to scroll down
  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
      } catch (err) { }
    }, 100);
  }

  // on destroy
  ngOnDestroy() {
    clearInterval(this.x);
    localStorage.removeItem('exchangeDetails')
    this.server.chatArr = []
  }

  
  

  // to send message after order cancelled
  

  
  // send chat
  sendChat() {
    if (this.obj.chat) {
      let data = {
        "userId": this.appC.profileData.userId,
        "toUserId": 4,
        "messageFormat": "TEXT",
        "message": this.obj.chat,
        // "type": "TEXT"
        "tradeId": '3948659ebed44a4da2d22c50dcc4c843'
      }
      this.server.wsChat.send(JSON.stringify(data))
      // to avoide multi message in chat box
      this.server.chatArr.push(data)     
     
      
      this.obj.chat = ''
      this.scrollToBottom();
    }
  }
  showlist: any = false
  butnDisputeReason() {
    this.showlist = !this.showlist
  }


  

  // to upload image
  handleFileInput1(event) {
    this.server.showSuccessMessage('Please wait! uploading is in progress...')
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg' || type === 'application/pdf') {
        let fileData = event.target.files[0];
        this.sendFormData1(fileData)
        var reader = new FileReader()
      } else {
        this.server.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }

  /** to call form data */
  sendFormData1(fileData) {
    let formdata = new FormData()
    formdata.append('file', fileData);
    this.server.postApi('account/upload-file', formdata).subscribe((succ) => {
      if (succ.status == 200) {
        this.myImage = succ.data
        let data = {
          "userId": this.appC.profileData.userId,
          "toUserId": Number(this.toUserId),
          "messageFormat": "IMAGE",
          "message": this.myImage,
          "type": "IMAGE",
          "tradeId": this.tradeId
        }
        this.server.wsChat.send(JSON.stringify(data))
        this.server.chatArr.push(data)
        this.myImage = ''
      }
    })
  }



 
  

 

  
  

}
