import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  type_pass: any = 'password'
  type_pass1: any = 'password'
  type_pass2: any = 'password';
  constructor(public service: ServiceService,private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
  }

  // Form Validation
  formValidation(){
    this.changePasswordForm = new FormGroup ({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8),Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      confirmPassword: new FormControl('', [Validators.required]),
      
  }, passwordMatchValidator);

  /** Function for password match and mismatch */
  function passwordMatchValidator(g: FormGroup) {
      let pass = g.get('newPassword').value;
      let confPass = g.get('confirmPassword').value;
      if (pass != confPass) {
          g.get('confirmPassword').setErrors({ mismatch: true });
      } else {
          g.get('confirmPassword').setErrors(null)
          return null
      }
    }
  }

  /** to get the value of field  */
  get oldPassword(): any {
    return this.changePasswordForm.get('oldPassword');
  }
  get newPassword(): any {
      return this.changePasswordForm.get('newPassword');
  }
  get confirmPassword(): any {
      return this.changePasswordForm.get('confirmPassword');
  }

/** to check old pass or new pass not same */
  changeFunc(){
    if(this.changePasswordForm.value.oldPassword == this.changePasswordForm.value.newPassword) {
      this.service.showErrorMessage("Old and New Password can't be same")
    }else {
      if(this.changePasswordForm.valid) {
        this.changePass()
      }else {
        this.service.showErrorMessage('Form not valid!')
      }
    }
    
  }

  // to switch eye
  switchEye() {
    this.type_pass = 'text';
  }

  switchToText() {
    this.type_pass = 'password';
  }
  switchEye1() {
    this.type_pass1 = 'text';
  }

  switchToText1() {
    this.type_pass1 = 'password';
  }
  // to switch eye
  switchEye2() {
    this.type_pass2 = 'text';
  }

  switchToText2() {
    this.type_pass2 = 'password';
  }

  // to change password
  changePass() {
    var apiDoc = {
      "newPassword": this.changePasswordForm.value.newPassword,
      "oldPassword": this.changePasswordForm.value.oldPassword
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/change-password',apiDoc).subscribe(res=>{
    if(res['status'] == 200 ){
      this.service.hideSpinner();
      this.service.showSuccessMessage(res['message']); 
      this.changePasswordForm.reset()
      this.router.navigateByUrl('/my-profile')
    }
    else {
      this.service.hideSpinner();
      this.service.showErrorMessage(res['message']);
    }
    }, err=>{
      this.service.hideSpinner();
      this.service.showErrorMessage('Something Went Wrong');
    })
  }

}
