import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-sell-bitcoin',
  templateUrl: './sell-bitcoin.component.html',
  styleUrls: ['./sell-bitcoin.component.css']
})
export class SellBitcoinComponent implements OnInit {
  userData: any = {};
  id: any;
  disableButton: boolean = false;
  sale_type: string;
  p2pId: string;
  tradeId: any;
  toUserId: any;

  transactionForm = new FormGroup({
    'usd_amount': new FormControl(null, [Validators.required]),
    'coin_amount': new FormControl(null, [Validators.required])
  })

  constructor(private server: ServiceService, public router: Router, public appC: AppComponent) { }

  ngOnInit() {
    this.callByUrl()

  }
  // to call by url
  callByUrl() {
    let url = window.location.href
    let arr = url.split('/')
    let new_arr = arr[arr.length - 1].split('-')
    this.sale_type = new_arr[new_arr.length - 1]
    this.p2pId = new_arr[new_arr.length - 2]
    this.toUserId = new_arr[new_arr.length - 3]
    this.getDetails(this.p2pId, this.sale_type);
  }

  // to get details
  getDetails(id, type) {
    this.id = id
    let url;
    if (type == 'buy') {
      url = `p2p-exchange/get-details-after-press-buy-button?peerToPeerExchangeId=${id}`
    } else {
      url = `p2p-exchange/get-details-after-press-sell-button?peerToPeerExchangeId=${id}`
    }
    this.server.getCandyPixelForms(url).subscribe((res) => {
      if (res['status'] == 200) {
        this.userData = res['data'];
        // this.checkStatus();
      }
    })
  }

  // to send trade request
  sendTradeReq() {
    if (navigator.onLine) {
      this.server.postApi(`p2p-exchange/send-trade-request?amountInRange=${this.transactionForm.value.usd_amount}&userId=${localStorage.getItem('userId')}&peerToPeerExchangeId=${Number(this.id)}`, {}).subscribe((res) => {
        if (res['status'] == 200 || res['status'] == 201) {
          // if(res['status'] == 200) {
          let my_data = this.toUserId + '-' + res.data['tradeId'] + '-' + this.userData.userName + '-' + this.sale_type + '-' + this.userData.paymentWindow + '-' + this.p2pId;
          let data = {
            "toUserId": this.toUserId,
            "userId": localStorage.getItem('userId'),
            "messageType": "TEXT",
            "message": `${this.sale_type} trade request from ${this.appC.userName}`,
            "tradeId": res.data['tradeId'],
            "userName": this.appC.userName,
            "orderType": this.sale_type,
            "minutes": Number(this.userData.paymentWindow),
            'peerToPeerExchangeId': Number(this.p2pId),

          }
          this.server.wsChat.send(JSON.stringify(data))
          this.router.navigate(['chat', my_data])
        }
      })
    }
  }

  // to convert into USD amount
  calcUsdAmount(val) {
    this.disableButton = false;
    this.transactionForm.patchValue({
      'usd_amount': (Number(val) * Number(this.userData.price)).toFixed(2)
    })
    if (this.transactionForm.value.usd_amount < this.userData.minValue) {
      this.server.showSuccessMessage(`Min price must be ${this.userData.minValue} USD`)
      this.disableButton = true
    } else if (this.transactionForm.value.usd_amount > this.userData.maxValue) {
      this.server.showSuccessMessage(`Max price must be ${this.userData.maxValue} USD`)
      this.disableButton = true
    }
  }

  // to convert into coin amount
  calcCoinAmount(val) {
    this.disableButton = false;
    this.transactionForm.patchValue({
      'coin_amount': (Number(val) / Number(this.userData.price)).toFixed(8)
    })
    if (val < this.userData.minValue) {
      this.server.showSuccessMessage(`Min price must be ${this.userData.minValue} USD`)
      this.disableButton = true
    } else if (val > this.userData.maxValue) {
      this.server.showSuccessMessage(`Max price must be ${this.userData.maxValue} USD`)
      this.disableButton = true
    }
  }
}
