import { AfterContentInit, Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
declare var $;
@Component({
  selector: "app-homepage-before-login",
  templateUrl: "./homepage-before-login.component.html",
  styleUrls: ["./homepage-before-login.component.css"],
})
export class HomepageBeforeLoginComponent implements OnInit, AfterContentInit {
  coinpair: any = [];
  coinDetail: any = {};
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  marketdata: any;
  arr: any = [];
  basecoins = "BTC";
  executables: any;
  tab = "BTC Market";
  baseCoinFun: string;
  baseCoinVal: string;
  myInterval: any;
  userLoginStatus: boolean = false;
  loginStatus: any = false;

  isLoggedIn = false;
  getStarted: FormGroup;
  coinList: any = [];
  showCookie: any = "";
  today = new Date();
  announcmentData: any = [];
  kuldhan: any = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public service: ServiceService,
    public fb: FormBuilder
  ) {
    // document.getElementById("teams-founder").scrollIntoView(true);
    // window.location.hash =   '#teams-founder';
    // var self = this;
    // service.connection.subscribe(connection => {
    //   if (connection == 'online') {
    //     this.subscribefunc(1);
    //   }
    // });
    // this.service.wsExchange.addEventListener('message', function (event) {
    //   let data = JSON.parse(event.data);
    //   if (data.messageType == 'TICKER_UPDATE') {
    //     var symbol = data.symbol.split('_')[0];
    //     self.arr.forEach(element => {
    //       if (element.executable == symbol) {
    //         element.lastPrice = data.data.lastPrice;
    //         element.hourChange = data.data.volume24Hour;
    //         element.hourLow = data.data.lowest24HourPrice;
    //         element.hourhigh = data.data.highest24HourPrice;
    //         element.hourVol = data.data.totalVolume;
    //         element.percentageChange = data.data.percentageChange
    //       }
    //     });
    //   }
    // })
    this.service.getWalletCoins();
  }

  scrollllun() {
    var ele = document.getElementById("teams-founder");
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  }
  botScroll() {
    var ele = document.getElementById("bot-team");
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  }
  launchScroll() {
    var ele = document.getElementById("launch-team");
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  }
  ngAfterContentInit(): void {
    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.type == "team") {
        this.scrollllun();
      }
      else if(res.type == 'bot'){
        this.botScroll();
      }
      else if(res.type == 'launch'){
        this.launchScroll();
      }
    });
  }
  ngOnInit() {

    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
    this.showCookie = localStorage.getItem("cooki");

    

    this.service.connectChatSocket();
    this.getStarted = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
          ),
        ],
      ],
    });
    window.scrollTo(0, 0);
    this.verifyEmail();

    // this.getPrice();
    const token = localStorage.getItem("credential");
    if (token != null) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    // this.getCoinList()
    this.silckSlider();
    this.silckSlider2();
    window.scrollTo(0, 0);
    // this.getAllCoinPairFunc();
    // slider
    this.getCoinList();
    this.getAnnouncementData();
    this.getBannerList();
    // this.getAllCoins()
    this.faqList()
  }
  // Get All The Coin Functionality
  //  async getAllCoins() {
  //   await this.getPrice();
  //     this.service.showSpinner();
  //     this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {

  //       this.service.hideSpinner();
  //       if (res['status'] == 200) {
  //         var coinList = res['data'].coinList;
  //         for (let x = 0; x < coinList.length; x++) {

  //           const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
  //           if (m != -1) {
  //             coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
  //             this.kuldhan= res['data'].userBalance[m].totalBalance +this.kuldhan;
  //             coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
  //             coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
  //             // coinList[x]['price24hours'] = this.get24hour(res['data'].coinList[m].coinFullName)
  //             // this.depositCoin(coinList[x].coinShortName);

  //           }
  //         }
  //         let removeCoin = ['BCH','OMG','XLM','DASH']
  //         this.coinList = coinList.filter((ele)=>{
  //           return !removeCoin.includes(ele.coinShortName)
  //         })

  //         // this.coinList = coinList;
  //         console.log(this.coinList);

  //         // this.selectedUserCoin = this.coinList[0]

  //       }
  //     }, err => {
  //       this.service.hideSpinner();
  //       if (err['status'] == '401') {
  //         localStorage.clear();
  //         this.router.navigate(['/login']);
  //         this.service.showErrorMessage('Session Expired!');
  //       } else {
  //         this.service.showErrorMessage('Something Went Wrong');
  //       }
  //     })
  //   }
  silckSlider() {
    $(".items").slick({
      infinite: true,
      slidesToShow: 2,
      dots: false,
      prevArrow: false,
      nextArrow: false,
      autoplay: true,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $(".multiple-items").slick({
      infinite: true,
      slidesToShow: 4,
      autoplay: true,
      dots: false,
      arrows: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
  silckSlider2() {}
  length(n) {
    return new Array(n);
  }
  download(e) {
    alert(e + "comming soon");
  }
  // tto get coin list
  getCoinList() {
    this.service
      .getCandyPixelForms("wallet/coin/get-coin-list")
      .subscribe((getLiquidity) => {
        if (getLiquidity["status"] == 200) {
          let index = getLiquidity["data"].findIndex(
            (x) => x.coinShortName == "USD"
          );
          if (index != 1) {
            getLiquidity["data"].splice(index, 2);
            this.coinList = getLiquidity["data"];
          }
          console.log(this.coinList);

          let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC","INR"];
          this.coinList = this.coinList.filter((ele) => {
            return !removeCoin.includes(ele.coinShortName);
          });
          console.log(this.coinList);

          $(document).ready(function () {
            $(".main_slider").slick({
              // autoplay: true,
              dots: true,
              loop: false,
              arrow: true,
              infinite: true,
              autoplay: true,

              speed: 300,
              slidesToShow: 4,
              slidesToScroll: 5,
              responsive: [
                {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    dots: false,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          });
          $(window).resize(function () {
            // debugger;
            $(".customer-logos, .main_slider").slick("setPosition");
          });
          $(document).ready(function () {
            $(".customer-logos").slick({
              slidesToShow: 6,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 1200,
              arrows: false,
              dots: false,
              pauseOnHover: false,
              responsive: [
                {
                  breakpoint: 1450,
                  settings: {
                    slidesToShow: 4,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 520,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            });
          });
        }
        this.getPrice();
      });
  }

  getPrice() {
    var getLiquiditydata = [];
    this.service.getLiquidity().subscribe((getLiquidity) => {
      getLiquiditydata = getLiquidity["data"];
      getLiquiditydata.forEach((element) => {
        var ind = this.coinList.findIndex(
          (x) => x.coinShortName == element.symbol
        );
        if (ind != -1) {
          this.coinList[ind].marketPriceInUsd24HourBefore =
            element.quote.USD.percent_change_24h;
          this.coinList[ind].marketPriceInUsdAWeekBefore =
            element.quote.USD.percent_change_7d;
          this.coinList[ind].price = element.quote.USD.price.toFixed(4);
          this.coinList[ind].volume_24h = element.quote.USD.volume_24h;
          this.coinList[ind].last_updated = element.last_updated;
        }
      });
    });
  }

  // verify email
  verifyEmail() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let token = params["token"];
      if (token) {
        this.service
          .getCandyPixelForms("account/verify-user?token=" + token)
          .subscribe(
            (succ) => {
              if (succ["status"] == 200) {
                this.service.showSuccessMessage(succ["message"]);
                this.router.navigate(["/login"]);
              }
            },
            (error) => {
              this.service.showErrorMessage(error.message);
            }
          );
      }
    });
  }

  register() {
    this.router.navigate(["/register"]);
  }

  goToSignup() {
    this.service.email = this.getStarted.value.email;
    this.router.navigate(["/register"]);
  }

  // to navigate depending on login status
  navigateAsPerLoginStatus(coin) {
    if (localStorage.getItem("credential")) {
      localStorage.setItem("animatedCoin", coin);
      this.router.navigateByUrl("basic-buy-sell/buy");
    } else {
      this.router.navigate(["login"]);
    }
  }

  // to navigate depending on login status
  navigateAsPerLoginStatus1(coin) {
    if (localStorage.getItem("credential")) {
      localStorage.setItem("animatedCoin", coin);
      this.router.navigateByUrl("basic-buy-sell/buy");
    } else {
      this.router.navigate(["login"]);
    }
  }
  buysell() {
    this.router.navigateByUrl("advanced-exchange");
  }
  navigateToUrl(urlvalue) {
    if (urlvalue == "huobi") {
      window.open("https://www.huobi.com/en-us/");
    } else if (urlvalue == "okblockchain") {
      window.open("https://www.okcoin.com/");
    } else if (urlvalue == "nodecapital") {
      window.open("http://www.nodecap.com/");
    } else if (urlvalue == "genesis") {
      window.open("https://www.genesisblockchain.io/");
    } else if (urlvalue == "plumventures") {
      window.open("http://www.plumventures.cn/");
    } else if (urlvalue == "playstore") {
      window.open("https://play.google.com/store");
    } else if (urlvalue == "appstore") {
      window.open("https://www.apple.com/in/app-store/");
    }
  }
  closeCookie() {
    // if (this.showCookie != null) {
    //   this.showCookie = localStorage.getItem('cooki')

    // }
    // else {
    //   localStorage.setItem('cooki', 'cooki')
    //   this.showCookie = localStorage.getItem('cooki')
    // }

    localStorage.removeItem("cooki");
    this.showCookie = localStorage.getItem("cooki");

  }

  // Get All Coin Pair Functionality
  getAllCoinPairFunc() {
    this.service.showSpinner();
    this.arr = [];
    this.service.getCandyPixelForms("wallet/coin/get-symbol-list").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          var ind = res.data.findIndex((x) => x.baseCoin == this.basecoins);
          if (ind > -1) {
            res.data[ind].execCoin.forEach((element) => {
              this.arr.push({
                base: this.basecoins,
                executable: element,
                lastPrice: "",
                hourChange: "",
                hourhigh: "",
                hourLow: "",
                hourVol: "",
              });
            });
          }
          // this.subscribefunc(1);
          setTimeout(() => {
            this.subscribefunc(1);
          }, 1000);
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  subscribefunc(mode) {
    switch (mode) {
      case 1:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "SUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.service.wsExchange.readyState) {
            this.service.wsExchange.send(JSON.stringify(data));
          }
        });
        break;
      case 2:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "UNSUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.service.wsExchange.readyState) {
            this.service.wsExchange.send(JSON.stringify(data));
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscribefunc(2);
  }
  getAnnouncementData() {
    // let url = `static/get-announcement-list?page=${this.currentPage}&pageSize=${this.pageSize}`
    let url = "static/get-all-announcement-for-website";
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.announcmentData = res["data"]["details"];
        this.service.hideSpinner();
        //  this.service.showSuccessMessage(res['message'])
      } else {
        this.service.hideSpinner();
        // this.service.showErrorMessage(res['message'])
      }
    });
  }

  //  tags data
  tags: any;
  account: any;
  bannerData: any = [];
  //  banner list
  // getBannerList() {
  //   var url = "static/get-all-banner-for-website";

  //   this.service.showSpinner();
  //   this.service.getCandyPixelForms(url).subscribe(
  //     (res) => {
  //       this.service.hideSpinner();
  //       if (res["status"] == 200) {
  //         this.bannerData = res["data"]["details"];
  //         console.log(this.bannerData);
  //       } else {
  //         this.service.hideSpinner();
  //         0;
  //       }
  //     },
  //     (err) => {
  //       this.service.hideSpinner();
  //       if (err["status"] == "401") {
  //         localStorage.clear();
  //         this.router.navigate(["/login"]);
  //         this.service.showErrorMessage("Session Expired!");
  //       } else {
  //       }
  //     }
  //   );
  // }

   // get banner list and show in slider
   getBannerList() {
    this.service.getCandyPixelForms('static/get-all-banner-for-website').subscribe((res: any) => {
      if (res.status == 200) {
        // this.bannerData = res['data'][0]['categoryDetails'];
        this.bannerData = res["data"]["details"];

        // console.log(this.bannerData)
        $(document).ready(function () {
          $('.banner_sliderrr').slick({
            autoplay: true,
            dots: false,
            loop: false,
         

            arrow: false,
            infinite: true,
            speed: 2000,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [{
              breakpoint: 1280,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1024,
              settings:
              {
                slidesToShow: 1,
                dots: false,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
            ]
          });
        });
      }
    })
  }

  //User Details
  viewAnnounce(id ) {
    this.router.navigate(['/banner-list'], { queryParams: { id:id } })
  }


  faqData:any  = [];
  faqList(){
    this.service.showSpinner();
    var url = `static/get-link-list`  ;
    this.service.getCandyPixelForms(url).subscribe((res:any)=>{
      console.log("kyc", res);
      if(res.status==200){
        this.faqData=res.data;
      }
      this.service.hideSpinner();
    },(err)=>{
      if(err['status']==401){
      this.service.hideSpinner();
        this.service.showErrorMessage('Unauthorized Access')
      }
      else{
      this.service.hideSpinner();
        this.service.showErrorMessage('Something Went Wrong');
     }
    })
  }
}
