import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/header/header.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { RegisterComponent } from './pages/register/register.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { SupportComponent } from './pages/support/support.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { FaqComponent } from './pages/faq/faq.component';
import { GoogleAuthenticationFirstPageComponent } from './pages/google-authentication-first-page/google-authentication-first-page.component';
import { GoogleAuthenticationSecondPageComponent } from './pages/google-authentication-second-page/google-authentication-second-page.component';
import { GoogleAuthenticationThirdPageComponent } from './pages/google-authentication-third-page/google-authentication-third-page.component';
import { GoogleAuthenticationFourthPageComponent } from './pages/google-authentication-fourth-page/google-authentication-fourth-page.component';
import { SmsAuthenticationPageComponent } from './pages/sms-authentication-page/sms-authentication-page.component';
import { MyWalletComponent } from './pages/my-wallet/my-wallet.component';
import { DepositHistoryComponent } from './pages/deposit-history/deposit-history.component';
import { EmailVerificationPageComponent } from './pages/email-verification-page/email-verification-page.component';
import { MarketInfoComponent } from './pages/market-info/market-info.component';
import { SecurityComponent } from './pages/security/security.component';
import { GoogleAuthComponent } from './pages/google-auth/google-auth.component';
import { AccountActivityComponent } from './pages/account-activity/account-activity.component';
import { HomeBeforeLoginComponent } from './pages/home-before-login/home-before-login.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { KycComponent } from './pages/kyc/kyc.component';
import { P2pExchangeComponent } from './pages/p2p-exchange/p2p-exchange.component';
import { QuickSellComponent } from './pages/quick-sell/quick-sell.component';
import { CreateAdvertisementComponent } from './pages/create-advertisement/create-advertisement.component';
import { WithdrawHistoryComponent } from './pages/withdraw-history/withdraw-history.component';
import { LoginHistoryComponent } from './pages/login-history/login-history.component';
import { DisableSmsComponent } from './pages/disable-sms/disable-sms.component';
import { LoginActivityComponent } from './pages/login-activity/login-activity.component';
import { SecurityActivityComponent } from './pages/security-activity/security-activity.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { Register2Component } from './pages/register2/register2.component';
import { AddAccountComponent } from './pages/add-account/add-account.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { SellBitcoinComponent } from './pages/sell-bitcoin/sell-bitcoin.component';
import { CloseTradeComponent } from './pages/close-trade/close-trade.component';
import { CancelTradeComponent } from './pages/cancel-trade/cancel-trade.component';
import { CancelContactExchangeComponent } from './pages/cancel-contact-exchange/cancel-contact-exchange.component';
import { ContactExchangeComponent } from './pages/contact-exchange/contact-exchange.component';
import { DashboardTradesComponent } from './pages/dashboard-trades/dashboard-trades.component';
import { P2pBuyCoinComponent } from './pages/p2p-buy-coin/p2p-buy-coin.component';
// import { AdvancedExchangeComponent } from './pages/advanced-exchange/advanced-exchange.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { KyclistComponent } from './pages/kyclist/kyclist.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { BuySellComponent } from './pages/buy-sell/buy-sell.component';

import { WalletTransactionStatusComponent } from './pages/wallet-transaction-status/wallet-transaction-status.component';
import { InternalTransferHistoryComponent } from './pages/internal-transfer-history/internal-transfer-history.component';
import { EscrowTranferHistoryComponent } from './pages/escrow-tranfer-history/escrow-tranfer-history.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { AdminChatComponent } from './pages/admin-chat/admin-chat.component';
import { AdminMessageListComponent } from './pages/admin-message-list/admin-message-list.component';
import { FeesComponent } from './pages/fees/fees.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { UserAdminChatComponent } from './pages/user-admin-chat/user-admin-chat.component';
import { HomepageBeforeLoginComponent } from './pages/homepage-before-login/homepage-before-login.component';
import { ComingSoonComponent } from './pages/shared/coming-soon/coming-soon.component';


import { ReferralComponent } from './pages/referral/referral.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { ComingSoonNewComponent } from './pages/coming-soon-new/coming-soon-new.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { BankAccountListComponent } from './pages/bank-account-list/bank-account-list.component';
import { CarrersComponent } from './pages/carrers/carrers.component';
import { AccountComponent } from './pages/account/account.component';
import { NomineeComponent } from './pages/nominee/nominee.component';
import { P2pExchangeDataComponent } from './pages/p2p-data/p2p-exchange-data/p2p-exchange-data.component';
import { CreateAddComponent } from './pages/create-add/create-add.component';
import { NativeTokenComponent } from './pages/native-token/native-token.component';
import { ListingComponent } from './pages/static-content/listing/listing.component';
import { DownloadsComponent } from './pages/static-content/downloads/downloads.component';
import { BlogComponent } from './pages/static-content/blog/blog.component';
import { CareerDataComponent } from './pages/static-content/career-data/career-data.component';
import { CareerJobsSearchComponent } from './pages/static-content/career-jobs-search/career-jobs-search.component';
import { CareerJobsDetailsComponent } from './pages/static-content/career-jobs-details/career-jobs-details.component';
import { CareerJobsFormComponent } from './pages/static-content/career-jobs-form/career-jobs-form.component';
import { AboutDataComponent } from './pages/static-content/about-data/about-data.component';
import { SwapCryptoComponent } from './pages/swap-crypto/swap-crypto.component';
import { AdvancedExchangeComponent } from './pages/advanced-exchange/advanced-exchange.component';
import { AnnouncementListComponent } from './pages/static-content/announcement-list/announcement-list.component';
import { NewsComponent } from './pages/static-content/news/news.component';
import { NewsDetailsComponent } from './pages/static-content/news-details/news-details.component';
import { PartnershipComponent } from './pages/static-content/partnership/partnership.component';
import { LaunchpadComponent } from './pages/static-content/launchpad/launchpad.component';
import { BannerListComponent } from './pages/static-content/banner-list/banner-list.component';
import { BlogDetailsComponent } from './pages/static-content/blog-details/blog-details.component';
import { DisclaimerComponent } from './pages/static-content/disclaimer/disclaimer.component';
import { MyWalletCryptoComponent } from './pages/my-wallet-crypto/my-wallet-crypto.component';
import { MyWalletWithdrawCryptoComponent } from './pages/my-wallet-withdraw-crypto/my-wallet-withdraw-crypto.component';
import { FeedbackUserComponent } from './pages/static-content/feedback-user/feedback-user.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuard } from './auth.guard';
import { CreateAddEditComponent } from './pages/create-add-edit/create-add-edit.component';
import { MyWalletAssetsComponent } from './pages/my-wallet-assets/my-wallet-assets.component';
import { StakingComponent } from './pages/staking/staking.component';
import { StakingDetailsComponent } from './pages/staking-details/staking-details.component';
import { StakingHistoryComponent } from './pages/staking-history/staking-history.component';
import { StakingHistoryDetailComponent } from './pages/staking-history-detail/staking-history-detail.component';




const routes: Routes = [
  { path: "", redirectTo: "homepage", pathMatch: "full" },
  // { path: '', component: HomeBeforeLoginComponent },
  { path: 'homepage', component: HomepageBeforeLoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'support', component: SupportComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'advanced-exchange', component: AdvancedExchangeComponent },
  { path: 'google-authentication-first-page', component: GoogleAuthenticationFirstPageComponent },
  { path: 'google-authentication-second-page', component: GoogleAuthenticationSecondPageComponent },
  { path: 'google-authentication-third-page', component: GoogleAuthenticationThirdPageComponent },
  { path: 'google-authentication-fourth-page', component: GoogleAuthenticationFourthPageComponent },
  { path: 'sms-authentication-page', component: SmsAuthenticationPageComponent },
  { path: 'my-wallet', component: MyWalletComponent },
  { path: 'deposit-history', component: DepositHistoryComponent },
  { path: 'email-verification-page', component: EmailVerificationPageComponent },
  { path: 'market-info', component: MarketInfoComponent },
  { path: 'security', component: SecurityComponent },
  { path: 'google-auth', component: GoogleAuthComponent },
  { path: 'account-activity', component: AccountActivityComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'kyc', component: KycComponent },
  { path: 'kyclist', component: KyclistComponent },
  { path: 'p2p-exchange', component: P2pExchangeComponent },
  { path: 'quick-sell', component: QuickSellComponent },
  { path: 'create-advertisement', component: CreateAdvertisementComponent },
  { path: 'withdraw-history', component: WithdrawHistoryComponent },
  { path: 'login-history', component: LoginHistoryComponent },
  { path: 'disable-sms', component: DisableSmsComponent },
  { path: 'login-activity', component: LoginActivityComponent },
  { path: 'security-activity', component: SecurityActivityComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'register2', component: Register2Component },
  { path: 'add-account', component: AddAccountComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'sell-bitcoin', component: SellBitcoinComponent },
  { path: 'close-trade', component: CloseTradeComponent },
  { path: 'cancel-trade', component: CancelTradeComponent },
  { path: 'cancel-contact-exchange', component: CancelContactExchangeComponent },
  { path: 'contactExchange', component: ContactExchangeComponent },
  { path: 'dashboard-trades', component: DashboardTradesComponent },
  { path: 'p2p-buy/:id', component: P2pBuyCoinComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'feedback/:id/:name', component: FeedbackComponent },
  { path: 'profile/:id', component: ProfileComponent },
  { path: 'buy-sell', component: BuySellComponent },
  { path: 'wallet-transaction-status/:action', component: WalletTransactionStatusComponent },
  { path: 'internal-transfer-history', component: InternalTransferHistoryComponent },
  { path: 'escrow-transfer-history', component: EscrowTranferHistoryComponent },
  { path: 'transfer', component: TransferComponent },
  { path: 'admin-chat', component: AdminChatComponent },
  { path: 'admin-message-list', component: AdminMessageListComponent },
  { path: 'fees', component: FeesComponent },
  { path: 'confirmation', component: ConfirmationComponent },
  { path: 'user-chat', component: UserAdminChatComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'soon', component: ComingSoonComponent },
  { path: 'referral', component: ReferralComponent },
  { path: 'cookie', component: CookiesComponent },
  { path: 'coming-soon', component: ComingSoonNewComponent },
  { path: 'announcement', component: AnnouncementComponent },
  { path: 'bank-list', component: BankAccountListComponent },
  { path: 'carrers', component: CarrersComponent },
  { path: 'account', component: AccountComponent },
  { path: 'nominee', component: NomineeComponent },
  { path: 'p2p-data', component: P2pExchangeDataComponent },
  { path: 'create-addd', component: CreateAddComponent },
  { path: 'create-edit', component: CreateAddEditComponent },
  { path: 'native', component: NativeTokenComponent },
  { path: 'listing', component: ListingComponent },
  { path: 'downloads', component: DownloadsComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'carrier-data', component: CareerDataComponent },
  { path: 'career-job', component: CareerJobsSearchComponent },
  { path: 'career-job-details', component: CareerJobsDetailsComponent },
  { path: 'career-job-form', component: CareerJobsFormComponent },
  { path: 'about-data', component: AboutDataComponent },
  { path: 'swap', component: SwapCryptoComponent },
  { path: 'announce', component: AnnouncementListComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news-detail', component: NewsDetailsComponent },
  { path: 'partner', component: PartnershipComponent },
  { path: 'launch', component: LaunchpadComponent },
  { path: 'banner-list', component: BannerListComponent },
  { path: 'blog-detail', component: BlogDetailsComponent },
  { path: 'crypto-deposit', component: MyWalletCryptoComponent },
  { path: 'crypto-withdraw', component: MyWalletWithdrawCryptoComponent },
  { path: 'feedback-user', component: FeedbackUserComponent },
  { path: 'assets', component: MyWalletAssetsComponent },
  { path: 'staking', component: StakingComponent },
  // { path: 'history-detail', component: StakingHistoryComponent},
  { path: 'staking-details',component:StakingDetailsComponent},
  { path: 'StakingHistoryDetail', component: StakingHistoryDetailComponent},
  { path: 'StakingHistory', component: StakingHistoryComponent},
  { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
