import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.css']
})
export class LoginHistoryComponent implements OnInit {
  profiledetails: any;
  loginHistoryList: any;

  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.profiledetails= JSON.parse(localStorage.getItem('profiledata') ) 
    this.getLoginHistory()
  }

   // Get login history List Functionality
   getLoginHistory() {
      var url = 'account/get-user-login-details?userIdForLoginHistoy=' + this.profiledetails.userId ;
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
        this.loginHistoryList = res['data']
      this.loginHistoryList['profileData'] = this.profiledetails

        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          this.service.showErrorMessage('Something Went Wrong');
        }
      })
  }

  length(n) {
    return new Array(n)
  }
}
