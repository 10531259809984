import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-wallet-transaction-status',
  templateUrl: './wallet-transaction-status.component.html',
  styleUrls: ['./wallet-transaction-status.component.css']
})
export class WalletTransactionStatusComponent implements OnInit {
  action: any = [];
  responseTxt: any;
  responseCode: string;
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    
    let arr = window.location.href.split('/');
    let lastContent = arr[arr.length - 1];
    if (lastContent.includes("#")) {

      this.rejectWithdraw(lastContent);
    } 
    else {
      let url = lastContent
     
      if(String(url).includes('ERC20') || String(url).includes('BEP20')){
        if(String(url).includes('ERC20')){
          let tempUrl = url.split('ERC20')[0]
          this.approveWithdrawNetwork(tempUrl,'ERC20')
        }
        else{
          let tempUrl = url.split('BEP20')[0]
          this.approveWithdrawNetwork(tempUrl,'BEP20')
        }
        
      }
      else{
        this.approveWithdraw(lastContent);

      }
    }
  }
// Approve Withdraw 
approveWithdraw(url) {
  var apireq = {
    "isWithdraw": true,
    "withdrawToken": url.slice(5)
  }
  this.service.showSpinner();
  this.service.postCandyPixelForms('wallet/wallet/approve-withdraw', apireq).subscribe(res => {
    
    this.responseTxt = res['message'];
    if (res['status'] == 200) {
      this.service.hideSpinner();
      this.responseCode = '1'
      this.service.showSuccessMessage(res['message'])
    } else {
      this.service.showErrorMessage(res['message'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.service.showErrorMessage('Session Expired!');
    }
  })
}
approveWithdrawNetwork(url,network) {
  var apireq = {
    "isWithdraw": true,
    "withdrawToken": url.slice(5)
  }
  this.service.showSpinner();
  this.service.postCandyPixelForms(`wallet/wallet/approve-withdraw-Bep-Erc?network=${network}`, apireq).subscribe(res => {
    
    this.responseTxt = res['message'];
    if (res['status'] == 200) {
      this.service.hideSpinner();
      this.responseCode = '1'
      this.service.showSuccessMessage(res['message'])
    } else {
      this.service.showErrorMessage(res['message'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.service.showErrorMessage('Session Expired!');
    }
  })
}

// reject withdraw
rejectWithdraw(url) {
  let token = url.split("#")[1];
  var getUrl = 'wallet/wallet/cancel-withdraw?token=' + token.slice(5);
  this.service.showSpinner();
  this.service.getCandyPixelForms(getUrl).subscribe(res => {
   
    this.responseTxt = res['message'];
    if (res['status'] == 200) {
      this.service.hideSpinner();
      this.responseCode = '0'
      this.service.showSuccessMessage(res['message'])
    } else {
      this.service.showErrorMessage(res['message'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.service.showErrorMessage('Session Expired!');
    }
  })
}
}
