import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-staking',
  templateUrl: './staking.component.html',
  styleUrls: ['./staking.component.css']
})
export class StakingComponent implements OnInit {
  fixedItem: any = [];
  pageNumber: any = 1
  pageSize: any = 10
  coinList: any = []

  constructor(public service: ServiceService) { }

  ngOnInit() {
    this.getData()
    this.getCoinList()
  }


  getData() {
    let url = `wallet/view-fixed-deposit-code?page=${this.pageNumber - 1}&pageSize=${this.pageSize}`
    // let url = `wallet/view-fixed-deposit-code`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.fixedItem = res['data']['getEmailHistoryDetailsFromDate'];
        console.log('=====>12', this.fixedItem);

        this.service.hideSpinner()
        // this.service.showSuccessMessage(res['message'])
      }
      else {
        this.service.hideSpinner()
        this.service.showErrorMessage('Data Not Found')
      }
    })
  }
  getCoinList() {
    this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe((getLiquidity) => {
      if (getLiquidity['status'] == 200) {
        let index = getLiquidity['data'].findIndex(x => x.coinShortName == 'USD')
        if (index != 1) {
          getLiquidity['data'].splice(index, 2)
          this.coinList = getLiquidity[0].coinFullName
        }
        console.log(this.coinList);


        // console.log(this.coinList);


      }
    })
  }

}
