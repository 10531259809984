import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-disable-sms',
  templateUrl: './disable-sms.component.html',
  styleUrls: ['./disable-sms.component.css']
})
export class DisableSmsComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0)
  }
  back() {
    this.router.navigate(['/security'])
  }

}
