import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import { ToastrService } from 'ngx-toastr'
declare var $
@Component({
  selector: 'app-register2',
  templateUrl: './register2.component.html',
  styleUrls: ['./register2.component.css']
})
export class Register2Component implements OnInit {
  signupData: any = {};
  imageUrl: any = "assets/images/profile-img.jpg";
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  CountryCodeList: any;
  signupTwoForm: FormGroup;
  CodeList: any;
  TelephoneNo: boolean = true;
  accountData: any;
  refferalCode:any;
  smsAuth: any = '';
  ipAddress: string;
  constructor(public router: Router,
    public service: ServiceService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    if (localStorage.getItem('signupData')) {
      this.signupData = this.service.decryptData(localStorage.getItem('signupData'));
     
      
    }


    console.log(this.signupData);
    this.formValidation();
    this.onDateChanged();
    this.countryList = this.service.countryListJson;
    this.signupTwoForm.patchValue({
      'refferalCode': this.signupData.refferalCode
    })
    this.getIP()
  }

// get IP Address
getIP() {
  this.service.getIPAddress().subscribe((res: any) => {
    this.ipAddress = res.ip;
    //this.getIPLocation()
  });
}

 getIPLocation(){
 var url= "https://api.ipgeolocation.io/ipgeo?apiKey=5cb98274eb3d46eaa0359cf9cbbedf77&ip=" + this.ipAddress
 this.service.getCandyPixelForms(url).subscribe(res => {
 })
}

  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }

  // Form Validation
  formValidation() {
    this.signupTwoForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/i)]),
      'middleName': new FormControl('', [Validators.pattern(/^[a-zA-Z]*$/i)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[a-z A-Z]*$/i)]),
      //'image': new FormControl('',Validators.required),
      'dob': new FormControl('', Validators.required),
      'address': new FormControl('', [Validators.maxLength(150), Validators.required]),
      'selectCountry': new FormControl('', Validators.required),
      'selectState': new FormControl('', Validators.required),
      'selectCity': new FormControl('', [Validators.required]),
      'refferalCode':new FormControl(''),
      'gender' : new FormControl(''),
      'zipCode' : new FormControl(''),
      "email" : new FormControl ('')
      // 'mobile': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/), Validators.minLength(8), Validators.maxLength(15)]),
      //'twoFA': new FormControl('',Validators.required)
    })
    // let dob = new Date()
    // let mydate = {
    //     date: {
    //         year: dob.getFullYear() - 18,
    //         month: dob.getMonth() + 1,
    //         day: dob.getDate()}
    //     }
    // this.signupTwoForm.patchValue({"dob": mydate});       
    // this.signupTwoForm.patchValue({dob});                

  }
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }

  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  // Date of Birth Management
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 },
    height: '33px',
    // background : '#000'
  };
  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear() - 18,
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
  // Signup Api Integration
  signupFunc() {
    // $("#smsAuth").modal("show");

    var apireq = {
      "address": this.signupTwoForm.value.address,
      "city": this.signupTwoForm.value.selectCity,
      "country": this.signupTwoForm.value.selectCountry,
      "code": this.signupTwoForm.value.countryCode,
      "dob": this.signupTwoForm.value.dob.formatted,
      "email": this.signupData.email,
      "firstName": this.signupTwoForm.value.firstName,
      "middleName": this.signupTwoForm.value.middleName,
      "lastName":  this.signupTwoForm.value.lastName,
      "imageUrl": this.imageUrl,
      "password": this.signupData.password,
      // "phoneNo": this.countryCode + this.signupTwoForm.value.mobile,
      "phoneNo": this.signupData.phoneNo,
      "myRefferalCode": "string",
      "referredCode": this.signupTwoForm.value.refferalCode,
      "countryCode": this.signupData.countryCode,
      "pnWithoutCountryCode": this.signupData.pnWithoutCountryCode,
      "roleStatus": "USER",
      "webUrl": this.service.webUrl,
      "state": this.signupTwoForm.value.selectState,
      'gender': this.signupTwoForm.value.gender,
      "zipCode" : this.signupTwoForm.value.zipCode

    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/signup', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        localStorage.removeItem('signupData')
        this.service.showSuccessMessage(' Verification link has been send to your registered email id');
        $("#smsAuth").modal("show");

        // this.router.navigate(['/login'])
      }
      else if (res['status']== 201){
        $("#smsAuth").modal("show");

      }
      else if (res['status'] == 205) {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.showErrorMessage('Something Went Wrong');
      this.service.hideSpinner();
    })
  }
  // Get StateList Functionality
  getStateList(event) {
    var stateList = this.countryList.filter(x => (x.country == event.target.value));
    this.stateList = stateList[0].states;
    this.countryCode = stateList[0].code;
  }

  mobileVerification() {
    var url = `account/verify-sms-code-mobile-app-mobile?phoneNo=${String(this.signupData.phoneNo).replace('+','%2B')}`;

    var smsapireq = {
      code: this.smsAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage(res["message"]);
          $("#smsAuth").modal("hide");
          this.router.navigate(['/login'])
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }

   /** Function to restrict space */
   restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  updateFunc() {
    var apireq = {
      address: this.signupTwoForm.value.address,
      firstName: this.signupTwoForm.value.firstName,
      middleName :  this.signupTwoForm.value.middleName,
      imageUrl: this.imageUrl,
      lastName: this.signupTwoForm.value.lastName,
      dob: this.signupTwoForm.value.dob.formatted,
      //"state": this.signupTwoForm.value.state,
      // phoneNo: this.signupTwoForm.value.mobile,
      zipCode: this.signupTwoForm.value.zipCode,
      randomId: this.signupTwoForm.value.uniqueId,
      country: this.signupTwoForm.value.selectCountry,
      state: this.signupTwoForm.value.selectState,
      city: this.signupTwoForm.value.selectCity,
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms("account/profile-update", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          // this.getProfile();
          this.service.showSuccessMessage("User details updated");
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  // resSendEmail() {
  //   var url = "account/resend-verify-email?email=" + this.signupTwoForm.value.email + "&webUrl=" + this.service.webUrl;
  //   this.service.showSpinner();
  //   this.service.getCandyPixelForms(url).subscribe(res => {
  //     this.service.hideSpinner();
  //   }, err => {
  //     this.service.hideSpinner();
  //   })
  // }
}
