import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html',
  styleUrls: ['./announcement-list.component.css']
})
export class AnnouncementListComponent implements OnInit {
  loginStatus:any = false
  pageNumber: number = 1;
  totalRecord: any;
  pageSize:number=10
  totalItems: any;
  anounceData:any = [];
  userID: any;
  firstAnnounce:any
  constructor(public router: Router,public service: ServiceService,private activated : ActivatedRoute) { 
    window.scrollTo(0,0)
   }

  ngOnInit() {
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
    this.announcementData()
  }
  announcementData(){ 
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-all-announcement-for-website').subscribe(res=>{
           if (res['status']==200){
             this.firstAnnounce = res['data']['details'][0]
             this.anounceData=res['data']['details'];
             this.service.hideSpinner()
            //  this.service.showSuccessMessage(res['message'])
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }

  //User Details
  viewAnnounce(id ) {
    this.router.navigate(['/banner-list'], { queryParams: { id:id } })
  }
}
