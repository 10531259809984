import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coming-soon-new',
  templateUrl: './coming-soon-new.component.html',
  styleUrls: ['./coming-soon-new.component.css']
})
export class ComingSoonNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0)
  }

}
