import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
declare var $
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgotForm: FormGroup;
  showDetail: any = "BANK";
  products: any = [];
  countryCode: any = [];
  smsAuth: any = '';
  emailAuth :any = '';
  ipAddress: any;
  constructor(public router: Router, private httpClient: HttpClient,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.forgotForm = new FormGroup({
      'email': new FormControl('',[Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'mobile': new FormControl("", [
        Validators.pattern(/^[^0][0-9]*$/),
      ]),
      // 'mobile': new FormControl(''),
      'countryCode': new FormControl("+91", [Validators.required]),
    });
    this.countryCode = this.service.countryListJson;

    this.getStateList();

    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
  localStorage.setItem('email',this.forgotForm.value.email)

  }
  bankDetails(showSection) {
    this.showDetail = showSection;
  }
  resetForm(){
    this.forgotForm.value.email,
    this.forgotForm.value.mobile,
    this.forgotForm.value.countryCode
    
    
    
    
    
      this.forgotForm.reset({
        
        email:'',
        mobile: '',
        countryCode:''
      })
    }
    // Get StateList Functionality
    getStateList() {
      // var stateList = this.countryList.filter(x => (x.country == event.target.value));
      // this.stateList = stateList[0].states;
      // this.countryCode = stateList[0].code;
      // this.countryCode = this.countryCode.code
      this.countryCode.code;
      // console.log(this.countryCode);
    }


// forgot function
forgotPass(){
  if(this.showDetail == 'BANK'){
    this.forgotFunc();
  }
  else{
    this.forgotFuncMobile()
  }
}

 // Forgot Functionality with email
 forgotFunc() {
   localStorage.setItem('email',this.forgotForm.value.email)
  var url = "account/forget-password-mobile-app?email="+this.forgotForm.value.email
   this.service.showSpinner();
   this.service.getCandyPixelForms(url).subscribe(res => {
     if (res['status'] == 200) {
       this.service.hideSpinner();
       this.forgotForm.reset();
       $("#emailAuth").modal("show");

        this.service.showSuccessMessage(res['message'])
     }
     if(res['status'] == 400){
      this.service.showErrorMessage(res['error'])
     }
     else {
       this.service.hideSpinner();
       this.service.showErrorMessage(res['message']);
     }
   }, err => {
     if(err['status']== 400){
      this.service.showErrorMessage(err['error']['error'])

     }
     this.service.hideSpinner();
    // this.service.showErrorMessage(err['message']);

   })
 }

//  forgot with mobile number
forgotFuncMobile() {
  localStorage.setItem('email',this.forgotForm.value.email)
  localStorage.setItem('countryCode',(this.forgotForm.value.countryCode + this.forgotForm.value.mobile))
  var url = `account/send-phoneNo-code?phoneNo=${String(`${this.forgotForm.value.countryCode + this.forgotForm.value.mobile}`).replace('+','%2B')}`;

  this.service.showSpinner();
  this.service.getCandyPixelForms(url).subscribe(res => {
    if (res['status'] == 200) {
      this.service.hideSpinner();
      this.forgotForm.reset();
      $("#smsAuth").modal("show");

       this.service.showSuccessMessage(res['message'])
    }
    if(res['status'] == 400){
     this.service.showErrorMessage(res['error'])
    }
    else {
      this.service.hideSpinner();
      this.service.showErrorMessage(res['message']);
    }
  }, err => {
    if(err['status']== 400){
     this.service.showErrorMessage(err['error']['error'])

    }
    this.service.hideSpinner();
   // this.service.showErrorMessage(err['message']);

  })
}

//  forgotFunc1() {
//   var url = "account/forget-password?email="+this.forgotForm.value.email+"&webUrl=" + this.service.webUrl + "/resetpassword/";

//    this.service.showSpinner();
//    this.service.getCandyPixelForms(url).subscribe(res => {
//      if (res['status'] == 200) {
//        this.service.hideSpinner();
//        this.forgotForm.reset();
//         this.service.showSuccessMessage(res['message'])
//      }
//      if(res['error'].status == 400){
//       this.service.showErrorMessage('sdfsdfds')
//      }
//      else {
//        this.service.hideSpinner();
//        this.service.showErrorMessage(res['message']);
//      }
//    }, err => {
//      this.service.hideSpinner();
//    })
//  }

verifyEmail:any
 emailVerification() {
   this.verifyEmail = localStorage.getItem('email')
   
  // var url = `account/verify-sms-code-mobile-app?email=${this.forgotForm.value.email}`;
  var url = "account/verify-sms-code-mobile-app?email="+this.verifyEmail
 
  var smsapireq = {
    otp: this.emailAuth,
    ipAddress: this.ipAddress,
    source: "WEB",
  };
  this.service.showSpinner();
  this.service.postCandyPixelForms(url, smsapireq).subscribe(
    (res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        this.emailAuth = "";
        // localStorage.removeItem('email')
        // this.getProfile();
        this.service.showSuccessMessage(res["message"]);
        $("#emailAuth").modal("hide");
      // $("#emailAuth").modal("show");
        this.router.navigate(['/resetpassword'])
      } else {
        this.service.showErrorMessage(res["message"]);
      }
    },
    (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage("Something Went Wrong");
    }
  );
}

// resend email
resendEmail(){
  // let url =   // https://java-bitbharat.mobiloitte.org/account/send-phoneNo-code?phoneNo=%2B919582062028
  // var url = `account/send-phoneNo-code?phoneNo=${(`${this.signupForm.value.countryCode + this.signupForm.value.mobile}`).replace('+','%2B')}`;
  this.verifyEmail = localStorage.getItem('email')

  // var url = `account/resend-verify-email?email=${this.verifyEmail}&webUrl=string`
  var url = `account/forget-password-mobile-app?email=${this.verifyEmail}&webUrl=string`

    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.emailAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage(res["message"]);
          // $("#smsAuth").modal("hide");
          // this.router.navigate(['/login'])
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }

  verifyMobileCode
  resendMobileOtp() {
   this.verifyMobileCode = localStorage.getItem('countryCode')
    var url = `account/send-phoneNo-code?phoneNo=${String(`${this.verifyMobileCode}`).replace('+','%2B')}`;

    // var smsapireq = {
    //   otp: this.smsAuth,
    //   ipAddress: this.ipAddress,
    //   source: "WEB",
    // };
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage(res['message']);
          // $("#smsAuth").modal("show");
        // $("#emailAuth").modal("show");
          // this.router.navigate(['/login'])
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
  mobileVerification() {
   this.verifyMobileCode = localStorage.getItem('countryCode')

    var url = `account/verify-phone-Sms-code?phoneNo=${String(`${this.verifyMobileCode}`).replace('+','%2B')}`;
    var smsapireq = {
      otp: this.smsAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage(res['message']);
          $("#smsAuth").modal("hide");
        // $("#emailAuth").modal("show");
          this.router.navigate(['/resetpassword'])
        } else {
          this.service.hideSpinner()
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
}

