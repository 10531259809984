import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  loginStatus: any = false;

  constructor(public service:ServiceService) { 
   
     

  }

  ngOnInit() {
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
    this.faqList()
  }
  navigateToUrl(urlvalue){
    if (urlvalue=='huobi'){
      window.open('https://www.huobi.com/en-us/')
    }
    else if (urlvalue=='okblockchain'){
      window.open('https://www.okcoin.com/')
    }
    else if(urlvalue=='nodecapital'){
      window.open('http://www.nodecap.com/')
    }
    else if(urlvalue=='genesis'){
      window.open('https://www.genesisblockchain.io/')
    }
    else if(urlvalue=='plumventures'){
      window.open('http://www.plumventures.cn/')
    }
    else if(urlvalue=='playstore'){
      window.open('https://play.google.com/store')
    }
    else if(urlvalue=='appstore'){
      window.open('https://www.apple.com/in/app-store/')
    }
  }
  navigateToSocialMedia(val){
    if (val=='linkedin'){
      window.open('https://www.linkedin.com/signup')
    }
    else if (val=='twitter'){
      window.open('https://twitter.com/?lang=en')
    }
    else if (val=='telegram'){
      window.open('https://telegram.org/')
    }
    else if (val=='instagram'){
      window.open('https://www.instagram.com/')
    }
    else if (val=='youtube'){
      window.open('https://www.youtube.com/')
    }
  }
  
  download(e) {
    alert(e + 'comming soon')
  }
  click(){
   
  }
  faqData:any  = [];
  faqList(){
    this.service.showSpinner();
    var url = `static/get-link-list`  ;
    this.service.getCandyPixelForms(url).subscribe((res:any)=>{
      console.log("kyc", res);
      if(res.status==200){
        this.faqData=res.data;
      }
      this.service.hideSpinner();
    },(err)=>{
      if(err['status']==401){
      this.service.hideSpinner();
        this.service.showErrorMessage('Unauthorized Access')
      }
      else{
      this.service.hideSpinner();
        this.service.showErrorMessage('Something Went Wrong');
     }
    })
  }
}
