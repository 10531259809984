import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.css']
})
export class FeesComponent implements OnInit {
  coinList: any;
  FeeArray: any=[];

  constructor(public service: ServiceService, public route: Router,) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.getCoinList()
  }

   // to get coin list
   getCoinList() {
    this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe((res) => {
      if (res['status'] == 200) {
       this.FeeArray= res['data']
       
      }
      let removeCoin = ['BCH','OMG','XLM','DASH','LTC']
        this.FeeArray = this.FeeArray.filter((ele)=>{
          return !removeCoin.includes(ele.coinShortName)
        })
    })
  }

}
