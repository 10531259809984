import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staking-history-detail',
  templateUrl: './staking-history-detail.component.html',
  styleUrls: ['./staking-history-detail.component.css']
})
export class StakingHistoryDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
