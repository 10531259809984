import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  privacyData: any;

  constructor(public service: ServiceService) { 
    window.scrollTo(0,0)

  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getPrivacy()
  }
  getPrivacy(){ 
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=Privacy%20Policy').subscribe(res=>{
           if (res['status']==200){
             this.privacyData=res['data'].pageData;
           }
    })
   }
}
