import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {
  announcmentData:any = [];
  pageSize: any = 5
  currentPage = 1
  total: any
  constructor(public service: ServiceService) {
    window.scrollTo(0,0)
   }

  ngOnInit() {
  this.getAnnouncementData()

  }
  
  getAnnouncementData(){ 
    // let url = `static/get-announcement-list?page=${this.currentPage}&pageSize=${this.pageSize}`
    let url = 'static/get-all-announcement-for-website'
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res=>{
           if (res['status']==200){
             this.announcmentData=res['data']['details'];
             this.service.hideSpinner()
            //  this.service.showSuccessMessage(res['message'])
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }
  
}
