import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-staking-details',
  templateUrl: './staking-details.component.html',
  styleUrls: ['./staking-details.component.css']
})
export class StakingDetailsComponent implements OnInit {
  coinList: any = []
  dropCoin: string = 'Coin';
  dropImg: string = 'assets/bitcoin.png'

  constructor(public service: ServiceService, private route: Router) { }

  ngOnInit() {
    this.getCoinList()
    this.getFdAmount()
  }


  getCoinList() {
    this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe((getLiquidity) => {
      if (getLiquidity['status'] == 200) {
        let index = getLiquidity['data'].findIndex(x => x.coinShortName == 'USD')
        if (index != 1) {
          getLiquidity['data'].splice(index, 2)
          this.coinList = getLiquidity['data']
        }
        console.log(this.coinList);


        // console.log(this.coinList);


      }
    })
  }
  getFdAmount() {
    let url = `wallet/view-fixed-deposit-List-With-CoinName?coinName=${this.dropCoin}&fixedStatus=COMPLETED`
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res['status'] == 200) {

        console.log(res);
      }
    })
  }

  amount = 0
  walletBalance: any
  cmc: any
  getTotalPercentage(percent) {
    this.amount = (percent / 100) * this.walletBalance

  }
  // dropCoin:any
  addCoin() {
    let temp = this.coinList.filter((getLiquidity) => {
      return getLiquidity[0].coinShortName
    })
    console.log(temp);
  }

  fillBox(BTC, img) {
    this.dropCoin = BTC;
    this.dropImg = img;
    this.recieveCoin()
    this.sendCoin()
    console.log(this.dropCoin);
  }

  recieveCoin() {


    // this.network = this.networkType[this.selectingCoin.dropCoin]['network']

    this.walletBalance = 0
    if (this.dropCoin == "XRP" || this.dropCoin == "XLM") {
      let url = "wallet/wallet-type2/get-address?coinName=" + this.dropCoin;
      // this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.walletAddress = res["data"].walletAddress;
            // this.tags = res["data"].tag;
            // $("#recieveModal").modal({ backdrop: "static" });
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.route.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            this.service.showErrorMessage("Kyc not done");
          }
        }
      );
    } else {
      let url = "wallet/wallet/get-address?coinName=" + this.dropCoin;
      // this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.walletBalance = 0
          this.amount = 0
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.account = res["data"]["eosAccountName"];
            // this.walletAddress = res["data"].walletAddress;
            this.walletBalance = res['data']['walletBalance'] ? res['data']['walletBalance'] : 0
            this.cmc = res['data']['coinName']
            // $("#recieveModal").modal({ backdrop: "static" });
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.walletBalance = 0
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.route.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            this.service.showErrorMessage("Kyc not done");
          }
        }
      );
    }
  }
  coinWithdrawFee: any
  coinWithdrawAmount: any
  withdrawalAmountMax: any
  interestRate: any
  sendCoin() {
    // this.service.showSpinner();
    this.service.getCandyPixelForms("wallet/coin/get-coin-details?coinName=" + this.dropCoin).subscribe((res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        this.coinWithdrawFee = res["data"].withdrawlFee;
        this.coinWithdrawAmount = res["data"].withdrawalAmount;
        this.withdrawalAmountMax = res['data'].withdrawalAmountMax
        this.interestRate = res['data']['interestRate']
      } else {
        this.service.showErrorMessage(res["message"]);
      }
    },
      (err) => {
        this.service.hideSpinner();
      }
    );

    // this.coinName = coinShortName;
    //this.coinWithdrawFee = this.withdrawfee1;
    // this.sendForm.reset();
    //$('#sendModal').modal({ backdrop: 'static' })
  }
  addFixedDeposit() {
    let url = `wallet/add-fixed-deposit-vauld`
    let data = {
      coinName: this.dropCoin,
      coinAmount: this.amount

    }
    this.service.showSpinner()
    this.service.postApi(url, data).subscribe(res => {
      if (res['status'] == 200) {
        // this.aboutData = res['data'].pageData;
        this.service.hideSpinner()
        this.service.showSuccessMessage(res['message'])
      }
      else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res['message'])
      }
    }, (err => {
      this.service.hideSpinner()
      this.service.showErrorMessage('Insufficient Wallet Balance')
      this.service.showErrorMessage(err['message'])
    })
    )
  }
}
