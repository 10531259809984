import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})
export class BannerListComponent implements OnInit {
  announcementId: any;
  viewAnnonunceData:any
  constructor(private router: Router, public service: ServiceService, private activateRoute: ActivatedRoute) {
    window.scrollTo(0,0)
    this.activateRoute.queryParams.subscribe(res=>{
      this.announcementId = res.id 
    })
    console.log(this.announcementId);
   }

  ngOnInit() {
    this.viewAnnouncement();
  }


  viewAnnouncement(){ 
    let url = `static/get-announcement-by-id?announcementId=${this.announcementId}`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res=>{
           if (res['status']==200){
             
             this.viewAnnonunceData=res['data'];
             this.service.hideSpinner()
            //  this.service.showSuccessMessage(res['message'])
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }
}
