import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-feedback-user",
  templateUrl: "./feedback-user.component.html",
  styleUrls: ["./feedback-user.component.css"],
})
export class FeedbackUserComponent implements OnInit {
  contactForm: FormGroup;
  products: any = [];
  siteKey: string;
  recaptcha: any = "";
  constructor(
    public router: Router,
    public service: ServiceService,
    private httpClient: HttpClient
  ) {
    this.siteKey = "6LciYhwcAAAAAMpAXkcqzippcN1jcv_1_RtnVaZs";
  }
  logdata: any;
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidation();
    this.httpClient.get("assets/contry.json").subscribe((data) => {
      // console.log(data);
      this.products = data;
      // console.log(this.products)
    });
    this.viewNominee()
  }
  // Form Validation
  formValidation() {
    this.contactForm = new FormGroup({
      // 'name': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
        ),
      ]),
      message: new FormControl("", [Validators.required]),
      mobile: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
        Validators.minLength(8),
        Validators.maxLength(15),
      ]),
      // 'countryCode': new FormControl('+91',[Validators.required])
    });
   
  }
  profiledata: any = [];
  dataaa: any;
  // Contact Us Functionality

  

  contactFunc() {

    let data = {
      // 'name': this.contactForm.value.name,
      email: this.contactForm.value.email,
      message: this.contactForm.value.message,
      phoneNo: this.contactForm.value.mobile,
      // "countryCode": this.contactForm.value.countryCode,
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms("account/Feedback-for-static-content", data)
      .subscribe(
        (res) => {
          if (res["status"] == 200) {
            this.service.hideSpinner();
            this.service.showSuccessMessage(
              "Feedback submitted successfully. We will get back to you shortly!"
            );
            this.contactForm.reset();
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.hideSpinner();
        }
      );
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }
  
  profileDataView:any
// view nominee
viewNominee() {
  let url = `account/my-account`
  this.service.showSpinner();
  // this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    this.service.getCandyPixelForms(url).subscribe(
    (res) => {
      if (res["status"] == 200) {
        this.profileDataView = res["data" ];
       
       
          this.contactForm.patchValue({
            email: this.profileDataView.email,
            mobile: this.profileDataView.phoneNo,
          
          });
        
          this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res["message"]);
      }
    },
    (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message']);
    }
  );
}

}
