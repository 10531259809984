import { AfterContentInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-native-token",
  templateUrl: "./native-token.component.html",
  styleUrls: ["./native-token.component.css"],
})
export class NativeTokenComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {
  window.scrollTo(0, 0);

  }


  ngOnInit() {
    // window.scrollTo(0, 0);
  }
  currUrl = "xindia";
  // scrollllun(e) {
  //   this.currUrl = e;
  //   if (e == "xindia") {
  //     return;
  //   }
  //   var divElement = document.getElementById(e);
  //   divElement.scroll({
  //     top: divElement.scrollHeight, //scroll to the bottom of the element
  //     behavior: "smooth", //auto, smooth, initial, inherit
  //   });

  // }
scrol(e){
this.currUrl = e
  var ele = document.getElementById(e);   
  window.scrollTo(ele.offsetLeft,ele.offsetTop);
}

 
}
