import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-career-data',
  templateUrl: './career-data.component.html',
  styleUrls: ['./career-data.component.css']
})
export class CareerDataComponent implements OnInit {
  categoryListArray:any  = [];
  totalItems:any
  constructor(public commonService:ServiceService) { 
    window.scrollTo(0,0)
  }

  ngOnInit() {
    this.getCategoryList();
  }
  scrollllun(){
    var ele = document.getElementById("openCareer");   
    window.scrollTo(ele.offsetLeft,ele.offsetTop);
  }


  // -------------------- get category list --------------------- //
  getCategoryList() {
    let apiReqUrl: any = `static/get-all-career`

    this.commonService.showSpinner();

    // remove empty keys from request body
    // apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.status == 200) {
        this.categoryListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
        // this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.showErrorMessage(res.message)
      }
    },(err)=>{
      console.log(err);
      
      if(err.status == 404){
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }
}
