import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
declare var $;
@Component({
  selector: "app-swap-crypto",
  templateUrl: "./swap-crypto.component.html",
  styleUrls: ["./swap-crypto.component.css"],
})
export class SwapCryptoComponent implements OnInit {
  showDetail: any = "USER";
  searchStatus: boolean = false;
  coinpair: any = [];
  coinDetail: any = {};
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  marketdata: any;
  arr: any = [];
  basecoins = "BTC";
  executables: any;
  tab = "BTC Market";
  baseCoinFun: string;
  baseCoinVal: string;
  myInterval: any;
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  // slider
  coinList: any = [];
  filteredCoinList: any = [];

  constructor(public service: ServiceService, public route: Router) {
    window.scrollTo(0, 0);
    var self = this;
    service.connection.subscribe((connection) => {
      if (connection == "online") {
        this.subscribefunc(1);
      }
    });
    this.service.wsExchange.addEventListener("message", function (event) {
      let data = JSON.parse(event.data);
      if (data.messageType == "TICKER_UPDATE") {
        var symbol = data.symbol.split("_")[0];
        self.arr.forEach((element) => {
          if (element.executable == symbol) {
            element.lastPrice = data.data.lastPrice;
            element.hourChange = data.data.volume24Hour;
            element.hourLow = data.data.lowest24HourPrice;
            element.hourhigh = data.data.highest24HourPrice;
            element.hourVol = data.data.totalVolume;
            element.percentageChange = data.data.percentageChange;
          }
        });
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getAllCoinPairFunc();
    // slider
    this.getCoinList();
    // this.getPrice();
    // this.filterCoin(this.coinList)
    // this.getFavList()
  }
  bankDetails(showSection) {
    this.showDetail = showSection;
  }

  length(n) {
    return new Array(n);
  }
  // Get All Coin Pair Functionality
  getAllCoinPairFunc() {
    // this.service.showSpinner();
    this.arr = [];
    this.service.getCandyPixelForms("wallet/coin/get-symbol-list").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          var ind = res.data.findIndex((x) => x.baseCoin == this.basecoins);
          if (ind > -1) {
            res.data[ind].execCoin.forEach((element) => {
              this.arr.push({
                base: this.basecoins,
                executable: element,
                lastPrice: "",
                hourChange: "",
                hourhigh: "",
                hourLow: "",
                hourVol: "",
              });
            });
          }
          // this.subscribefunc(1);
          setTimeout(() => {
            this.subscribefunc(1);
          }, 1000);
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  subscribefunc(mode) {
    switch (mode) {
      case 1:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "SUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.service.wsExchange.readyState) {
            this.service.wsExchange.send(JSON.stringify(data));
          }
        });
        break;
      case 2:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "UNSUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.service.wsExchange.readyState) {
            this.service.wsExchange.send(JSON.stringify(data));
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscribefunc(2);
  }

  trendList: any = [];

  /**
   * Slider
   */
  getPrice() {
    var getLiquiditydata = [];
    this.service.getLiquidity().subscribe((getLiquidity) => {
      getLiquiditydata = getLiquidity["data"];

      getLiquiditydata.forEach((element) => {
        var ind = this.coinList.findIndex(
          (x) => x.coinShortName == element.symbol
        );

        if (ind != -1) {
          this.coinList[ind].marketPriceInUsd24HourBefore =
            element.quote.USD.percent_change_24h;
          this.coinList[ind].marketPriceInUsdAWeekBefore =
            element.quote.USD.percent_change_7d;
          this.coinList[ind].price = element.quote.USD.price.toFixed(4);
          this.coinList[ind].volume_24h = element.quote.USD.volume_24h;
          this.coinList[ind].last_updated = element.last_updated;
          this.coinList[ind].market_cap = element.quote.USD.market_cap;
        }
      });
      this.filteredCoinList = this.coinList;
      // this.filteredCoinList = this.coinList.sort((x,y) => y.marketPriceInUsd24HourBefore-x.marketPriceInUsd24HourBefore);
      // this.filteredCoinList = sortedArray.splice(0,3)
      this.trendList = this.coinList;
    });
  }
  filterCoin(e) {
    let coinName = String(e.target.value).toLowerCase();
    this.filteredCoinList = this.coinList.filter((element) => {
      let cName = String(element.coinShortName).toLowerCase();
      return cName.includes(coinName);
    });
  }
  favListArray: any = [];
  
  myfavListArray : any = []
  trendingList : any = []
  getCoinList() {
    this.service
      .getCandyPixelForms("wallet/coin/get-coin-list")
      .subscribe((getLiquidity) => {
        if (getLiquidity["status"] == 200) {
          this.myfavListArray = []
          for(let ele of getLiquidity["data"]){
            if(ele.isFavourite){
              this.myfavListArray.push(ele.coinShortName)

            }
          }
         
          let index = getLiquidity["data"].findIndex(
            (x) => x.coinShortName == "USD"
          );
          if (index != 1) {
            getLiquidity["data"].splice(index, 2);
            this.coinList = getLiquidity["data"];
          }
          


          this.filteredCoinList = this.coinList;

         

          $(document).ready(function () {
            $(".main_slider").slick({
              // autoplay: true,
              dots: true,
              loop: false,
              arrow: true,
              infinite: true,
              speed: 300,
              slidesToShow: 5,
              slidesToScroll: 5,
              responsive: [
                {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            });
          });
        }
        this.getPrice();
      });
  }

  buyPaginationEvent(page) {
    this.pageNumberSell = page;
    if (this.searchStatus == true) {
      this.getCoinList();
    } else {
      this.getPrice();
    }
  }
  currUrl = "xindia";

  scrol(e) {
    this.currUrl = e;
    var ele = document.getElementById(e);
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  }

  favouriteCoin(coinId) {
    this.service.showSpinner();
    this.service
      .postApi("wallet/coin/set-coin-favourite-true?coinId=" + coinId, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.getCoinList();
            // this.editData=res.data[0];
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            //  this.service.onLogout();
            this.service.showErrorMessage("Unauthorized Access");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }

  removeFavouriteCoin(coinShortName) {
    this.service.showSpinner();
    this.service
      .postApi("wallet/coin/set-coin-unfavourite?coinName=" + coinShortName, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.getCoinList();
            // this.editData=res.data[0];
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            //  this.service.onLogout();
            this.service.showErrorMessage("Unauthorized Access");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }

  removeFavouriteCoinList(coinShortName) {
    this.service.showSpinner();
    this.service
      .postApi("wallet/coin/set-coin-unfavourite?coinName=" + coinShortName, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.getCoinList();
            // this.editData=res.data[0];
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            //  this.service.onLogout();
            this.service.showErrorMessage("Unauthorized Access");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }
  toFixed(number ,toLength : number){
    return Number(number).toFixed(toLength)
  }
}
