import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';
import { Router, NavigationExtras } from '@angular/router';
declare var $
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  notificationData: any = []
  foo

  constructor(private router: Router, public service: ServiceService, public appC: AppComponent) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.service.notification.subscribe((res) => {
      // this.getNotification()
    })
    this.readNotification()
  }

  //   let objToSend: NavigationExtras = {
  //     queryParams: {
  //     id: 1,
  //     productName: 'Netgear Cable Modem',
  //     productCode: 'CM700',
  //     description: 'Netgear Cable Modem compatible with all cables',
  //     prodRating: 4.9
  //     },
  //     skipLocationChange: false,
  //     fragment: 'top' 
  // };


  // this.router.navigate(['/productdetails'], { 
  // state: { productdetails: objToSend }
  // });
  // }

  navigateTo(order) {
    // let arr = order.message.split(' ')
    // let tradeType = arr[0] == 'buy' ? 'sell' : 'buy';
    // let my_data = `${order.fromUserId}-${order.tradeId}-${order.userName}-${tradeType}-${order.minutes}-${order.peerToPeerExchangeId}`
    // this.appC.chatArr = []
    // this.router.navigate(['contactExchange', my_data])
    let arr = order.data

    this.appC.chatArr = []

    // let navigationExtras: NavigationExtras = { state: { exchangeDetails: objToSend } };

    if ((order.message.includes('You have a Trade Request'))) {

      let exchangeDetails = arr
      let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
      this.router.navigate(['contactExchange'], navigationExtras)

    }
  }

  // read notification make isSeen equal true
  readNotification() {
    this.service.getCandyPixelForms('notification/read-notification').subscribe((res) => {
      this.getNotification()
    })
  }

  // /get all notification data
  getNotification() {
    this.service.showSpinner()
    this.service.getCandyPixelForms('notification/get-notification-data?role=USER').subscribe((res) => {
      if (res['status'] == 1618) {
        
        this.service.hideSpinner()
        this.service.notificationArr = res['data']
      }
    })
  }
  clearNot(){
    $('#clearNotification').modal({ backdrop: "static" });

  }

  deleteNotifications() {
    var apireq = {};
    this.service.showSpinner();
    this.service.deleteApi("notification/delete-notification").subscribe(
      (res) => {
        $('#clearNotification').modal("hide");
        this.service.hideSpinner();
        this.readNotification();
        this.getNotification();
        this.service.showSuccessMessage("Notification Delete Successfully!");
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
}
