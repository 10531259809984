import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-google-authentication-first-page',
  templateUrl: './google-authentication-first-page.component.html',
  styleUrls: ['./google-authentication-first-page.component.css']
})
export class GoogleAuthenticationFirstPageComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0)
  }
  next() {
    this.router.navigate(['/google-authentication-second-page'])
  }

}
