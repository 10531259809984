import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-jobs-form',
  templateUrl: './career-jobs-form.component.html',
  styleUrls: ['./career-jobs-form.component.css']
})
export class CareerJobsFormComponent implements OnInit {
 

  constructor() {
    window.scrollTo(0,0)
   }
  

  ngOnInit() {
  }
 

}
