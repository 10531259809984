import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  constructor() {
    window.scrollTo(0,0)
   }

  ngOnInit() {
  }

  navigateToUrl(urlvalue){
    if (urlvalue=='huobi'){
      window.open('https://www.huobi.com/en-us/')
    }
    else if (urlvalue=='okblockchain'){
      window.open('https://www.okcoin.com/')
    }
    else if(urlvalue=='nodecapital'){
      window.open('http://www.nodecap.com/')
    }
    else if(urlvalue=='genesis'){
      window.open('https://www.genesisblockchain.io/')
    }
    else if(urlvalue=='plumventures'){
      window.open('http://www.plumventures.cn/')
    }
    else if(urlvalue=='playstore'){
      window.open('https://play.google.com/store')
    }
    else if(urlvalue=='appstore'){
      window.open('https://www.apple.com/in/app-store/')
    }
  }
}
