import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-bank-account-list',
  templateUrl: './bank-account-list.component.html',
  styleUrls: ['./bank-account-list.component.css']
})
export class BankAccountListComponent implements OnInit {
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  faqData : any  = [];
  searchStatus: boolean = false;
  bankDetailsList: any = [];
  constructor(public service: ServiceService) { }

  ngOnInit() {
    this.getBankList()
  }
  // for pagination
  sellPaginationEvent(page) {
    this.pageNumberBuy = page;
    if (this.searchStatus == true) {
      // this.searchOrderList();
    } 
  }

  getBankList(){
     this.service.getCandyPixelForms('account/get-user-account-list').subscribe(res=>{
           if (res['status']==200){
             this.bankDetailsList=res['data'];
           }
    })
   }
}
