import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { DatePipe } from '@angular/common';
declare var $
@Component({
  selector: 'app-dashboard-trades',
  templateUrl: './dashboard-trades.component.html',
  styleUrls: ['./dashboard-trades.component.css']
})
export class DashboardTradesComponent implements OnInit {
  tradeTotal: any;
  tableType: any = 'Open Trades'
  pageNumberTrade: any = 1;
  pageSize: any = 5;
  status: any = ''
  getTradingArr: any = [];
  walletData: any;
  action: any;

  buyAdvertisementStatus: any
  sellAdvertisementStatus: any
  buyAdvertisementStatusSend: any
  sellAdvertisementStatusSend: any
  constructor(private router: Router, public server: ServiceService, private datePipe: DatePipe) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.showTradeTable('Open Trades');
    this.getBalance()
    this.getAdvertisementStatus()
  }

  showTradeTable(type) {
    this.tradeTotal = 0
    this.tableType = type
    this.pageNumberTrade = 1
    if (this.tableType != 'Open Trades') {
      if (this.tableType == 'Completed Trade') {
        this.status = ''
      } else if (this.tableType == 'Complete Trades') {
        this.status = 'COMPLETE'
      } else if (this.tableType == 'Cancel Trades') {
        this.status = 'CANCEL'
      }
      this.getTradingList();
    } else {
      this.getOpenOrders();
    }
  }

  // to get open orders
  getOpenOrders() {
    this.getTradingArr = []
    this.server.getCandyPixelForms(`p2p-exchange/get-open-trade-list?page=${this.pageNumberTrade - 1}&pageSize=${this.pageSize}`).subscribe((res) => {

      if (res['status'] == 200) {
        this.getTradingArr = res['data'].RESULT_LIST
        this.tradeTotal = res['data'].TOTAL_COUNT
      }
    })
  }

  // to get trading details list
  getTradingList() {
    this.getTradingArr = []
    this.server.getCandyPixelForms(`p2p-exchange/get-trading-details-list?page=${this.pageNumberTrade - 1}&tradeStatus=${this.status}&pageSize=${this.pageSize}`).subscribe((res) => {

     
      if (res['status'] == 200) {
        this.server.hideSpinner();
        if (this.tableType == 'Completed Trade') {
          this.getTradingArr = res['data'].totalCount.content
          this.tradeTotal = res['data'].totalCount.totalElements
        } else {
          this.server.hideSpinner();
          this.getTradingArr = res['data'].RESULT_LIST
          this.tradeTotal = res['data'].TOTAL_COUNT
        }
      }
    })
  }

  // tarde pagination 
  tradePaginationEvent(page) {
    this.pageNumberTrade = page
    // this.getTradingList();
    if (this.tableType != 'Open Trades') {
      if (this.tableType == 'Completed Trade') {
        this.status = ''
      } else if (this.tableType == 'Complete Trades') {
        this.status = 'COMPLETE'
      } else if (this.tableType == 'Cancel Trades') {
        this.status = 'CANCEL'
      }
      this.getTradingList();
    } else {
      this.getOpenOrders();
    }
  }

  // get wallet balence
  getBalance() {
    this.server.showSpinner();
    this.server.getCandyPixelForms('wallet/wallet/get-balance?coinName=BTC').subscribe((res) => {
      this.server.hideSpinner()
      if (res['status'] == 200) {
       
        this.walletData = res['data']
        this.server.hideSpinner();
      }
    })
  }

  // create advertisement
  createAdvertise() {
    this.router.navigate(['/p2p-data'])
  }


  /**
   * set advertisement ENABLE or DISABLE of buy and sell 
   */

  // get enable/disable advertisement status of buy and sell
  getAdvertisementStatus() {
    this.server.getCandyPixelForms('p2p-exchange/get-advertisements-status?orderType=SELL').subscribe((res) => {
      if (res['status'] == 200) {
        this.sellAdvertisementStatus = res['data'].status
        this.sellAdvertisementStatusSend = this.sellAdvertisementStatus
      }
    })
    this.server.getCandyPixelForms('p2p-exchange/get-advertisements-status?orderType=BUY').subscribe((res) => {
      if (res['status'] == 200) {
        this.buyAdvertisementStatus = res['data'].status
        this.buyAdvertisementStatusSend = this.buyAdvertisementStatus
      }
    })
  }

  // set advertisement enable/disable of buy and sell
  setEnableDisableAdvertisement() {
    if (this.sellAdvertisementStatus != this.sellAdvertisementStatusSend) {
      this.server.postApi(`p2p-exchange/set-enable-disable-all-advertisements?orderType=SELL&statusType=${this.sellAdvertisementStatusSend}`, {}).subscribe((res) => {
        if (res['status'] == 200) {
          this.getOpenOrders();
          this.getAdvertisementStatus();
        }
      })
    }
    if (this.buyAdvertisementStatus != this.buyAdvertisementStatusSend) {
      this.server.postApi(`p2p-exchange/set-enable-disable-all-advertisements?orderType=BUY&statusType=${this.buyAdvertisementStatusSend}`, {}).subscribe((res) => {
        if (res['status'] == 200) {
          this.getOpenOrders();
          this.getAdvertisementStatus();
        }
      })
    }
  }

  // get sell advertisement status chages form UI
  sellAdvertisementChange(event) {
    if (this.sellAdvertisementStatusSend == 'ENABLE') {
      this.sellAdvertisementStatusSend = 'DISABLE'
    } else {
      this.sellAdvertisementStatusSend = 'ENABLE'
    }
  }

  // get buy advertisement status chages form UI
  buyAdvertisementChange(event) {
    if (this.buyAdvertisementStatusSend == 'ENABLE') {
      this.buyAdvertisementStatusSend = 'DISABLE'
    } else {
      this.buyAdvertisementStatusSend = 'ENABLE'
    }
  }


  /**
   * Export csv data of trade all/complete/cancel
   */
  exportCSV() {
    let dataArr = [];
    dataArr.push({
      sNo: "S.No.",
      createdAt: "Created At",
      tradeType: "Trade Type",
      tradingPartner: "Trading Partner",
      transactionStatus: "Transaction Status",
      fiat: "Fiat",
      tradeAmount: "Trade Amount",
      tradingFee: "Trading Fee",
      totalBTC: "Total BTC",
     
    })
    this.getTradingArr.forEach((element, ind) => {
      dataArr.push({
        sNo: ind + 1,
        createdAt: element.creationTime ? this.datePipe.transform((element.creationTime),'medium') : '--',
        tradeType: element.type ? element.type : '--',
        tradingPartner: element.tradingPartner ? element.tradingPartner : '--',
        transactionStatus: element.statusType ? element.statusType : '--',
        fiat: element.fiatCoin ? element.fiatCoin : '--',
        tradeAmount: element.tradeAmount ? element.tradeAmount : '--',
        tradingFee: element.tradeFee ? element.tradeFee : '--',
        totalBTC: element.totalBTC ? element.totalBTC : '--',
        exchangeRate: element.price ? element.price : '--'
      })
    })
    new ngxCsv(dataArr, 'Trades Data')
  }

   /**
   * Export csv data of trade all/complete/cancel
   */
    exportCSVOpenOrder() {
      let dataArr = [];
      dataArr.push({
        sNo: "S.No.",
        exchangeStatusType: "Advertisement Status",
        tradeType: "Trade Type",
        paymentType: "Payment Typer",
        // transactionStatus: "Transaction Status",
        fiat: "Fiat",
        price: "Equation",
        createdAt: "Created At",
       
       
      })
      this.getTradingArr.forEach((element, ind) => {
        dataArr.push({
          sNo: ind + 1,
          createdAt: element.creationTime ? this.datePipe.transform((element.creationTime),'medium') : '--',
          exchangeStatusType : element.exchangeStatusType ? element.exchangeStatusType : '--',
          tradeType: element.orderType ? element.orderType : '--',
          paymentType: element.paymentType ? element.paymentType : '--',
          // transactionStatus: element.statusType ? element.statusType : '--',
          fiat: element.fiatCoin ? element.fiatCoin : '--',
          price: element.price ? element.price : '--',
          // tradingFee: element.tradeFee ? element.tradeFee : '--',
          // totalBTC: element.totalBTC ? element.totalBTC : '--',
          // exchangeRate: element.price ? element.price : '--'
        })
      })
      new ngxCsv(dataArr, 'Trades Data')
    }

    viewExchangeP2p(peerToPeerExchangeId) {
      // alert(peerToPeerExchangeId)
      this.router.navigate(['/create-edit'], { queryParams: { peerToPeerExchangeId: peerToPeerExchangeId } })
    }
    fkUserId:any
    peerToPeerExchangeIds:any
    // Delete / Block Function
    type:any
  openModal(action,peerToPeerExchangeId,type) {
    // alert(action)
    // alert(peerToPeerExchangeId)
    this.type = type
    this.peerToPeerExchangeIds = peerToPeerExchangeId;
    this.action = action;
    if (action == 'ENABLED') {
      $('#active').modal('show')

    } else if (action == 'DISABLED') {
      $('#block').modal('show')
    }
    // else {
    //   $('#active').modal('show')
    // }
  }

  performAction() {
    // var url = 'p2p-exchange/set-enable-disable-all-advertisements?orderType='+this.type+'&statusType=' + (this.action) + '&peerToPeerExchangeId=' + (this.peerToPeerExchangeIds);
    var url = 'p2p-exchange/set-enable-disable-advertisements?orderType='+this.type+'&exchangeStatusType=' + (this.action) + '&peerToPeerExchangeId=' + (this.peerToPeerExchangeIds);

    // var url = 'account/admin/user-management/user-status?ipAddress=' + (11) + '&location=' + ('unknown') + '&userIdForStatusUpdate=' + (this.userID) + '&userStatus=' + (this.action);
    this.server.showSpinner();
    this.server.postApi(url, '').subscribe(res => {

      this.server.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'DISABLED') {
          $('#block').modal('hide');
          this.server.showSuccessMessage('Advertisements Disabled Successfully');
        }
        else {
          $('#active').modal('hide');
          this.server.showSuccessMessage('Advertisements Enabled Successfully');
        }
        this.getOpenOrders();
      }
    }, err => {

      this.server.hideSpinner();
      if (err['status'] == '401') {
        // this.server.onLogout();
        this.server.showErrorMessage('Unauthorized Access');
      } else {
        this.server.showErrorMessage('Something Went Wrong');
      }
    })
  }


  
  deleteModal(peerToPeerExchangeId) {
    $('#deleteModal').modal('show')
    this.peerToPeerExchangeIds = peerToPeerExchangeId
  }
  deleteCategory() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = "p2p-exchange/Delete-advertisement?peerToPeerExchangeId="+this.peerToPeerExchangeIds
    this.server.showSpinner();
    this.server.deleteApi(apiReqUrl).subscribe((res: any) => {
      // console.log("delete category user response ==>", res)
      $('#deleteModal').modal('hide');
      if (res.status == 200) {
        this.getOpenOrders()
        this.server.showSuccessMessage(res.message);
      } else {
        this.server.hideSpinner();
        this.server.showErrorMessage(res.message)
      }
    })
  }
}


