import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-authentication-page',
  templateUrl: './sms-authentication-page.component.html',
  styleUrls: ['./sms-authentication-page.component.css']
})
export class SmsAuthenticationPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)
  }

}
