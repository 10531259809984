import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  pageNumber: number = 1;
  totalRecord: any;
  pageSize:number=10
  totalItems: any;
  blogData:any = [];
  userID: any;

  constructor(public router: Router,public service: ServiceService,private activated : ActivatedRoute) { 
    window.scrollTo(0,0)
   }

  ngOnInit() {
    this.getBlogData()
  }
  getBlogData(){ 
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-Blog-list').subscribe(res=>{
           if (res['status']==200){
             
             this.blogData=res['data'];
             this.service.hideSpinner()
            //  this.service.showSuccessMessage(res['message'])
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }

  //User Details
  viewBlog(id ) {
    this.router.navigate(['/blog-detail'], { queryParams: { id:id } })
  }
}
