import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-carrers',
  templateUrl: './carrers.component.html',
  styleUrls: ['./carrers.component.css']
})
export class CarrersComponent implements OnInit {
  contactForm: FormGroup;

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
  }

  formValidation(){
    this.contactForm = new FormGroup({
      'name': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
      'email': new FormControl('',[Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'title': new FormControl(''),
      'description': new FormControl('')
    })
  }

}
