import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  contentId: any;
  viewBlogData:any
  current_Url : any = window.location.href

  constructor(private router: Router, public service: ServiceService, private activateRoute: ActivatedRoute) {
    window.scrollTo(0,0)
    this.activateRoute.queryParams.subscribe(res=>{
      this.contentId = res.id 
    })
    console.log(this.contentId);
   }

  ngOnInit() {
    this.viewBlog();
  }


  viewBlog(){ 
    let url = `static/get-Blog-by-id?blogId=${this.contentId}`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res=>{
           if (res['status']==200){
             
             this.viewBlogData=res['data'];
             this.service.hideSpinner()
            //  this.service.showSuccessMessage(res['message'])
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }

   shareToWhatsapp(socialMedia){
   
    var mail = document.createElement("a");
    if(socialMedia == 'whatsapp'){
      
        mail.href = `whatsapp://send?text=${this.current_Url}`
        
      
      
    }
    else if(socialMedia == 'facebook'){
      mail.href = `https://www.facebook.com/sharer/sharer.php?u=${this.current_Url}`
    }
    else if(socialMedia == 'twitter'){
      mail.href = "https://twitter.com/share?url="+ encodeURIComponent(this.current_Url)+"&text="+document.title
    }
    else if(socialMedia == 'instagram'){
      mail.href = `https://www.instagram.com/?url=${this.current_Url}`
    }
   
  
    mail.target = "_blank"
    mail.click()
    // this.service.toasterSucc("Replied Successfully")
    


}
 // copy text
 copyDynamicText() {
  let shareLink = window.location.href
  this.service.showSuccessMessage('Sharing link copy successfully.')
  navigator.clipboard.writeText(shareLink)
}
}
