import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
// import * as countryListDetails from 'src/assets/contry.json';
import { HttpClient } from "@angular/common/http";
import { IMyDpOptions } from "mydatepicker";

declare var $;
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  signupForm: FormGroup;
  signupData: any = {};
  encryptedData: any = "";
  type_pass: any = "password";
  type_pass2: any = "password";
  recaptcha: any = "";
  webUrl: string;
  countryList: any = [];
  stateList: any = [];
  countryCode: any = [];
  countryCodeArray: any = [];
  CountryCodeList: any;
  refferalCode: any;
  products: any = [];
  countryListJson: any = [];
  ipAddress: any;
  smsAuth: any = "";
  emailAuth: any = "";
  profileData: any;
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    public service: ServiceService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.myReferalCode) {
        this.refferalCode = res.myReferalCode;
        console.log(this.refferalCode);
        
      }
    });

    this.formValidation();
    window.scrollTo(0, 0);
    if (localStorage.getItem("signupData")) {
      this.signupData = this.service.decryptData(
        localStorage.getItem("signupData")
      );
      this.signupForm.patchValue({
        email: this.signupData.email,
        password: this.signupData.password,
        confirmPassword: this.signupData.password,
      });
    }
    this.countryCode = this.service.countryListJson;
    this.getStateList();

    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
  }
  // Form Validation
  formValidation() {
    this.signupForm = new FormGroup({
      email: new FormControl(this.service.email, [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
        ),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i
        ),
      ]),
      confirmPassword: new FormControl("", Validators.required),
      mobile: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
        Validators.minLength(8),
        Validators.maxLength(15),
      ]),
      countryCode: new FormControl("+91", [Validators.required]),
      // 'mobile': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/), Validators.minLength(8), Validators.maxLength(15)]),
      //'term': new FormControl('', [Validators.required]),
      //'agree': new FormControl('', [Validators.required]),
    });
  }

  // Signup Functionality
  // signupFunc() {
  //   var formData = {
  //     email: this.signupForm.value.email,
  //     password: this.signupForm.value.password,
  //     phoneNo: `${this.signupForm.value.countryCode}${this.signupForm.value.mobile}`,
  //     countryCode: this.signupForm.value.countryCode,
  //     pnWithoutCountryCode: this.signupForm.value.mobile,
  //     refferalCode: this.refferalCode,
  //   };
  //   console.log(formData);

  //   this.encryptedData = this.service.encryptData(formData);
  //   localStorage.setItem("signupData", this.encryptedData);
  //   this.router.navigate(["/register2"]);
  //   this.signupForm.reset();
  // }
  default() {
    this.webUrl = this.service.webUrl;
  }
  // to switch eye
  switchEye() {
    this.type_pass = "text";
  }

  switchToText() {
    this.type_pass = "password";
  }

  // to switch eye
  switchEye2() {
    this.type_pass2 = "text";
  }

  switchToText2() {
    this.type_pass2 = "password";
  }

  login() {
    this.router.navigate(["/login"]);
  }

  // Get StateList Functionality
  getStateList() {
    // var stateList = this.countryList.filter(x => (x.country == event.target.value));
    // this.stateList = stateList[0].states;
    // this.countryCode = stateList[0].code;
    // this.countryCode = this.countryCode.code
    this.countryCode.code;
    // console.log(this.countryCode);
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }

  // Enable SMS Auth Functionality
  // openSMSAuthModal() {
  //   this.smsAuth = '';
  //   if (this.profileData.twoFaType != 'GOOGLE') {
  //     this.service.showSpinner();
  //     this.service.getCandyPixelForms('account/send-sms-code').subscribe(res => {
  //       this.service.hideSpinner();
  //       if (res['status'] == 200) {
  //         $('#smsAuth').modal({ backdrop: 'static' });
  //         this.service.showSuccessMessage(res['message']);
  //       }
  //       else {
  //         this.service.showErrorMessage(res['message']);
  //       }
  //     }, err => {
  //       this.service.hideSpinner();
  //       if (err['status'] == '500') {
  //         this.service.showErrorMessage('Mobile Number Not Verified');
  //       }
  //       else {
  //         this.service.showErrorMessage(err['message']);
  //       }
  //     })
  //   }
  //   else {
  //     this.service.showErrorMessage('Disable Google Auth First');
  //   }
  // }

  // verifySMSAuth() {
  //   var url = 'account/verify-sms-code';
  //   var smsapireq = {
  //     'code': this.smsAuth,
  //     'ipAddress': this.ipAddress,
  //     'source': 'WEB'
  //   }
  //   this.service.showSpinner();
  //   this.service.postCandyPixelForms(url, smsapireq).subscribe(res => {
  //     this.service.hideSpinner();
  //     if (res['status'] == 200) {
  //       this.smsAuth = '';
  //       // this.getProfile();
  //       this.service.showSuccessMessage(res['message']);
  //       $('#smsAuth').modal('hide');
  //     }
  //     else {
  //       this.service.showErrorMessage(res['message']);
  //     }
  //   }, err => {
  //     this.service.hideSpinner();
  //     this.service.showErrorMessage('Something Went Wrong');
  //   })
  // }

  // Date of Birth Management
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 },
    height: "33px",
    // background : '#000'
  };
  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear() - 18,
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
  // Signup Api Integration
  signupFunc() {
    // $("#smsAuth").modal("show");

    var apireq = {
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
      phoneNo: `${
        this.signupForm.value.countryCode + this.signupForm.value.mobile
      }`,
      countryCode: this.signupForm.value.countryCode,
      pnWithoutCountryCode: this.signupForm.value.mobile,
      // refferalCode: this.refferalCode,
      roleStatus: "USER",
      // "webUrl": this.service.webUrl + '/register2',
      // "webUrl": this.service.webUrl,
      webUrl: "string",
      myRefferalCode: "string",
      referredCode: this.refferalCode ? this.refferalCode : 'string',
      address: "",
      city: "",
      country: "",
      deviceToken: "string",
      deviceType: "string",
      dob: "string",
      firstName: "",
      imageUrl: "",
      lastName: "",
      middleName: "",
      state: "",
      zipCode: "",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms("account/signup", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          localStorage.removeItem("signupData");
          this.service.showSuccessMessage(res["message"]);
          $("#emailAuth").modal("show");

          // this.router.navigate(['/login'])
        } else if (res["status"] == 201) {
          // $("#smsAuth").modal("show");
          this.service.showErrorMessage("Email Already Registered");
        } else if (res["status"] == 205) {
          this.service.showErrorMessage(res["message"]);
          // this.resendEmail();
          // $("#emailAuth").modal("show");
        } else if (res["status"] == 207) {
          this.resendEmail();
          $("#emailAuth").modal("show");
        }
      },
      (err) => {
        this.service.showErrorMessage("Something Went Wrong");
        this.service.hideSpinner();
      }
    );
  }
  // Get StateList Functionality
  // getStateList(event) {
  //   var stateList = this.countryList.filter(x => (x.country == event.target.value));
  //   this.stateList = stateList[0].states;
  //   this.countryCode = stateList[0].code;
  // }

  mobileVerification() {
    var url = `account/verify-phone-Sms-code?phoneNo=${String(
      `${this.signupForm.value.countryCode + this.signupForm.value.mobile}`
    ).replace("+", "%2B")}`;

    var smsapireq = {
      otp: this.smsAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage("Successfully Registered");
          $("#smsAuth").modal("hide");
          // $("#emailAuth").modal("show");
          this.router.navigate(["/login"]);
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  resendOtp() {
    // let url =   // https://java-bitbharat.mobiloitte.org/account/send-phoneNo-code?phoneNo=%2B919582062028
    var url = `account/send-phoneNo-code?phoneNo=${`${
      this.signupForm.value.countryCode + this.signupForm.value.mobile
    }`.replace("+", "%2B")}`;

    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage(res["message"]);
          // $("#smsAuth").modal("hide");
          // this.router.navigate(['/login'])
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }

  // email otp verification
  emailVerification() {
    // var url = `account/verify-user?token=${`${this.emailAuth}`
    let url = "account/verify-user?token=" + this.emailAuth;

    // var smsapireq = {
    //   otp: this.smsAuth,
    //   ipAddress: this.ipAddress,
    //   source: "WEB",
    // };
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage(
            "An OTP has been sent on your entered mobile number. Please validate the account."
          );
          // $("#smsAuth").modal("hide");
          $("#emailAuth").modal("hide");
          // this.resendOtp()
          $("#smsAuth").modal("show");
          // this.router.navigate(['/login'])
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }

  // resend email
  resendEmail() {
    // let url =   // https://java-bitbharat.mobiloitte.org/account/send-phoneNo-code?phoneNo=%2B919582062028
    // var url = `account/send-phoneNo-code?phoneNo=${(`${this.signupForm.value.countryCode + this.signupForm.value.mobile}`).replace('+','%2B')}`;
    var url = `account/resend-verify-email?email=${this.signupForm.value.email}&webUrl=string`;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.emailAuth = "";
          // this.getProfile();
          this.service.showSuccessMessage(res["message"]);
          // $("#smsAuth").modal("hide");
          // this.router.navigate(['/login'])
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
}
function myReferalCode(myReferalCode: any) {
  throw new Error("Function not implemented.");
}
