import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';
declare var $
@Component({
  selector: 'app-admin-message-list',
  templateUrl: './admin-message-list.component.html',
  styleUrls: ['./admin-message-list.component.css']
})
export class AdminMessageListComponent implements OnInit {
  notificationData: any = []
  foo

  constructor(private router: Router, public service: ServiceService, public appC: AppComponent) { }

  ngOnInit() {
    // window.scrollTo(0, 0)
    // this.service.notification.subscribe((res) => {
    //   this.getNotification()
    // })
    // this.readNotification()
  }


  navigateTo(order) {
    let arr = order.data

    this.appC.chatArr = []
    // let order =`http://localhost:4200/admin-chat?tradeId=f176cce5a0814aeda65436e034a70756&toUserId=3`
    let tradeId = order.returnUrl.split('?')[1].split('&')[0].split('=')[1]
    let toUserId = order.returnUrl.split('?')[1].split('&')[1].split('=')[1]

    // let navigationExtras: NavigationExtras = { state: { exchangeDetails: objToSend } };
    this.router.navigate(['/admin-chat'], { queryParams: { tradeId: tradeId, toUserId: toUserId } })
    // let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
    // this.router.navigate(['admin-chat'], navigationExtras)

  }

  // read notification make isSeen equal true
  readNotification() {
    this.service.getCandyPixelForms('notification/read-notification').subscribe((res) => {
      this.getNotification()
    })
  }

  // /get all notification data
  getNotification() {
    this.service.showSpinner()

    this.service.getCandyPixelForms('notification/get-notification-data?role=ADMIN').subscribe((res) => {
      if (res['status'] == 1618) {
        this.service.hideSpinner()

        this.service.messageArr = res['data']
      }
    })
  }

  clearNot(){
    $('#clearNotification').modal({ backdrop: "static" });

  }

  deleteNotifications() {
    var apireq = {};
    this.service.showSpinner();
    this.service.deleteApi("notification/delete-notification").subscribe(
      (res) => {
        $('#clearNotification').modal("hide");
        this.service.hideSpinner();
        this.readNotification();
        this.getNotification();
        this.service.showSuccessMessage("Message Delete Successfully!");
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

}

