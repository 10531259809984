import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {
  showDetail: any = "COMPANY";
  userListingForm:FormGroup
  imageUrl :any
  constructor(public service:ServiceService, private router:Router) {
    window.scrollTo(0,0)
   }
  

  ngOnInit() {
    this.userListingFormValidations()
  }
  bankDetails(showSection) {
    this.showDetail = showSection;
  }

userListingFormValidations(){
  this.userListingForm = new FormGroup({
    email: new FormControl( '',[
      Validators.required,
      Validators.pattern(
        /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
      ),
    ]),
    tokenName : new FormControl('',[Validators.required]),
    'image': new FormControl(''),
    webUrl : new FormControl('',Validators.required),
    whitePaperLink : new FormControl('',Validators.required),
    description : new FormControl ('')

  })

}


sendUserListngForm(){
let url =   `static/add-listing-request-user`
 var apireq = {
  'email' : this.userListingForm.value.email,
  'tokenName' : this.userListingForm.value.tokenName,
  'website' : this.userListingForm.value.webUrl,
  'whitepaperLink' : this.userListingForm.value.whitePaperLink,
  'description': this.userListingForm.value.description,
  'coinImage' : this.imageUrl
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms(url, apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          this.router.navigate(['/homepage'])
          // this.getProfile();
          this.service.showSuccessMessage(res['message']);
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
}

// Image Functionality Start Here
uploadImg($event): void {
  var img = $event.target.files[0];
  this.uploadImageFunc(img);
}
uploadImageFunc(img) {
  var fb = new FormData();
  fb.append('file', img)
  this.service.showSpinner();
  this.service.postApi('account/upload-file', fb).subscribe(res => {
    // this.service.hideSpinner();
    if (res['status'] == '200') {
      this.imageUrl = res['data'];
      this.service.hideSpinner();
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      // this.service.onLogout();
      this.service.showErrorMessage('Unauthorized Access');
    } else {
      this.service.showErrorMessage('Something Went Wrong');
    }
  })
}
}
