import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
declare var $
@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit {
  showDetail: any = "BANK";
  showAccountDetail : any = "ACC"
  adminBankDetails:any = "ADMINBANK"
  addAccountForm:FormGroup
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  faqData : any  = [];
  searchStatus: boolean = false;
  bankDetailsList: any = [];
  pageNumber: number = 1;
  itemsPerPage: number = 5;
  isSearched:boolean = false
  totalItems:any
  addUpiForm:FormGroup
  // lodaer : boolean = true
  withdrawInr:FormGroup
  //  showUpiDetail : boolean = false;
  widthDwalTerms = [
    {item:"You have seen the deposit Account number, IFSC code, and Name."},
    {item:"Deposit in that account number, which b showing in your Bt-Bharat Exchange. (Don't deposit another account or wrong account, We are not responsible for wrong account deposit)"},
    {item:"After deposit, the payment waits 30 minutes. (It will take a maximum of 30 minutes to reflect in the Bit-Bharat Exchange wallet)"},
    {item:"Always deposit from your registered bank account. Ifyou have not added a bank account so before deposit first Add a bank account."},
    {item:"Bank Transfer deposit charge 10Rupees/Transaction. This charge is applied from our Nbanking services partner's end."}
  ]
  constructor(private router: Router,public service:ServiceService) {}

  ngOnInit() {
    this.formValidation();
    this.depositInr();
    this.upiFormValidation()
    this.getBankList()
    this.getUpiList()
    this.adminAccountList()
    this.adminUpiList()

  }
  bankDetails(showSection) {
    this.showDetail = showSection;
  }
  bitbharatAccountDetail(accountDetail){
this.showAccountDetail = accountDetail
  }
  adminDetails(adminDetails){
this.adminBankDetails = adminDetails
  }
  depositInr(){
    this.withdrawInr =  new FormGroup({
      amountInr: new FormControl('',[
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/),
      ]),
      utrNo: new FormControl('',Validators.required),
      image: new FormControl('',Validators.required)
    })
  }
  // upiAddress(){
  //   this.showUpiDetail = true
  // }

  // Form Validation 
formValidation(){
  this.addAccountForm = new FormGroup({
    'accountHolderName': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
    'accountNumber': new FormControl('',[Validators.required]),
    // 'contactNumber': new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/i)]),
    'bankName': new FormControl('', [Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
    // 'swiftNumber': new FormControl('',[Validators.required]),
    // 'ibanNumber':new FormControl('',[Validators.required]),
    'ifsc':new FormControl('',[Validators.required]),
    'reEnterAccountNumber' : new FormControl('',[Validators.required]),

  })
}
upiFormValidation(){
  this.addUpiForm = new FormGroup({
    'upiid' : new FormControl('',[Validators.required]),
    'reenterupiid' : new FormControl('',[Validators.required]),
    'nickname' : new FormControl('',[Validators.required])
  })
}
  profile() {
    this.router.navigate(["/my-profile"]);
  }
  kyc() {
    this.router.navigate(["/kyc"]);
  }
  security() {
    this.router.navigate(["/security"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  accountDetail() {
    this.router.navigate(["/account"]);
  }
  nomineeDetail() {
    this.router.navigate(["/nominee"]);
  }
  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {

      
      this.getBankList();
    } else {
      this.getBankList();
    }
  }
  // Contact Us Functionality
submitFunc(){
  let data={
    'accountNo': this.addAccountForm.value.accountNumber,
    'accountHolderName': this.addAccountForm.value.accountHolderName,
    "ifsc": this.addAccountForm.value.ifsc,
    'bankName': this.addAccountForm.value.bankName,
    // 'contactNo': this.addAccountForm.value.contactNumber,
    // 'swiftNo':this.addAccountForm.value.swiftNumber,
    // 'ibanNo':this.addAccountForm.value.ibanNumber,
    // "imageUrl": ""
  }
this.service.showSpinner();
//this.service.postCandyPixelForms('static/submit-contact-us-request',data).subscribe(res=>{
this.service.postCandyPixelForms('account/add-user-bank-account',data).subscribe(res=>{

  if (res['status']==200){
    // this.router.navigate(['/bank-list'])
    this.showDetail = "BANK"
    this.getBankList();
    this.service.hideSpinner();
    this.service.showSuccessMessage('Successfully submitted, we will get back to you shortly!')
    this.addAccountForm.reset();
  }
}, err=>{
  this.service.hideSpinner();
})
}
addUpi(){
  let data={
    'name': this.addUpiForm.value.nickname,
    'upiId': this.addUpiForm.value.upiid,
  }
this.service.showSpinner();
//this.service.postCandyPixelForms('static/submit-contact-us-request',data).subscribe(res=>{
this.service.postCandyPixelForms('wallet/wallet/add-Upi-details-User',data).subscribe(res=>{

  if (res['status']==200){
    // this.router.navigate(['/bank-list'])
    this.showDetail = "UPI"
    this.getUpiList();
    this.service.hideSpinner();
    // this.service.showSuccessMessage(res['message'])
    this.addAccountForm.reset();
  }
}, err=>{
  this.service.hideSpinner();
})
 
}
upiDataArray:any = []
getUpiList(){
  let url = `wallet/wallet/User-Upi-List`
  this.service.showSpinner();
this.service.getCandyPixelForms(url).subscribe((res)=>{
  if(res['status'] == 200){
    this.service.hideSpinner()
    // this.service.showSuccessMessage(res['message'])
    this.upiDataArray = res['data']
  }else{
    this.service.hideSpinner()
    this.service.showErrorMessage(res['message'])

  }
})
}

// getBankDetails(){
//   this.service.getCandyPixelForms('account/get-user-account-detail?bankDetailId=74').subscribe(res=>{
//   })
// }
stopEvent(event: any) {
  // event.stopPropagation();
  event.preventDefault();
}
onRightClick() {
  return false;
}
onCopyPaste(event:KeyboardEvent) {
  // console.log(event)
  if ((event.ctrlKey || event.metaKey) && event.charCode == 67){
  return false;
}
  if ((event.ctrlKey || event.metaKey) && event.charCode == 86){
  return false;
}
}
  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  
  }
// bank list get api

 // for pagination
 sellPaginationEvent(page) {
  this.pageNumberBuy = page;
  if (this.searchStatus == true) {
    // this.searchOrderList();
  } 
}
totalRecords:any
getBankList(){
  let url = `account/get-user-account-list?page=${this.pageNumber -1}&pageSize=${this.itemsPerPage}`
   this.service.getCandyPixelForms(url).subscribe(res=>{
         if (res['status']==200){
           this.bankDetailsList=res['data']['RESULT_LIST'];
        this.totalRecords = res['data']['TOTAL_COUNT'];
       

         }
  })
 }
 imageUrl:any
 depositInrApi(){
  let url =`wallet/wallet/Deposit-INR`
  let dat  = {
    amount : this.withdrawInr.value.amountInr,
    utrNo : this.withdrawInr.value.utrNo,
  image: this.imageUrl
  }
  this.service.showSpinner()
  this.service.postApi(url, dat).subscribe((res)=>{
    if(res.status == 200){
      $("#withdrawalInr").modal("hide");

this.service.hideSpinner()
// this.service.showSuccessMessage(res.message)
    }
    else{
      this.service.hideSpinner()
      this.service.showErrorMessage(res.message)
    }
  })
 }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        // this.service.onLogout();
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }
  adminBankData:any = []
  adminAccountList(){
    let url = `wallet/wallet/bank-List-web`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe((res:any)=>{
      if(res.status == 200){
this.adminBankData = res.data  
  this.service.hideSpinner()
  // this.service.showSuccessMessage(res.message)
      }
      else{
        this.service.hideSpinner()
        this.service.showErrorMessage(res.message)
      }
    }) 
  }
  adminUpiData: any = []
  adminUpiList(){
    let url = `wallet/wallet/upi-List-web`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe((res:any)=>{
      if(res.status == 200){
this.adminUpiData = res.data  
  this.service.hideSpinner()
  // this.service.showSuccessMessage(res.message)
      }
      else{
        this.service.hideSpinner()
        this.service.showErrorMessage(res.message)
      }
    }) 
  }
}
