import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-create-add',
  templateUrl: './create-add.component.html',
  styleUrls: ['./create-add.component.css']
})
export class CreateAddComponent implements OnInit {
  activeTab: any;
  searchForm = new FormGroup({
    'currency': new FormControl(''),
    'country': new FormControl(''),
    'paymentType': new FormControl('')
  })
  // buy & sell
  buyOrderArr: any = [];
  sellOrderArr: any = []
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  searchStatus: boolean = false;
  showDetail: any = "BUY";
  showSection: any = "BUY"
  orderType:any = "BUY"
  // create advertisement
  coinList: any = [];
  userWalletBalance: any = [];
  currentPrice: any = 0;
  marketPrice: any = {};
  coin: any = '';
  addForm = new FormGroup({
    // 'coin': new FormControl('', Validators.required),
    'minValue': new FormControl('', Validators.required),
    'maxValue': new FormControl('', Validators.required),
    'margin': new FormControl('', Validators.required),
    // 'term': new FormControl('', Validators.required),
    'orderType': new FormControl('', Validators.required),
    'paymentWindow': new FormControl('', Validators.required),
    'country': new FormControl('', Validators.required),
   //'fiatCoin': new FormControl('', Validators.required),
    'termsOfTrade': new FormControl('', Validators.required),
    //'addTags': new FormControl('', Validators.required),
    'isIdentifiedPeople': new FormControl(true,Validators.requiredTrue),
    'twpfaType': new FormControl(true, Validators.requiredTrue),
    'p2pPaymentMethod': new FormControl('',),
    'equation': new FormControl('',),
    'advCurrency': new FormControl('',),
    'addCoins' : new FormControl(''),
    'totalAmount' : new FormControl('',Validators.required)
  })
  userLoginStatus: boolean = false;
  countryList: any = [];
  profileData: any;
  isAdvertisementFormSubmitted: boolean = false;
  

  constructor(public router: Router, public server: ServiceService, public appC: AppComponent) {
    // this.addForm.patchValue({
    //   'coin': ''
    // })

    // check user login
    if (!localStorage.getItem('credential')) {
      this.userLoginStatus = false
      this.addForm.disable()
    } else {
      this.userLoginStatus = true
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    // this.getListOfCoin();
    this.getAllCoins();
    this.switchTab('buy');
    this.getCountryList();
    this.getProfile();
    
  }
  bankDetails(showSection) {
    this.showDetail = showSection;
    this.addForm.patchValue({
      orderType : showSection
    })
  }
  usserWallet : any
 
  getAllCoins() {
  

    // this.service.showSpinner();
    this.server.getCandyPixelForms("wallet/wallet/get-all-user-balance-and-coinlist")
      .subscribe(
        (res: any) => {
        
          if (res["status"] == 200) {
            
            this.usserWallet = res.data.userBalance
         
           
          }
        },
        (err) => {
          this.server.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
            this.server.showErrorMessage("Session Expired!");
          } else {
            this.server.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }
  user_balance : any
  available_balace : any
  getCoinChange(){
    console.log( this.addForm.value.addCoins);
    
    let temp = this.usserWallet.filter((ele)=>{
      console.log(ele.instrument);
      
      return this.addForm.value.addCoins == ele.instrument
    })
    console.log(temp);
    this.user_balance = temp[0].totalBalance
    
    this.available_balace= temp[0].totalBalance
    
  }
  getMAXAmount(){
    this.addForm.patchValue({
      totalAmount : this.user_balance
    })
  }
  // switch between tabs
  switchTab(tab) {
    this.activeTab = tab
    switch (tab) {
      case 'buy':
        this.sellOrderList();
        break;
      case 'sell':
        this.buyOrderList();
        break;
      case 'advertisement':
        break;
    }
  }

 reset(){
    this.sellOrderList();
    this.buyOrderList();
 }
  /**
   * Buy and Sell Section 
   */
  search() {
    if (this.searchForm.value.currency || this.searchForm.value.country || this.searchForm.value.paymentType) {
      this.searchOrderList()
    } else {
      this.server.showErrorMessage('Please select field for search.')
    }
  }
  searchOrderList() {
    this.searchStatus = true
    if (this.activeTab == 'buy') {
    
      // this.buyOrderArr = [
      //   {userName : 'John Walker M.',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 488, maxValue : 5088 }
      // ]
      // let url = `p2p-exchange/search-and-filters-advertisement?orderType=SELL${(this.searchForm.value.currency ? '&currency=' + this.searchForm.value.currency : '') + (this.searchForm.value.country ? '&country=' + this.searchForm.value.country : '') + (this.searchForm.value.paymentType ? '&paymentType=' + this.searchForm.value.paymentType : '')}&page=${(this.pageNumberSell - 1)}&pageSize=${this.pageSize}`
      // this.server.getCandyPixelForms(url).subscribe((res) => {
      //   if (res['status'] == 200) {
      //     this.buyOrderArr = res['data'].list
      //     this.buyTotal = res['data'].totalCount
      //     this.searchFormReset()
      //   } else {
      //     this.buyOrderArr = ''
      //     this.buyTotal = ''
      //   }
      // })
    }
    else {
      this.sellOrderArr = []
      let url = `p2p-exchange/search-and-filters-advertisement?orderType=BUY${(this.searchForm.value.currency ? '&currency=' + this.searchForm.value.currency : '') + (this.searchForm.value.country ? '&country=' + this.searchForm.value.country : '') + (this.searchForm.value.paymentType ? '&paymentType=' + this.searchForm.value.paymentType : '')}&page=${(this.pageNumberSell - 1)}&pageSize=${this.pageSize}`
      this.server.getCandyPixelForms(url).subscribe((res) => {
        if (res['status'] == 200) {
          this.sellOrderArr = res['data'].list;
          this.sellTotal = res['data'].totalCount
          this.searchFormReset()
        } else {
          this.sellOrderArr = ''
          this.sellTotal = ''
        }
      })
    }
  }

  searchFormReset() {
    this.searchForm.reset({
      'currency': '',
      'country': '',
      'paymentType': ''
    })
  }

  // to get advertisement list buy 
  buyOrderList() {
    // this.sellOrderArr = [
    //   {userName : 'Mariya Smith',paymentType : 'FIAT', price : 2322,fiatCoin : 'BTC',minValue : 212, maxValue : 5666 },
    //   {userName : 'Mariya Smith',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 266, maxValue : 5454 },
    //   {userName : 'Mariya Smith',paymentType : 'FIAT', price : 8898,fiatCoin : 'BTC',minValue : 565, maxValue : 5441 },
    //   {userName : 'Mariya Smith',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 233, maxValue : 6546 },
    //   {userName : 'Mariya Smith',paymentType : 'FIAT', price : 5655,fiatCoin : 'BTC',minValue : 212, maxValue : 6565 }
    // ]
    // return
    this.searchStatus = false
    this.sellOrderArr = []
    
    if (this.userLoginStatus == true) {
      // if user login 
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list?orderType=BUY&page=${this.pageNumberBuy - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.sellOrderArr = res['data'].RESULT_LIST;
          this.sellTotal = res['data'].TOTAL_COUNT
        }
      })
    } else {
      // if user not login 
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list-globally?orderType=BUY&page=${this.pageNumberBuy - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.sellOrderArr = res['data'].RESULT_LIST;
          this.sellTotal = res['data'].TOTAL_COUNT
        }
      })
    }
  }
  //p2p-exchange/search-and-filters-advertisement?orderType=SELL&currency=USD&page=0&pageSize=5
  //p2p-exchange/get-advertisement-list?orderType=SELL&page=0&pageSize=5
  // to get advertisement list sell 
  sellOrderList() {
    this.searchStatus = false
    this.buyOrderArr = []
    // this.buyOrderArr = [
    //   {userName : 'John Walker M.',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 488, maxValue : 5088 },
    //   {userName : 'John Walker M.',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 488, maxValue : 5088 },
    //   {userName : 'John Walker M.',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 488, maxValue : 5088 },
    //   {userName : 'John Walker M.',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 488, maxValue : 5088 },
    //   {userName : 'John Walker M.',paymentType : 'FIAT', price : 3048,fiatCoin : 'BTC',minValue : 488, maxValue : 5088 }

    // ]
    // return
    if (this.userLoginStatus == true) {
      // if user login 
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.buyOrderArr = res['data'].RESULT_LIST;
          this.buyTotal = res['data'].TOTAL_COUNT
        }
      })
    } else {
      // if user not login 
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list-globally?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.buyOrderArr = res['data'].RESULT_LIST;
          this.buyTotal = res['data'].TOTAL_COUNT
        }
      })
    }
  }

  // for pagination
  sellPaginationEvent(page) {
    this.pageNumberBuy = page;
    if (this.searchStatus == true) {
      this.searchOrderList();
    } else {
      this.buyOrderList();
    }
  }

  buyPaginationEvent(page) {
    this.pageNumberSell = page;
    if (this.searchStatus == true) {
      this.searchOrderList();
    } else {
      this.sellOrderList();
    }
  }

  // to navigate
  navigateTo(path, data, type) {
    localStorage.setItem('chat-type', 'p-p')
    let val = data.fkUserId + '-' + data.peerToPeerExchangeId + '-' + type
    this.router.navigate([path, val])
  }



  /**
   * Create Advertisement Section
   */
  // to get coin list
  // getListOfCoin() {
  //   this.server.showSpinner();
  //   this.server.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe(res => {
  //     this.server.hideSpinner();
  //     if (res['status'] == 200) {
  //       this.coinList = res['data'].coinList;
  //       this.userWalletBalance = res['data'].walletBalance
  //     }
  //   })
  // }

  // change coin
  changeCoin(event) {
    this.coin = event.target.value
    this.server.GetMarketData(this.coin).subscribe(response => {
      this.marketPrice = Object.values(response)
      // if (response.Data) {
      //   response.Data.length ? this.newsData = response.Data : this.newsData = [];
      // }
    }, error => {
    })

  }

  // to get market price
  // getMarketPrice(coin) {
  //   this.server.showSpinner();
  //   this.server.getCandyPixelForms(`wallet/coin/get-market-price?coinName=${coin}`).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       this.currentPrice = res['data'];
  //       this.marketPrice = Number(res['data']);
  //     }
  //   })
  // }

  // to calculate margin
  calcMargin(val) {
    let myval = Number(val)
    this.currentPrice = (((myval / 100) * this.marketPrice[0]) + this.marketPrice[0]).toFixed(2)
    this.addForm.patchValue({
      'equation': this.currentPrice
    })
  }

  // to create advertise
  createAdvertise() {
    this.isAdvertisementFormSubmitted = true
    // if (this.addForm.invalid) {
    //   return;
    // }
    let data = {
      // "cryptoCoin": this.coin,
      // "cryptoCoin": "BTC",
      "cryptoCoin": this.addForm.value.addCoins,
      // "fiatCoin": "USD",
      "fiatCoin": this.coin,
      "margin": Number(this.addForm.value.margin),
      "maxValue": Number(this.addForm.value.maxValue),
      "minValue": Number(this.addForm.value.minValue),
      "orderStatus": "ENABLED",
      "orderType": this.showDetail,
      "paymentType": this.addForm.value.p2pPaymentMethod,
      "termsOfTrade": this.addForm.value.termsOfTrade,
      'username': this.appC.userName,
      'paymentWindow': Number(this.addForm.value.paymentWindow),
      //'addTags': this.addForm.value.addTags,
      'country': this.addForm.value.country,
      'isIdentifiedPeople': this.addForm.value.isIdentifiedPeople,
      "restrictAmount": 0,
      "role": "USER",
      "roleId": this.profileData.userId,
      'twpfaType': 'SMS',
      'price': this.currentPrice,
      'totalAmount' : this.addForm.value.totalAmount
    }
    this.server.showSpinner()
    this.server.postApi('p2p-exchange/add-advertisment', data).subscribe((res) => {
      this.server.hideSpinner()
      if (res['status'] == 200) {
        this.server.showSuccessMessage('Advertisement placed successfully!')
        // this.addForm.reset();
        this.addFormReset();
        // this.switchTab('buy');
        //this.activeTab = 'buySell'
        this.router.navigateByUrl('/dashboard-trades')
        this.sellOrderList();
        this.buyOrderList()
      } else {
        this.server.hideSpinner()
        this.server.showErrorMessage(res.message)
      }
    }, err => {
      this.server.hideSpinner()
      this.server.showErrorMessage('Something Went Wrong')
    })
  }

  // reset add advertisement form
  addFormReset() {
    this.addForm.reset({
      'country': '',
      
    })
  }

  // get list of country
  getCountryList() {
    this.server.getCandyPixelForms('account/get-country-list').subscribe((res: any) => {
      
      this.countryList = res.data;
     
    })
  }

  // get profile
  getProfile() {
    this.profileData = JSON.parse(localStorage.getItem('profiledata'));
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

}
