import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-trade',
  templateUrl: './cancel-trade.component.html',
  styleUrls: ['./cancel-trade.component.css']
})
export class CancelTradeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)
  }

}
