import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-career-jobs-search',
  templateUrl: './career-jobs-search.component.html',
  styleUrls: ['./career-jobs-search.component.css']
})
export class CareerJobsSearchComponent implements OnInit {
  totalItems:any
  subCategoryArray:any = [];
  categoryListArray:any  = [];

  constructor(public commonService:ServiceService,public router:Router) {
    window.scrollTo(0,0)
   }

  ngOnInit() {
    this.getEmbellishmentList();
    this.getCategoryList()
  }


   // -------------------- get category list --------------------- //
   getEmbellishmentList() {
    let apiReqUrl: any = `static/get-all-sub-category`

    this.commonService.showSpinner();

    // remove empty keys from request body
    // apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.status == 200) {
        this.subCategoryArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
        // this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.subCategoryArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.showErrorMessage(res.message)
      }
    },(err)=>{
      console.log(err);
      
      if(err.status == 404){
        this.subCategoryArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }

   // -------------------- get category list --------------------- //
   getCategoryList() {
    let apiReqUrl: any = `static/get-all-career`

    this.commonService.showSpinner();

    // remove empty keys from request body
    // apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.status == 200) {
        this.categoryListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
        // this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.showErrorMessage(res.message)
      }
    },(err)=>{
      console.log(err);
      
      if(err.status == 404){
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }

  viewBody(subTeamId) {
    // alert(subTeamId)
    this.router.navigate(['/career-job-details'], { queryParams: { subTeamId: subTeamId } })
  }
}
