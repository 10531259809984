import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-create-advertisement',
  templateUrl: './create-advertisement.component.html',
  styleUrls: ['./create-advertisement.component.css']
})
export class CreateAdvertisementComponent implements OnInit {

  currentPrice: any = 0;
  marketPrice: any = 0;
  coin: any = 'Coin';
  addForm = new FormGroup({
    'coin': new FormControl('', Validators.required),
    'minTransac': new FormControl('', Validators.required),
    'maxTransac': new FormControl('', Validators.required),
    'margin': new FormControl('', Validators.required),
    'term': new FormControl('', Validators.required),
    'orderType': new FormControl('', Validators.required),
    'payWindow': new FormControl('', Validators.required)
  })
  constructor(private server: ServiceService, private router: Router, public appC: AppComponent) {
    this.addForm.patchValue({
      'coin': ''
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0)
  }
  // change coin
  changeCoin(coin) {
    this.coin = coin
    this.getMarketPrice(coin)
  }
  // to get market price
  getMarketPrice(coin) {
    this.server.showSpinner();
    this.server.getCandyPixelForms(`wallet/coin/get-market-price?coinName=${coin}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.server.hideSpinner();
        this.currentPrice = res['data'];
        this.marketPrice = Number(res['data']);
      }
      else{
        this.server.hideSpinner();
        this.server.showErrorMessage(res['message'])
      }
    })
  }
  // to calculate margin
  calcMargin(val) {
    let myval = Number(val)
    this.currentPrice = (((myval / 100) * this.marketPrice) + this.marketPrice).toFixed(2)
  }


  // to create advertise
  createAdvertise() {
    if (!this.addForm.valid) {
      this.server.showErrorMessage('Form not valid')
      return;
    }

    let data = {
      "cryptoCoin": this.coin,
      "fiatCoin": "USD",
      "margin": Number(this.addForm.value.margin),
      "maxValue": Number(this.addForm.value.maxTransac),
      "minValue": Number(this.addForm.value.minTransac),
      "orderStatus": "ENABLE",
      "orderType": this.addForm.value.orderType,
      // "paymentType": "paypal",
      "termsOfTrade": this.addForm.value.term,
      'username': this.appC.userName,
      'paymentWindow': Number(this.addForm.value.payWindow)
    }
this.server.showSpinner()
    this.server.postApi('p2p-exchange/add-advertisment', data).subscribe((res) => {
      if (res['status'] == 200) {
        this.server.hideSpinner()
        this.server.showSuccessMessage('Advertisement added!')
        this.addForm.reset();
        // this.switchTab('advertisement')
      }
      else{
        this.server.hideSpinner();
        this.server.showErrorMessage(res['message'])
      }
    })
  }









  quickbuy() {
    this.router.navigate(['/p2p-exchange'])
  }
  quicksell() {
    this.router.navigate(['/quick-sell'])
  }

}
