import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showDetail: any = "BANK";
  products: any = [];
  countryCode: any = [];
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  obj: any = {};
  toastrService: any;
  showEye: boolean = false;
  siteKey: string;
  recaptcha: any = "";
  remember: boolean = false;
  ipAddress: string;
  browser: string;
  currentCountryCode: any = "+91";
  copyToClipboard(item) {
    this.service.showSuccessMessage("Secret key copied");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  constructor(
    public router: Router,
    public service: ServiceService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private httpClient: HttpClient
  ) {
    // this.siteKey = '6LcfxWsbAAAAAJrnlZ9NzJq52WLoKHqWZfzNNyS3'; // created for domain
    // this.siteKey = '6LdlPtAcAAAAANVTTWtLqeIXNmZTzzRQIALJR9jI'; // created for domain
    this.siteKey = "6LciYhwcAAAAAMpAXkcqzippcN1jcv_1_RtnVaZs";
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidation();
    this.getIP();
    this.myFunction();
    this.countryCode = this.service.countryListJson;

    this.getStateList();

    this.httpClient.get("assets/contry.json").subscribe((data) => {
      console.log(data);

      this.products = data;
    });
    this.loginForm.controls["countryCode"].setValue(this.currentCountryCode);
    // $('select option[value="IN"]').attr("selected",true);
  }

  // Get StateList Functionality
  getStateList() {
    // var stateList = this.countryList.filter(x => (x.country == event.target.value));
    // this.stateList = stateList[0].states;
    // this.countryCode = stateList[0].code;
    // this.countryCode = this.countryCode.code
    this.countryCode.code;
    // console.log(this.countryCode);
  }

  bankDetails(showSection) {
    this.showDetail = showSection;
  }
  // google recaptcha
  resolved(e) {
    if (e) this.recaptcha = e;
    else {
      this.recaptcha = "";
    }
  }

  /** to show and hide password */
  showHidePassword() {
    if (this.showEye == false) {
      this.showEye = true;
    } else if (this.showEye == true) {
      this.showEye = false;
    }
  }

  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      if (true) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.obj.myfile = event.target.files[0];
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  // Form Validation
  formValidation() {
    this.loginForm = this.fb.group({
      // 'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      email: new FormControl(""),

      password: new FormControl("", [Validators.required]),
      remember: [true],
      countryCode: new FormControl(""),
      mobile: new FormControl("", [Validators.pattern(/^[^0][0-9]*$/)]),
      // 'recaptcha' : new FormControl('')
    });
    if (JSON.parse(localStorage.getItem("remembers")) == true) {
      this.loginForm.patchValue({
        email: window.atob(localStorage.getItem("email")),
        password: window.atob(localStorage.getItem("password")),
      });
    } else {
      // localStorage.clear();
      this.loginForm.reset();
    }
  }
  // Login Functionality
  loginFunc() {
    if (localStorage != null) {
      localStorage.setItem("email", window.btoa(this.loginForm.value.email));
      localStorage.setItem(
        "password",
        window.btoa(this.loginForm.value.password)
      );
    } else {
      this.loginForm.reset();
    }
    localStorage.setItem(
      "remembers",
      JSON.stringify(this.loginForm.value.remember)
    );
    this.service.showSpinner();
    var apireq = {
      // 'email': this.loginForm.value.email,
      email:
        this.showDetail == "BANK"
          ? this.loginForm.value.email
          : `${this.loginForm.value.countryCode}` + this.loginForm.value.mobile,
      password: this.loginForm.value.password,
      userAgent: this.browser,
      location: "",
      ipAddress: this.ipAddress,
    };
    this.service.postCandyPixelForms("auth", apireq).subscribe(
      (res: any) => {
        console.log(res);

        this.service.hideSpinner();
        if (res["status"] == 200) {
          // this.toaster.success("Login Successfully ")

  this.twoFAStatus = res["data"]["TwoFa"];
  if (res["data"]["TwoFa"] == "NONE") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#suggest").modal({ backdrop: "static" });
  } else if (res["data"]["TwoFa"] == "GOOGLE") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#googleAuth").modal({ backdrop: "static" });
  } else if (res["data"]["TwoFa"] == "SMS") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#smsAuth").modal({ backdrop: "static" });
    this.suggesstionFunc("sms");
  } else if (res["data"]["TwoFa"] == "EMAIL") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#emailAuth").modal({ backdrop: "static" });
    this.suggesstionFunc("email");
  } else if (res["data"]["TwoFa"] == "SKIP") {
    this.service.changeLoginSub("login");
    localStorage.setItem("credential", res["data"]["token"]);
    this.toaster.success("Login Successfully");

    this.router.navigate([""]);
  }
  this.service.initSocketNotification();
  this.service.initSocketChat(); //chat

         
        } 
        else if (res.status == 203) {
          this.service.showErrorMessage(res.message);
          this.service.hideSpinner();
        }
        // else if(res.status == 555){
        //   // this.service.showErrorMessage(res.message)
        //   this.service.showErrorMessage('gfgdshjghdfhj')

        //   this.service.hideSpinner();
        // }
      },
      (err: any) => {
        console.log(err);

        this.service.hideSpinner();
        if (err["error"]["status"] == 203) {
          // this.service.showErrorMessage('Please Enter Correct Credentials');

          this.toaster.error(err["error"]["message"]);
          // this.resSendEmail()
        } else if (err["error"]["status"] == 555) {
          this.toaster.error(
            "It looks like your account has been deactiavted. Please contact support to activate your account."
          );
        } else if (err["status"] == 401) {
          this.toaster.error("Please Enter Correct Credentials");
        }
        //   //   else {
        //   //     this.toaster.error("Please Enter Correct Credentials");
        //   //   }
        //   // this.service.hideSpinner();
        // else if (err.status == '203') {
        //     // this.service.showErrorMessage(err['error']['message']);
        //     this.service.showErrorMessage('It looks like your account has been deactiavte Please contact your admin to activate it.');

        //     // this.resSendEmail()
        //   }
        //   else if (err.error['status'] == 401) {
        //     // alert(5)

        //     this.service.showErrorMessage('Please Enter Correct Credentials');
        //   }

        //   else {
        //     this.service.showErrorMessage("Please Enter Correct Credentials");
        //   }
      }
    );
    this.service.hideSpinner();
  }

  // Resend Email For Verification
  resSendEmail() {
    var url =
      "account/resend-verify-email?email=" +
      this.loginForm.value.email +
      "&webUrl=" +
      this.service.webUrl;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    if (action == "Skip") {
      this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.toaster.success("Login Successfully");
            $("#suggest").modal("hide");
            this.service.changeLoginSub("login");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);
            this.router.navigate(["/my-profile"]);
          } else {
            this.toaster.error(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      if (this.twoFAStatus == "GOOGLE") {
        $("#suggest").modal("hide");
        $("#googleAuth").modal("show");
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms("account/google-auth").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.qrCode = res["data"]["qrCode"];
              this.secretKey = res["data"]["secretKey"];
              $("#suggest").modal("hide");
              $("#googleAuth").modal("show");
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
      }
    } else if (action == "sms") {
      if (this.twoFAStatus == "SMS") {
        this.service.showSpinner();
        this.service.getCandyPixelForms("auth/send-sms-code").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#smsAuth").modal("show");
              this.toaster.success(res["message"]);
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Mobile number is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms("account/send-sms-code").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#smsAuth").modal("show");
              this.toaster.success(res["message"]);
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Mobile number is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      }
    }
    // email send code 2fa
    // else if (action == 'email') {
    //   if (this.twoFAStatus == 'EMAIL') {
    //     $('#suggest').modal('hide');
    //     $('#emailAuth').modal('show');
    //   } else {
    //     var url = "account/forget-password-mobile-app?email="+this.loginForm.value.email
    //     this.service.showSpinner();
    //     this.service.getCandyPixelForms(url).subscribe(res => {
    //       this.service.hideSpinner();
    //       if (res['status'] == 200) {
    //         // this.qrCode = res['data']['qrCode'];
    //         // this.secretKey = res['data']['secretKey'];
    //         $('#suggest').modal('hide');
    //         $('#emailAuth').modal('show');
    //       }
    //     }, err => {
    //       this.service.hideSpinner();
    //     })
    //   }
    // }
    // end email 2fa
    else if (action == "email") {
      let url =
        "account/forget-password-mobile-app?email=" +
        this.loginForm.value.email;
      if (this.twoFAStatus == "EMAIL") {
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.toaster.success(
                "An OTP has been sent on your entered email ID"
              );
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Email is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.toaster.success(res["message"]);
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Email  is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      }
    }
  }
  // Verify Google / SMS Auth Functionality By Accounts
  verifyGoogleAuth(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "auth/verify-google";
        apireq = {
          otp: this.googleAuth,
          //"secretKey": this.secretKey
        };
      } else {
        url = "account/verify-google-code";
        apireq = {
          code: Number(this.googleAuth),
          secretKey: this.secretKey,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              this.service.changeLoginSub("login");
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            this.toaster.success("Google Authentication Verified");
            $("#googleAuth").modal("hide");
            this.router.navigate([""]);
          } else {
            this.toaster.error("Wrong Google Authentication Code");
            this.googleAuth = "";
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.toaster.error("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
          // 'username' : this.loginForm.value.email,
          // 'ipAddress': this.ipAddress,
          // 'source': 'WEB'
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            this.service.changeLoginSub("login");
            //localStorage.removeItem('authToken')
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            this.toaster.success(res["message"]);
            this.router.navigate([""]);
          } else {
            this.toaster.error(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.toaster.error("Something Went Wrong");
        }
      );
    } else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        // url = 'account/verify-Email-Sms-code';
        url = `auth/verify-Email-code`;
        emailapireq = {
          otp: this.emailAuth,
        };
      } else {
        url = "auth/verify-Email-code";
        emailapireq = {
          otp: this.emailAuth,
          // 'emailOtp':this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            this.service.changeLoginSub("login");
            //localStorage.removeItem('authToken')
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            this.toaster.success(res["message"]);
            this.router.navigate([""]);
          } else {
            this.toaster.error(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.toaster.error("Something Went Wrong");
        }
      );
    }
  }

  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  forgotpassword() {
    this.router.navigateByUrl("forgotpassword");
  }
  signup() {
    this.router.navigateByUrl("register");
  }

  // get IP Address
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      // this.getIPLocation()
    });
  }

  getIPLocation() {
    var url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=5cb98274eb3d46eaa0359cf9cbbedf77&ip=" +
      this.ipAddress;
    this.service.getCandyPixelForms(url).subscribe((res) => {});
  }

  myFunction() {
    console.log(navigator.userAgent.indexOf("Safari"));
    console.log(navigator.userAgent.indexOf("Safari"));

    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      this.browser = "Opera";
      //alert('Opera');
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      this.browser = "Chrome";
      //alert('Chrome');
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      this.browser = "Safari";
      //alert('Safari');
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      this.browser = "Firefox";
      //alert('Firefox');
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
      //IF IE > 10
      this.browser = "IE";
      //alert('IE');
    } else {
      this.browser = "Unknown";
      //alert('unknown');
    }
  }
}
