import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-p2p-buy-coin',
  templateUrl: './p2p-buy-coin.component.html',
  styleUrls: ['./p2p-buy-coin.component.css']
})
export class P2pBuyCoinComponent implements OnInit {
  userData: any = {};
  id: any;
  disableButton: boolean = false;
  sale_type: string;
  p2pId: string;
  tradeId: any;
  toUserId: any;
  userLoginStatus: boolean = false;
  profileData: any;
  transactionForm = new FormGroup({
    'usd_amount': new FormControl(null, [Validators.required]),
    'coin_amount': new FormControl(null, [Validators.required]),
    'traderMessage':new FormControl('')
  })

  constructor(private server: ServiceService, public router: Router, public appC: AppComponent) { }

  ngOnInit() {
    this.callByUrl()
    // check user login
    if (localStorage.getItem('credential')) {
      this.userLoginStatus = true
      this.getProfile()
    } else {
      this.userLoginStatus = false
    }
    // this.server.initSocketChat(); //chat

  }

  // to call by url
  callByUrl() {
    let url = window.location.href
    let arr = url.split('/')
    let new_arr = arr[arr.length - 1].split('-')
    this.sale_type = new_arr[new_arr.length - 1]
    this.p2pId = new_arr[new_arr.length - 2]
    this.toUserId = new_arr[new_arr.length - 3]
    this.getDetails(this.p2pId, this.sale_type);
  }

  // to get details
  getDetails(id, type) {
    this.id = id
    let url;
    if (type == 'buy') {
      url = `p2p-exchange/get-details-after-press-buy-button?peerToPeerExchangeId=${id}`
    } else {
      url = `p2p-exchange/get-details-after-press-sell-button?peerToPeerExchangeId=${id}`
    }
    this.server.showSpinner();
    this.server.getCandyPixelForms(url).subscribe((res) => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        this.userData = res['data'];
        // this.checkStatus();
      }
    }, err => {
      this.server.hideSpinner();
    })
  }

  // to send trade request
  sendTradeReq() {
    if (navigator.onLine) {
      this.server.showSpinner();
      this.server.postApi(`p2p-exchange/send-trade-request?amountInRange=${this.transactionForm.value.usd_amount}&userId=${this.profileData.userId}&peerToPeerExchangeId=${Number(this.id)}`, {}).subscribe((res) => {
        this.server.hideSpinner();
        if (res['status'] == 200 || res['status'] == 201) {
          // this.server.initSocketChat(); //chat
          if (res['status'] == 200) {
            this.server.showSuccessMessage('Trade request sent successfully')
            // let my_data = this.toUserId + '-' + res.data['tradeId'] + '-' + this.userData.userName + '-' + this.sale_type + '-' + this.userData.paymentWindow + '-' + this.p2pId;
            this.server.showSuccessMessage(res.message)

            let my_data = {
              // "fromUserId": 26,
              // "isSeen": false,
              "noOfCoins": res.data['noOfCoins'],
              // "notificationStatus": "Notification Send For Trading Request",
              // "orderType": this.sale_type,
              "orderType": res.data['orderType'],
              "paymentWindow": this.userData.paymentWindow,
              // "peerToPeerExchangeId": 12,
              // "timeStamp": "Sep 16, 2020 8:21:19 PM",
              "toUserId": Number(this.toUserId),
              "tradeId": res.data['tradeId'],
              "tradePartner": this.userData.userName,
              // "tradeStatus": "PENDING",
              // "tradingPrice": 300,
              // "userName": this.userData.userName,
              'peerToPeerExchangeId': Number(this.p2pId),
              'route': 'p2p-buy',
              'fiatCoin': res.data['fiatCoin'],
              'cryptoCoin': res.data['cryptoCoin'],
              'price': res.data['price']
            }
            let data = {
              "toUserId": Number(this.toUserId),
              // "userId": localStorage.getItem('userId'),
              "userId": this.profileData.userId,
              // "messageType": "TEXT",
              "messageFormat": "TEXT",
              "message": `${this.sale_type} trade request from ${this.appC.userName}`,
              "tradeId": res.data['tradeId']
              // "userName": this.appC.userName,
              // "orderType": this.sale_type,
              // "minutes": Number(this.userData.paymentWindow),
              // 'peerToPeerExchangeId': Number(this.p2pId),
            }
            setTimeout(() => {
              this.server.wsChat.send(JSON.stringify(data)) // chat screen as contact-exchage
              // this.router.navigate(['chat', my_data])
              // this.router.navigate(['/contactExchange', my_data])
              let exchangeDetails = JSON.stringify(my_data)
              let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
              this.router.navigate(['contactExchange'], navigationExtras)
            }, 500)
          } else {
            this.server.showErrorMessage(res.message)
          }
        } else {
          this.server.showErrorMessage(res.message)
        }
      }, err => {
        this.server.hideSpinner();
      })
    }
  }

  // to convert into USD amount
  calcUsdAmount(val) {
    this.disableButton = false;
    this.transactionForm.patchValue({
      'usd_amount': (Number(val) * Number(this.userData.price)).toFixed(2)
    })
    if (this.transactionForm.value.usd_amount < this.userData.minValue) {
      this.server.showSuccessMessage(`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`)
      this.disableButton = true
    } else if (this.transactionForm.value.usd_amount > this.userData.maxValue) {
      this.server.showSuccessMessage(`The largest amount you can ${this.sale_type} from this ad is ${this.userData.maxValue} ${this.userData.valueInCoinType}`)
      this.disableButton = true
    }
  }

  // to convert into coin amount
  calcCoinAmount(val) {
    this.disableButton = false;
    this.transactionForm.patchValue({
      'coin_amount': (Number(val) / Number(this.userData.price)).toFixed(8)
    })
    if (val < this.userData.minValue) {
      this.server.showSuccessMessage(`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`)
      this.disableButton = true
    } else if (val > this.userData.maxValue) {
      this.server.showSuccessMessage(`The largest amount you can ${this.sale_type} from this ad is ${this.userData.maxValue} ${this.userData.valueInCoinType}`)
      this.disableButton = true
    }
  }

  // navigate to register if user not logged in
  register() {
    this.router.navigate(['/login'])
  }

  // get user profile
  getProfile() {
    this.profileData = JSON.parse(localStorage.getItem('profiledata'));
  }

  seeFeedback() {
    this.router.navigate(['/feedback', this.toUserId, this.userData.userName])
  }

  seeProfile() {
    this.router.navigate(['/profile', this.toUserId])
  }

  message() {
    this.server.showErrorMessage('Please login/register to see details.')
  }
}
