import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ClipboardService } from "ngx-clipboard";
@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.css"],
})
export class MyProfileComponent implements OnInit {
  profileForm: FormGroup;
  profileData: any = {};
  arr: any = [];
  KycForm: FormGroup;
  docArr: any = [];
  imageUrl: any = "assets/images/user_un.png";
  fileData: any;
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  fileName1: any = "Upload Front Page Of Your Passport or National Id";
  fileName2: any = "Upload Back Page Of Your Passport or National Id";
  imageUrl1: any = "assets/images/image_placeholder.jpeg";

  constructor(
    public router: Router,
    public service: ServiceService,
    private _clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidation();
    this.getProfile();
    this.countryList = this.service.countryListJson;
  }

  // Form Validation Functionality
  formValidation() {
    this.profileForm = new FormGroup({
      firstName: new FormControl("", [
        Validators.pattern(/^[a-zA-Z]*$/i),
        Validators.required,
      ]),
      middleName: new FormControl("", [
        Validators.pattern(/^[a-zA-Z]*$/i),
       
      ]),
      lastName: new FormControl("", [
       
        Validators.pattern(/^[a-z A-Z]*$/i),
      ]),
      email: new FormControl(
        "",
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
        )
      ),
      mobile: new FormControl("", Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/)),
      gender: new FormControl("",Validators.required),
      selectCountry: new FormControl("", Validators.required),
      selectState: new FormControl("", Validators.required),
      selectCity: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
      zipCode: new FormControl("", [Validators.required]),
      uniqueId: new FormControl("", [Validators.required]),
      dob: new FormControl("", [Validators.required]),

    });
  }

  // Get Profile Name-Email
  getProfile() {
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.profileData = res["data"];
          let index = this.arr.findIndex(
            (x) => x.country == this.profileData.country
          );
          // this.stateList = this.arr[index].states;
          // this.mobileData = this.profileData.phoneNo.split('-');
          this.service.name = this.profileData.firstName;
          // this.getStateList(this.profileData.country);
             // if we get country then we will find states of that country
             this.profileData.country ? this.getStateList(this.profileData.country) : null || "string";
          setTimeout(() => {
            this.profileForm.patchValue({
              firstName: this.profileData.firstName,
              lastName: this.profileData.lastName,
              middleName : this.profileData.middleName,
              email: this.profileData.email,
              mobile: this.profileData.phoneNo,
              address: this.profileData.address,
              gender: this.profileData.gender ? this.profileData.gender : '',

              //"zipcode": this.profileData.zipCode,
              uniqueId: this.profileData.randomId,
              selectCountry: this.profileData.country ? this.profileData.country : '',
              selectState: this.profileData.state ? this.profileData.state : '',
              selectCity: this.profileData.city ? this.profileData.city :'',
              zipCode: this.profileData.zipCode ? this.profileData.zipCode : '',
              dob:this.profileData.dob ? this.profileData.dob : ''

            });
            this.service.hideSpinner();
          }, 1000);

          // if(this.profileData.country) {
          //   this.getStateList(this.profileData.country);
          // }
          if (this.profileData.imageUrl) {
            this.imageUrl =
              this.profileData.imageUrl != null
                ? this.profileData.imageUrl
                : "assets/images/mark-herrera.png";
            this.service.img = this.imageUrl;
          }
          //this.states =this.profileData.state
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        // this.service.showErrorMessage("Server error");
      }
    );
  }

  // Image Functionality Start Here
  uploadImg(event) {
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        this.fileData = event.target.files[0];
        this.uploadImageFunc();
        var reader = new FileReader();
        reader.onload = (e) => {};
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
        self.fileData = "";
      }
    }
  }

  uploadImageFunc() {
    let formdata = new FormData();
    formdata.append("file", this.fileData);
    this.service.showSpinner();
    this.service.postCandyPixelForms("account/upload-file", formdata).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.imageUrl = res["data"];
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  // Update Profile Data Functionality
  updateFunc() {
    var apireq = {
      address: this.profileForm.value.address,
      firstName: this.profileForm.value.firstName,
      middleName :  this.profileForm.value.middleName,
      imageUrl: this.imageUrl,
      lastName: this.profileForm.value.lastName,
      //"state": this.profileForm.value.state,
      gender: this.profileForm.value.gender,

      phoneNo: this.profileForm.value.mobile,
      zipCode: this.profileForm.value.zipCode,
      randomId: this.profileForm.value.uniqueId,
      country: this.profileForm.value.selectCountry,
      state: this.profileForm.value.selectState,
      city: this.profileForm.value.selectCity,
      dob: this.profileForm.value.dob
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms("account/profile-update", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          this.getProfile();
          this.service.showSuccessMessage("User details updated");
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  kyc() {
    this.router.navigate(["/kyc"]);
  }
  security() {
    this.router.navigate(["/security"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  addaccount() {
    this.router.navigate(["/add-account"]);
  }
  changepassword() {
    this.router.navigate(["/change-password"]);
  }
  googleauth() {
    this.router.navigate(["/google-auth"]);
  }
  verifyAccount() {
    this.router.navigate(["/kyc"]);
  }
  accountDetail(){
    this.router.navigate(['/account'])
  }
  nomineeDetail(){
    this.router.navigate(['/nominee'])
  }
  copy() {
    // this._clipboardService.copyFromContent(this.profileForm.value.uniqueId)
    // this.service.showSuccessMessage('Unique Id copied');
    this.service.showSuccessMessage("Unique Id copied");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", this.profileForm.value.uniqueId);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  // Get StateList Functionality
  getStateList(countryCode) {
    // var stateList = this.countryList.filter(x => (x.country == event.target.value));
    var stateList = this.countryList.filter((x) => x.country == countryCode);
    console.log(stateList);
    if(stateList.length){
      this.stateList = stateList[0].states ? stateList[0].states : '';
      this.countryCode = stateList[0].code ? stateList[0].code : '';
    }
   
  }

  bankList(){
  this.router.navigate(['/bank-list'])
  }
 
  deactivateAccount(){
    var apireq = {
  
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms("account/block-user", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          this.logoutFunc();
          this.service.showSuccessMessage("ACCOUNT DEACTIVATE SUCCESSFULLY");
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  
  }
  logoutFunc() {
    localStorage.removeItem('credential')
    localStorage.removeItem('profiledata')
    this.router.navigate(['/login'])
  }

   /** To upload document 1 -- Front*/
   handleFileInput1(event, index) {
    this.service.showSuccessMessage("Please wait! uploading is in progress...");
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        let fileData = event.target.files[0];
        if (index == "1") this.fileName1 = event.target.files[0].name;
        else if (index == "2") this.fileName2 = event.target.files[0].name;

        this.sendFormData1(fileData, index);

        var reader = new FileReader();
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }

  /** to call form data */
  sendFormData1(fileData, index) {
    let formdata = new FormData();
    formdata.append("file", fileData);
    this.service.postApi("account/upload-file", formdata).subscribe(
      (succ) => {
        if (succ.status == 200) {
          this.service.hideSpinner();
          this.service.showSuccessMessage("File Uploaded");
          let url = succ.data;
          if (index == "1") {
            this.imageUrl = succ.data;
          } else {
            this.imageUrl1 = succ.data;
          }
          var data = {};
          if (index == "1" || index == "2") {
            data = {
              name: this.KycForm.value.id_name,
              number: this.KycForm.value.id_number,
              url: url,
              documentNumber: index,
            };
          } else {
            data = {
              name: "Selfie with Id",
              number: "NA",
              url: url,
              documentNumber: index,
            };
          }
          this.docArr[Number(index)] = data;
        } else {
          this.service.showSuccessMessage(succ.message);
        }
      },
      (error) => {
        if (index == "1") {
          this.fileName1 = "Upload Front Page Of Your 1st ID";
        } else if (index == "2")
          this.fileName2 = "Upload Front Page Of Your 2nd ID";
        this.service.hideSpinner();
      }
    );
  }

  
}
