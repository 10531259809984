import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-nominee',
  templateUrl: './nominee.component.html',
  styleUrls: ['./nominee.component.css']
})
export class NomineeComponent implements OnInit {
  showDetail: any = "UPI";

  UserDetailsForm: any;
  UserDetailsForm1: any;
  profileData: any = [];
  arr: any = [];
  fileData: any;
  totalRecords: any;
  pageNumber: number = 1;
  itemsPerPage: number = 10;

  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  products: any =[]
  states: any;
  KycForm: FormGroup;
  nomineeForm:FormGroup
  updateNomineeForm:FormGroup
  fileName1: any = "Upload Front Page Of Your Passport or National Id";
  fileName2: any = "Upload Back Page Of Your Passport or National Id";
  docArr: any = [];
  kycStatus: boolean = false;
  resultArr: any = [];
  imageUrl: any = "assets/images/image_placeholder.jpeg";
  imageUrl1: any = "assets/images/image_placeholder.jpeg";
  stepStatus: any = 'FIRST';
  constructor(public router: Router, public service: ServiceService, private httpClient:HttpClient) {
    this.arr = this.service.countryListJson;
    this.arr.forEach((obj1) => {
      this.countryList.push(obj1.country);
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.validateNomineeForm();
    // this.updateNomineeValidationForm()
    this.getNominee();

    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
  }

resetForm(){
this.nomineeForm.value.firstName,
this.nomineeForm.value.lastName,
this.nomineeForm.value.email,
this.nomineeForm.value.relationship,
this.nomineeForm.value.relationshipOther,
this.nomineeForm.value.percentage,
this.nomineeForm.value.imageUrl,
this.nomineeForm.value.imageUrl1,
this.nomineeForm.value.mobile,
this.nomineeForm.value.countryCode,




  this.nomineeForm.reset({
    firstName : '',
    lastName: '',
    email:'',
    relationship : '',
    relationshipOther : '',
    percentage : '',
    imageUrl: '',
    imageUrl1:'',
    mobile: '',
    countryCode:''
  })
}



// form validate
validateNomineeForm(){
this.nomineeForm = new FormGroup({
  firstName : new FormControl("",[Validators.required,Validators.pattern(/^[a-zA-Z]*$/i)]),
  lastName : new FormControl("",[Validators.pattern(/^[a-zA-Z]*$/i)]),
  email : new FormControl(""),
  relationship : new FormControl("",Validators.required),
  imageUrl: new FormControl("", [Validators.required]),
  imageUrl1: new FormControl("",Validators.required),
  relationshipOther: new FormControl(''),
  percentage: new FormControl('',Validators.required),
  mobile: new FormControl("", [
    Validators.pattern(/^[^0][0-9]*$/),
    Validators.minLength(8),
    Validators.maxLength(15),
  ]),
  // countryCode: new FormControl("+91", ),
})
}
updateNomineeValidationForm(){
  this.updateNomineeForm = new FormGroup({
    firstName : new FormControl("",Validators.required),
    lastName : new FormControl("",Validators.required),
    email : new FormControl(""),
    relationship : new FormControl(""),
    imageUrl: new FormControl("", [Validators.required]),
    imageUrl1: new FormControl(""),
    relationshipOther: new FormControl(''),
    percentage: new FormControl(''),
    mobile: new FormControl("", [
      Validators.required,
      Validators.pattern(/^[^0][0-9]*$/),
      Validators.minLength(8),
      Validators.maxLength(15),
    ]),
    // countryCode: new FormControl("+91", ),
  })
}
bankDetails(showSection,id?) {
  this.showDetail = showSection;
  this.imageUrl = 'assets/images/image_placeholder.jpeg'
  this.imageUrl1 = 'assets/images/image_placeholder.jpeg'
  if(id){
    this.viewNominee(id)
  }
}
  profile() {
    this.router.navigate(['/my-profile'])
  }
  kyc() {
    this.router.navigate(["/kyc"]);
  }
  security() {
    this.router.navigate(["/security"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  addaccount() {
    this.router.navigate(["/add-account"]);
  }
  changepassword() {
    this.router.navigate(["/change-password"]);
  }
  googleauth() {
    this.router.navigate(["/google-auth"]);
  }
  verifyAccount() {
    this.router.navigate(["/kyc"]);
  }
  accountDetail(){
    this.router.navigate(['/account'])
  }
  nomineeDetail(){
    this.router.navigate(['/nominee'])
  }
  nextStep(stepStatus) {
    this.stepStatus = stepStatus;
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  
  }
// add nominee
// Update Profile Data Functionality
addNominee() {
  var apireq = {
    firstName: this.nomineeForm.value.firstName,
    lastName: this.nomineeForm.value.lastName,
    email :  this.nomineeForm.value.email,
    imageUrl: this.imageUrl,
    imageUrl1 : this.imageUrl1,
    relationShip: this.nomineeForm.value.relationship == "Other" ? this.nomineeForm.value.relationshipOther : this.nomineeForm.value.relationship ,
    sharePercentage: this.nomineeForm.value.percentage,  
    // nomineeStatus : "ACTIVE",
    phoneNo: this.nomineeForm.value.mobile
  };
  this.service.showSpinner();
  this.service
    .postCandyPixelForms("account/add-nominee", apireq)
    .subscribe(
      (res) => {
        

        if (res["status"] == 200) {
          // this.getProfile();
          this.service.showSuccessMessage(res['message']);
          this.showDetail = "UPI";
          this.getNominee();
          this.service.hideSpinner();
      
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err['message'])
      }
    );
}




// Get nominee Name-Email
getNominee() {
  this.service.showSpinner();
  // this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    (res) => {
      if (res["status"] == 200) {
        this.profileData = res["data"];
          this.service.hideSpinner();
    
      } else {
        this.service.hideSpinner();
        this.service.showErrorMessage('Nominee details does not exist.');
      }
    },
    (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage('Nominee details does not exist.');
    }
  );
}
profileDataView:any
// view nominee
viewNominee(nomineeId) {
  this.nomineeId = nomineeId
  let url = `account/get-details-by-nomineeId?nomineeId=${nomineeId}`
  this.service.showSpinner();
  // this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    this.service.getCandyPixelForms(url).subscribe(
    (res) => {
      if (res["status"] == 200) {
        this.profileDataView = res["data" ];
          this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res["message"]);
      }
    },
    (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message']);
    }
  );
}

// Update Profile Data Functionality
nomineeId :any
updateNominee() {
  let url = `account/nominee-details-update?nomineeId=${this.nomineeId}`
  var apireq = {
    firstName: this.updateNomineeForm.value.firstName,
    lastName: this.updateNomineeForm.value.lastName,
    email :  this.updateNomineeForm.value.email,
    imageUrl: this.imageUrl,
    imageUrl1 : this.imageUrl1,
    relationShip: this.updateNomineeForm.value.relationship == "Other" ? this.updateNomineeForm.value.relationshipOther : this.updateNomineeForm.value.relationship ,
    sharePercentage: this.updateNomineeForm.value.percentage,  
    "address": "string",
  "dob": "string",
  "nomineeId": this.nomineeId,
  phoneNo: this.nomineeForm.value.mobile

    // nomineeId: nomineeId,
  };

  this.service.showSpinner();
  this.service
    .postCandyPixelForms(url, apireq)
    // .subscribe(
    //   (res) => {
    //     this.service.hideSpinner();
    //     this.service.showSuccessMessage("User details updated");
    //     this.getNominee();
    //   },
    //   (err) => {
    //     this.service.hideSpinner();
    //   }
    // );
    .subscribe((res) => {
      if (res["status"] == 200) {
        this.service.hideSpinner();
        this.service.showSuccessMessage(res["message"]);
        // this.router.navigate(['/vendor-list'])

      } else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res["message"]);
      }
    },
    (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message']);
    });
}
code : any

deleteAddressModal(nomineeId) {
  $('#deleteModal').modal('show')
  this.nomineeId = nomineeId
}

  // Delete User
  deleteFunction() {
    let url = `account/delete-nominee-by-nomineeId?nomineeId=${this.nomineeId}`
    this.service.showSpinner();
    this.service.deleteApi(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#deleteModal').modal('hide')
        this.service.showSuccessMessage('Nominee Deleted Successfully');
        this.showDetail = "UPI";
        this.getNominee();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        // this.service.unauthorizedApi(this.code);
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

   // Image Functionality Start Here
   uploadImg(event,e) {
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        this.fileData = event.target.files[0];
        this.uploadImageFunc(e);
        var reader = new FileReader();
        reader.onload = (e) => {};
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
        self.fileData = "";
      }
    }
  }

  uploadImageFunc(e) {
    let formdata = new FormData();
    formdata.append("file", this.fileData);
    this.service.showSpinner();
    this.service.postCandyPixelForms("account/upload-file", formdata).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          if(e==1){

            this.imageUrl = res["data"];
          }
          else{

            this.imageUrl1 = res["data"]
          }
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }


}
