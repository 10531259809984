import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {
  newsLetterStaticId: any;
  viewNewsData:any
  constructor(private router: Router, public service: ServiceService, private activateRoute: ActivatedRoute) {
    window.scrollTo(0,0)
    this.activateRoute.queryParams.subscribe(res=>{
      this.newsLetterStaticId = res.id 
    })
    console.log(this.newsLetterStaticId);
   }

  ngOnInit() {
    this.viewNews();
  }


  viewNews(){ 
    let url = `static/get-static-news-letter-by-id?newsLetterId=${this.newsLetterStaticId}`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res=>{
           if (res['status']==200){
             
             this.viewNewsData=res['data'];
             this.service.hideSpinner()
            //  this.service.showSuccessMessage(res['message'])
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }
}
