import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { url } from 'inspector';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-deposit-history',
  templateUrl: './deposit-history.component.html',
  styleUrls: ['./deposit-history.component.css']
})
export class DepositHistoryComponent implements OnInit {
  coinList: any = [];
  showData: any = [];
  selectedUserCoin: any = {};
  depositeList: any = [];
  totalDeposite: any;
  depositePageNumber: number = 1;
  withdrawPageNumber: number = 1;
  pageNumber = 1
  pageSize = 10
  transferForm: FormGroup
  total: any;
  fiatCryptoData: any = "CRYPTO"
  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getListOfCoin();
    this.getPrice();
    this.getCoinList();
    this.checkTransferFormValidations()
    this.showList()
  }


  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'currency': new FormControl(''),
      'type': new FormControl(''),
      'searchTextTxnHash': new FormControl(''),
      'amount': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }
  // Get All The Coin Functionality
  getListOfCoin() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {


      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (let x = 0; x < coinList.length; x++) {

          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;


          }
          this.service.hideSpinner();
        }
        let removeCoin = ['BCH', 'OMG', 'XLM', 'DASH', 'LTC']
        this.coinList = coinList.filter((ele) => {
          return !removeCoin.includes(ele.coinShortName)
        })
        // this.coinList = coinList;

        this.selectedUserCoin = this.coinList[0].coinShortName
        this.getTrasactionList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  selectcoin(coin) {
    this.selectedUserCoin = coin
    this.getTrasactionList()
  }

  // Get Transaction List Functionality
  getTrasactionList() {

    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    let hash = this.transferForm.value.searchTextTxnHash
    this.depositeList = [];
    var url = `wallet/get-all-transaction-history-USER?page=${this.withdrawPageNumber - 1}&pageSize=10&txnType=DEPOSIT&coinName=${this.selectedUserCoin}${fromDate ? '&fromDate=' + fromDate : ''}${toDate ? '&toDate=' + toDate : ''}${hash ? '&txnHash=' + hash : ''}`

    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.depositeList = res['data']['resultlist'];
        this.total = res['data']['totalCount'];
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Kyc not done!');
      }
    })

  }
  reset() {
    this.transferForm.reset()
    this.getTrasactionList()
  }

  // getTrasactionList() {
  //   this.depositeList = [];
  //     if (this.selectedUserCoin == 'ETH' || this.selectedUserCoin == 'XRP' || this.selectedUserCoin == 'XLM'
  //     ||this.selectedUserCoin == 'OMG' || this.selectedUserCoin == 'USDT')
  //       var url = 'wallet/wallet-type2/get-deposits?coinName=' + this.selectedUserCoin + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
  //     else 
  //       var url = 'wallet/wallet/get-deposits?coinName=' + this.selectedUserCoin + '&page=' + (this.withdrawPageNumber -1) + '&pageSize=10';

  //     this.service.showSpinner();
  //     this.service.getCandyPixelForms(url).subscribe(res => {

  //       if (res['status'] == 200) {
  //         this.depositeList = res['data']['resultlist']
  //         this.totalDeposite = res['data']['totalCount'];

  //         if (this.selectedUserCoin == 'ETH') {
  //           this.etherSuccess()
  //         }
  //         this.service.hideSpinner();
  //       }
  //       else if(res['status'] == 205){
  //         this.service.hideSpinner();
  //         this.service.showErrorMessage(res['message'])
  //       }
  //       else {
  //         this.service.hideSpinner();
  //         this.service.showErrorMessage('Transaction Not Found');
  //       }
  //     }, err => {
  //       this.service.hideSpinner();
  //       if (err['status'] == '401') {
  //         localStorage.clear();
  //         this.router.navigate(['/login']);
  //         this.service.showErrorMessage('Session Expired!');
  //       } else {
  //         this.service.showErrorMessage('Something Went Wrong');
  //       }
  //     })    
  // }

  // Set Deposite / Withdraw Page Number
  getDepositePage(pageNumber) {
    this.depositePageNumber = pageNumber;
    this.getTrasactionList();
  }

  // Ether Success 
  etherSuccess() {
    this.service.getCandyPixelForms('wallet/wallet-type2/get-async-transfer?coinName=ETH').subscribe(res => {
    }, err => {

    })
  }

  getPrice() {
    var getLiquiditydata = [];
    this.service.getLiquidity().subscribe((getLiquidity) => {
      getLiquiditydata = getLiquidity['data']
      getLiquiditydata.forEach((element) => {
        var ind = this.coinList.findIndex((x) => x.coinShortName == element.symbol)
        if (ind != -1) {
          this.coinList[ind].marketPriceInUsd24HourBefore = element.quote.USD.percent_change_24h
          this.coinList[ind].marketPriceInUsdAWeekBefore = element.quote.USD.percent_change_7d
          this.coinList[ind].price = element.quote.USD.price.toFixed(4);
          this.coinList[ind].volume_24h = element.quote.USD.volume_24h
          this.coinList[ind].last_updated = element.last_updated
        }
        let removeCoin = ['BCH', 'OMG', 'XLM', 'DASH', 'LTC']
        this.coinList = this.coinList.filter((ele) => {
          return !removeCoin.includes(ele.coinShortName)
        })
      })
    })
  }

  getCoinList() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe((getLiquidity) => {
      if (getLiquidity['status'] == 200) {
        let index = getLiquidity['data'].findIndex(x => x.coinShortName == 'USD')
        if (index != 1) {
          getLiquidity['data'].splice(index, 1)
          this.coinList = getLiquidity['data']
          this.service.hideSpinner();
        }

        else {
          this.service.hideSpinner();
        }
      }
    })
  }

  exportAsEXCEL() {
    let dataArr = [];
    if (this.depositeList.length) {
      this.depositeList.forEach((element, ind) => {

        dataArr.push({
          "S No": ind + 1,
          "Date and Time": element.txnTime ? element.txnTime.slice(0, 10) : 'N/A',
          "Amount": element.amount ? element.amount : 'N/A',
          "Address": element.address ? element.address : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : 'N/A',
          // "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
          // "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'Deposit list history');
    }
  }

  exportAsEXCELFait() {
    let dataArr = [];
    if (this.showData.length) {
      this.showData.forEach((element) => {

        dataArr.push({
          "AMOUNT": element.amount ? element.amount : 'N/A',
          "FIAT ID": element.fiatId ? element.fiatId : 'N/A',
          "UTR NO.": element.utrNo ? element.utrNo : 'N/A',
          "STATUS": element.fiatStatus ? element.fiatStatus : 'N/A',
          "DATE": element.createTime ? element.createTime : 'N/A',
          // "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
          // "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'Deposit list history');
    }
  }

  copyText(text: string) {
    window.navigator.clipboard.writeText(text)
    this.service.showSuccessMessage('Copied to clipboard')
  }


  showList() {
    let url = `wallet/wallet/Deposi-Inr-List?page=${this.pageNumber -1}&pageSize=${this.pageSize}`
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe((getLiquidity) => {
      if (getLiquidity['status'] == 200) {
        this.showData = getLiquidity['data']['Data']
        this.service.hideSpinner();
      }

      else {
        this.service.hideSpinner();
      }
    })
  }

  fiatCryptoList(datafiatCrypto, event) {
    this.fiatCryptoData = datafiatCrypto
  }

}
