import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-internal-transfer-history',
  templateUrl: './internal-transfer-history.component.html',
  styleUrls: ['./internal-transfer-history.component.css']
})
export class InternalTransferHistoryComponent implements OnInit {
  profiledata:any
  historyList:any
  constructor(private router:Router,private _service : ServiceService,private datePipe: DatePipe) {

   }
  ngOnInit() {
    window.scrollTo(0,0)
    this.getTransferInternalData()
  }

  getTransferInternalData (){
    this._service.showSpinner();
    let url='wallet/wallet/get-transfer-internal-for-user?userId='+ this._service.getUserId();
      this._service.getCandyPixelForms(url).subscribe(response=>{
        if(response['status']==200){
            this.historyList =  response['data']
        }
      }
      , err => {
        this._service.unauthorizedApi(err['status'])
      })
  }

    /**
   * Export csv data of trade all/complete/cancel
   */
     exportCSV() {
      let dataArr = [];
      dataArr.push({
        // sNo: "S.No.",
        createdAt: "Created At",
        UserID: "User ID",
        CoinType: "Coin Type",
        Status: "Status",
        Amount: "Amount",
        // tradeAmount: "Trade Amount",
        // tradingFee: "Trading Fee",
        // totalBTC: "Total BTC",
       
      })
      this.historyList.forEach((element, ind) => {
        dataArr.push({
          // sNo: ind + 1,
          createdAt: element.txnTime ? this.datePipe.transform((element.txnTime),'medium') : '--',
          UserID: element.randomId ? element.randomId : '--',
          CoinType: element.coinType ? element.coinType : '--',
          Status: element.status ? element.status : '--',
          Amount: element.units ? element.units : '--',
          // tradeAmount: element.tradeAmount ? element.tradeAmount : '--',
          // tradingFee: element.tradeFee ? element.tradeFee : '--',
          // totalBTC: element.totalBTC ? element.totalBTC : '--',
          // exchangeRate: element.price ? element.price : '--'
        })
      })
      new ngxCsv(dataArr, 'Trades Data')
    }

}
