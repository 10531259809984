import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';


@Component({
  selector: 'app-staking-history',
  templateUrl: './staking-history.component.html',
  styleUrls: ['./staking-history.component.css']
})
export class StakingHistoryComponent implements OnInit {
  fixedItem:any = [];
  pageNumber:any = 1
  pageSize:any =10

  constructor(public service:ServiceService) { }

  ngOnInit() {
    this.getData()
  }
  getData() {
    let url = `wallet/view-fixed-deposit-code?page=${this.pageNumber -1}&pageSize=${this.pageSize}`
    // let url = `wallet/view-fixed-deposit-code`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.fixedItem = res['data']['getEmailHistoryDetailsFromDate'];
        console.log('=====>12', this.fixedItem);

        this.service.hideSpinner()
        // this.service.showSuccessMessage(res['message'])
      }
      else {
        this.service.hideSpinner()
        this.service.showErrorMessage('Data Not Found')
      }
    })
  }

}
