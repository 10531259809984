import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {
  termsData: any;

  constructor(public service: ServiceService) { 
    window.scrollTo(0,0)
  }

  ngOnInit() {
    this.getTerms()
  }

  getTerms(){ 
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=Disclaimer').subscribe(res=>{
           if (res['status']==200){
             this.termsData=res['data'].pageData;
           }
    })
   }

 
}
