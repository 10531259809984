import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginn: any;
  constructor(public router: Router) {

  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.loginn = localStorage.getItem('login')
  }
  message() {
    this.router.navigate(['/admin-chat'])
  }
  register() {
    this.router.navigate(['/register'])
  }
  exchange() {
    this.router.navigate(['/exchange-before-login'])
  }
  login() {
    this.router.navigate(['/login'])
  }
  marketInfo() {
    this.router.navigate(['/market-info'])
  }
  p2pexchange() {
    this.router.navigate(['/p2p-exchange'])
  }
  security(event) {
    if (event == 'wallet') {
      this.router.navigate(['/my-wallet'])
    }
    else if (event == 'withdraw') {
      this.router.navigate(['/withdraw-history'])
    }
    else if (event == 'deposit') {
      this.router.navigate(['/deposit-history'])
    }

  }

  new(event) {
    if (event == 'profile') {
      this.router.navigate(['/my-profile'])
    }
    else if (event == 'login') {
      this.router.navigate(['/login-history'])
    }
    else if (event == 'logout') {
      // this.router.navigate(['/login'])
      $('#myModal').modal('show')
    }
  }

  notifications() {
    this.router.navigate(['/notifications'])
  }
}
