import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ClipboardService } from "ngx-clipboard";
import { resolve } from "url";
declare var $: any;
@Component({
  selector: 'app-my-wallet-withdraw-crypto',
  templateUrl: './my-wallet-withdraw-crypto.component.html',
  styleUrls: ['./my-wallet-withdraw-crypto.component.css']
})
export class MyWalletWithdrawCryptoComponent implements OnInit {
  coinList: any = [];
  walletAddress: any;
  coinName: any;
  coinWithdrawFee: any;
  sendForm: FormGroup;
  finalAmount: number = 0;
  tags: any;
  account: any;
  tagError: string;
  selectedUserCoin: any = {};
  depositePageNumber: number = 1;
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  profileData: any;
  usdtdata: any;
  btcdata: any;
  value: number = 0;
  withdrawfee1: any;
  withdrawFee1: any;
  coinWithdrawAmount: any;
  coinamt: number;
  perCoinTotalBaln: any;
  receiveForm: FormGroup;
  userStatus: any;
  usdtCoin: any;
  kuldhan: any = 0;
  searchStatus: boolean = false;
  userKycStatus: any; // get user kyc status
  ipAddress: string;
  networkChaindeposit:any
  copyToClipboard(item) {
    this.service.showSuccessMessage("Copied successfully");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  copyToClipboardTag(item) {
    this.service.showSuccessMessage("Copied successfully");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  constructor(
    public router: Router,
    public service: ServiceService,
    private _clipboardService: ClipboardService
  ) {}
  depositeHistoryInterval;
  ngOnInit() {
    window.scrollTo(0, 0);

    // this.getAllCoins();
    // this.depositeHistoryInterval = setInterval(() => {
    //   this.getAllCoins();
    // }, 30000);
    this.depositETH();
    this.formValidationFunctionality();
    this.getProfile();
    // this.getwalletinUsd();
    this.getCoinList();
    // this.getTotalWalletAmount();
    // this.getinvestedWalletAmount();
    // this.getPercentageAmount();
    // this.getGainAmount();
    this.getIP(); // get IP Address
  }

  seachWalletTable(page) {
    this.depositePageNumber = page;
    if (this.searchStatus == true) {
      this.getAllCoins();
    } else {
      this.getallcoins1();
    }
  }

  // formValidation Functionality
  formValidationFunctionality() {
    this.sendForm = new FormGroup({
      coinAmount: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/),
      ]),
      address: new FormControl("", Validators.required),
      networkChain: new FormControl(""),
    });
    // this.receiveForm = new FormGroup({
    //   'walletAdd': new FormControl('',),

    // })
  }
  // Get All The Coin Functionality
  async getAllCoins() {
    await this.getPrice().catch((err) => {
      console.log(err);
    });

    // this.service.showSpinner();
    this.service
      .getCandyPixelForms("wallet/wallet/get-all-user-balance-and-coinlist")
      .subscribe(
        (res: any) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            var coinList = res["data"].coinList;
            for (let x = 0; x < coinList.length; x++) {
              const m = res.data.userBalance.findIndex(
                (ne) => coinList[x].coinShortName === ne.instrument
              );
              if (m != -1) {
                coinList[x]["totalBalance"] =
                  res["data"].userBalance[m].totalBalance;
                this.kuldhan =
                  res["data"].userBalance[m].totalBalance + this.kuldhan;
                coinList[x]["availableBalance"] =
                  res["data"].userBalance[m].availableBalance;
                coinList[x]["inOrderBalance"] =
                  res["data"].userBalance[m].inOrderBalance;
                coinList[x]["price24hours"] = this.get24hour(
                  res["data"].coinList[m].coinFullName
                );
                this.depositCoin(coinList[x].coinShortName);
              }
            }
            let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC", "INR"];
            this.coinList = coinList.filter((ele) => {
              return !removeCoin.includes(ele.coinShortName);
            });

            // this.coinList = coinList;
            // console.log(this.coinList);

            this.selectedUserCoin = this.coinList[0];
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }

  getallcoins1() {
    return;
    this.service.showSpinner();
    this.service
      .getCandyPixelForms("wallet/wallet/get-all-user-balance-and-coinlist")
      .subscribe(
        (res: any) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            var coinList = res["data"].coinList;
            for (let x = 0; x < coinList.length; x++) {
              const m = res.data.userBalance.findIndex(
                (ne) => coinList[x].coinShortName === ne.instrument
              );
              if (m != -1) {
                coinList[x]["totalBalance"] =
                  res["data"].userBalance[m].totalBalance;
                coinList[x]["availableBalance"] =
                  res["data"].userBalance[m].availableBalance;
                coinList[x]["inOrderBalance"] =
                  res["data"].userBalance[m].inOrderBalance;
                //this.depositCoin(coinList[x].coinShortName);
              }
            }
            let removeCoin = ["BCH", "OMG", "XLM", "DASH"];
            this.coinList = coinList.filter((ele) => {
              return !removeCoin.includes(ele.coinShortName);
            });
            // this.coinList = coinList;
            this.selectedUserCoin = this.coinList[0];
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }

  depositCoin(coinShortName) {
    var url = "";
    this.tags = "";
    this.account = "";
    // if (coinShortName == 'XRP' || coinShortName == 'XLM' || coinShortName == 'OMG' || coinShortName == 'USDT' || coinShortName == 'ETH') {

    //removing OMG and USDT from if condition
    // if (coinShortName == 'XRP' || coinShortName == 'XLM'  || coinShortName == 'ETH') {
    if (coinShortName == "XRP" || coinShortName == "XLM") {
      url =
        "wallet/wallet-type2/get-deposits?coinName=" +
        coinShortName +
        "&page=" +
        (this.depositePageNumber - 1) +
        "&pageSize=10";
      // this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            // this.service.showError('Something Went Wrong');
          }
        }
      );
    } else {
      /*else if (coinShortName == 'USDT') {
      url = 'coin/get-usdt-network-balance';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.usdtdata= res['data'].USDT_balance
          this.btcdata= res['data'].BTC_balance
          if(this.usdtdata>=1 && this.btcdata>=0.0001){
          this.getusdtdepositlist(coinShortName);
          }
          else{
            this.service.showError('You should have USDT more than 1 and BTC more than 0.001 to perform deposit')
          }
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
         // this.service.showError(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showError('Session Expired!');
        } else {
          //this.service.showError('Something Went Wrong');
        }
      })
    }*/
      url =
        "wallet/wallet/get-deposits?coinName=" +
        coinShortName +
        "&page=" +
        (this.depositePageNumber - 1) +
        "&pageSize=10";
      // this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.getallcoins1();
            // this.account = res['data']['eosAccountName']
            // this.walletAddress = res['data'].walletAddress;
            //$('#recieveModal').modal({ backdrop: 'static' })
          } else {
            // this.service.showError(res['message']);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            //this.service.showError('Something Went Wrong');
          }
        }
      );
    }
  }

  // Send & Recieve Coin Functionality
  sendCoin(coinShortName) {
    // this.service.showSpinner();
    this.service
      .getCandyPixelForms(
        "wallet/coin/get-coin-details?coinName=" + coinShortName
      )
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.coinWithdrawFee = res["data"].withdrawlFee;
            this.coinWithdrawAmount = res["data"].withdrawalAmount;
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );

    this.coinName = coinShortName;
    //this.coinWithdrawFee = this.withdrawfee1;
    this.sendForm.reset();
    //$('#sendModal').modal({ backdrop: 'static' })
  }
  getFinalAmount(event) {
    this.finalAmount = 0;
    if (/^(\d+)?([.]?\d{0,8})?$/.test(event.target.value)) {
      // this.finalAmount = Number(event.target.value) - ((this.coinWithdrawFee / 100) * Number(event.target.value));
      this.finalAmount = Number(event.target.value) - this.coinWithdrawFee;
      this.coinamt = Number(event.target.value);
      // this.value = ((this.coinWithdrawFee / 100) * Number(event.target.value));
      this.value = this.coinWithdrawFee;
    }
  }
  getTagValue(event) {
    this.tags = event.target.value;
  }
  sendCoinFunc() {
    var apiReq = {};
    var base;
    if (this.coinName == "OMG" || this.coinName == "BTC" || this.coinName == "ETH" || this.coinName == "XRP" || this.coinName == "BNB" || this.coinName == "TRX" || this.coinName == "USD" || this.coinName == "POLKADOT" || this.coinName == "MATIC" || this.coinName == "AVAX" || this.coinName == "SOLANA" || this.coinName == "INR") {
      apiReq = {
        amount: this.sendForm.value.coinAmount,
        coinName: this.coinName,
        isWithdraw: false,
        isKycAccepted: true,
        toAddress: this.sendForm.value.address,
 
        url: this.service.webUrl + "/wallet-transaction-status",
      };
      base = "wallet/wallet/withdraw";
      // base = 'wallet/wallet/withdraw-erc-BEP'
    } else  {
      apiReq = {
        amount: this.sendForm.value.coinAmount,
        coinName: this.coinName,
        isWithdraw: false,
        isKycAccepted: true,
        toAddress: this.sendForm.value.address,
        network : this.sendForm.value.networkChain,
        url: this.service.webUrl + "/wallet-transaction-status",
      };
      // base = "wallet/wallet/withdraw";
      base = 'wallet/wallet/withdraw-erc-BEP'
    } 
    // else {
    //   apiReq = {
    //     amount: this.sendForm.value.coinAmount,
    //     coinName: this.coinName,
    //     isWithdraw: false,
    //     isKycAccepted: true,
    //     tag: this.tags,
    //     toAddress: this.sendForm.value.address,
    //     url: this.service.webUrl + "/wallet-transaction-status",
    //   };
    //   base = "wallet/wallet/withdraw";
    // }

    // this.service.showSpinner();
    this.service.postCandyPixelForms(base, apiReq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#sendModal").modal("hide");
          this.service.showSuccessMessage(res["message"]);
          this.sendForm.reset();
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          localStorage.clear();
          this.router.navigate(["/login"]);
          this.service.showErrorMessage("Session Expired!");
        } else {
          this.service.showErrorMessage("Something Went Wrong");
        }
      }
    );
  }


  resetForm() {
    this.sendForm.reset();
    this.value = 0;
    this.finalAmount = 0;
  }
  disclaimerText: any = [];

  // recieveCoin(coinShortName) {
  //   this.getDisclamer(coinShortName)
  //   if( this.userStatus == 'BLOCK'){
  //      this.service.showErrorMessage('You have been blocked and suspended by admin, kindly contact our customer support')
  //      return
  //   }
  //   else {
  //   var url = ''
  //   this.tags = '';
  //   this.account = ''
  //   if (coinShortName == 'XRP' || coinShortName == 'XLM') {
  //     url = 'wallet/wallet-type2/get-address?coinName=' + coinShortName;
  //     this.service.showSpinner();
  //     this.service.getCandyPixelForms(url).subscribe(res => {
  //       this.service.hideSpinner();
  //       if (res['status'] == 200) {
  //         this.walletAddress = res['data'].walletAddress;
  //         this.tags = res['data'].tag;
  //         $('#recieveModal').modal({ backdrop: 'static' })
  //       }
  //     }, err => {
  //       this.service.hideSpinner();
  //       if (err['status'] == '401') {
  //         localStorage.clear();
  //         this.router.navigate(['/login']);
  //         this.service.showErrorMessage('Session Expired!');
  //       } else {
  //         this.service.showErrorMessage('Kyc not done');
  //       }
  //     })
  //   }
  //   else if (coinShortName == 'OMG') {
  //     url = 'wallet/wallet/get-address-for-erc20?coinName=' + coinShortName;
  //     this.service.showSpinner();
  //     this.service.getCandyPixelForms(url).subscribe(res => {
  //       this.service.hideSpinner();
  //       if (res['status'] == 200) {
  //         this.walletAddress = res['data'];
  //         $('#recieveModal').modal({ backdrop: 'static' })
  //       }
  //       else {
  //         this.service.showErrorMessage(res['message']);
  //       }
  //     }, err => {
  //       this.service.hideSpinner();
  //       if (err['status'] == '401') {
  //         localStorage.clear();
  //         this.router.navigate(['/login']);
  //         this.service.showErrorMessage('Session Expired!');
  //       } else {
  //         this.service.showErrorMessage('Kyc not done');
  //       }
  //     })
  //   } else {
  //     url = 'wallet/wallet/get-address?coinName=' + coinShortName;
  //     this.service.showSpinner();
  //     this.service.getCandyPixelForms(url).subscribe(res => {
  //       this.service.hideSpinner();
  //       if (res['status'] == 200) {
  //         this.account = res['data']['eosAccountName']
  //         this.walletAddress = res['data'].walletAddress;
  //         $('#recieveModal').modal({ backdrop: 'static' })
  //       }
  //       else {
  //         this.service.showErrorMessage(res['message']);
  //       }
  //     }, err => {
  //       this.service.hideSpinner();
  //       if (err['status'] == '401') {
  //         localStorage.clear();
  //         this.router.navigate(['/login']);
  //         this.service.showErrorMessage('Session Expired!');
  //       } else {
  //         this.service.showErrorMessage('Kyc not done');
  //       }
  //     })
  //   }
  // }
  // }

  getProfile() {
    if (localStorage.getItem("credential")) {
      // this.service.showSpinner();
      this.service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.profileData = res["data"]["twoFaType"];
            this.userStatus = res["data"]["userStatus"];
            this.userKycStatus = res["data"]["kyc"];
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          // this.service.showError(err['message']);
        }
      );
    }
  }

  openModal() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (this.coinamt < this.coinWithdrawAmount) {
      this.service.showErrorMessage(
        "Coin amount cannot be less than min. withdrawal amount"
      );
    } else {
      if (this.profileData == "GOOGLE") {
        $("#googleAuth").modal({ backdrop: "static" });
      } else if (this.profileData == "SMS") {
        this.suggesstionFunc("sms");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == "EMAIL") {
        this.suggesstionFunc("email");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == "NONE" || this.profileData == "SKIP") {
        this.service.showErrorMessage("Please Enable Two FA First.");
      }
    }
  }

  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this.service.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            this.service.showSuccessMessage(res["message"]);
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            this.sendCoinFunc();
            // this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            this.service.showSuccessMessage(res["message"]);
            this.sendCoinFunc();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            this.service.showSuccessMessage(res["message"]);
            this.sendCoinFunc();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            //this.service.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }

  // Resend Email For Verification
  //  resSendEmail() {
  //   var url = "account/resend-verify-email?email=" + this.loginForm.value.email + "&webUrl=" + this.service.webUrl + "/landing";
  //   this.service.showSpinner();
  //   this.service.getCandyPixelForms(url).subscribe(res => {
  //     this.service.hideSpinner();

  //   }, err => {
  //     this.service.hideSpinner();
  //   })
  // }
  // Copy Functionality
  copy(val) {
    if (val == 1) {
      this._clipboardService.copyFromContent(this.receiveForm.value.walletAdd);
      this.service.showSuccessMessage("Wallet Address Copied Successfully");
    }
    // } else  (val == 2) {
    //   this._clipboardService.copyFromContent(this.sendForm.value.uniqueId)
    //   this.service.showSuccessMessage('Tag Id Copied Successfully');
    // }
  }

  // selectedCoin Functionality
  // selectedCoin(coinname, maxtotalbaln) {
  //   if( this.userStatus == 'BLOCK'){
  //     this.service.showErrorMessage('You have been blocked and suspended by admin, kindly contact our customer support')
  //     return
  //  }
  //  else{
  //   this.selectedUserCoin = coinname;
  //   this.perCoinTotalBaln = maxtotalbaln

  //   $('#sendModal').modal({ backdrop: 'static' })
  //   this.sendCoin(coinname)
  //  }

  // }

  getDepositePage(pageNumber) {
    this.depositePageNumber = pageNumber - 1;
    this.depositCoin(this.coinList);
  }

  getusdtdepositlist(coinShortName) {
    var url = "";
    this.tags = "";
    this.account = "";
    url =
      "wallet/wallet/get-deposits?coinName=" +
      coinShortName +
      "&page=" +
      (this.depositePageNumber - 1) +
      "&pageSize=10";
    // this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.getallcoins1();
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        } else {
          // this.service.showError(res['message']);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          localStorage.clear();
          this.router.navigate(["/login"]);
          this.service.showErrorMessage("Session Expired!");
        } else {
          //this.service.showError('Something Went Wrong');
        }
      }
    );
  }
  depositETH() {
    // this.service.showSpinner();
    this.service
      .getCandyPixelForms("wallet/wallet/get-address?coinName=ETH")
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.appC.showInfoToast('Address fetched successfully.');
            //  this.coinFullName = succ.body.data.coinName;
            //  this.textValue = succ.body.data.walletAddress;
            //  this.myAngularxQrCode = succ.body.data.walletAddress;
            //  this.tagId = succ.tag;
            this.etherSuccess();
            // $('#wallet-address').modal({backdrop: 'static', keyboard: false});
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            // this.service.showError('Something Went Wrong');
          }
        }
      );
  }

  // Ether Success
  etherSuccess() {
    // this.service.getCandyPixelForms('wallet/wallet-type2/get-async-transfer?coinName=ETH').subscribe(res => {
    // }, err => {
    // })
  }

  depositUSDT() {
    // this.service.showSpinner();
    this.service
      .getCandyPixelForms("wallet/wallet/get-address?coinName=ETH")
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.appC.showInfoToast('Address fetched successfully.');
            //  this.coinFullName = succ.body.data.coinName;
            //  this.textValue = succ.body.data.walletAddress;
            //  this.myAngularxQrCode = succ.body.data.walletAddress;
            //  this.tagId = succ.tag;
            this.etherSuccess();
            // $('#wallet-address').modal({backdrop: 'static', keyboard: false});
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            // this.service.showError('Something Went Wrong');
          }
        }
      );
  }

  getwalletinUsd() {
    // this.service.showSpinner();
    let url = "wallet/wallet/get-balance-in-BTC";
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.service.hideSpinner();
        this.usdtCoin = res["data"].balanceInBtc;
      }
    });
  }

  // tto get coin list
  getCoinList() {

    this.service
      .getCandyPixelForms("wallet/coin/get-coin-list")
      .subscribe((getLiquidity) => {
        if (getLiquidity["status"] == 200) {
          let index = getLiquidity["data"].findIndex(
            (x) => x.coinShortName == "USD"
          );
          if (index != 1) {
            getLiquidity["data"].splice(index, 2);
            this.coinList = getLiquidity["data"];
          }
          $(document).ready(function () {
            $(".main_slider").slick({
              // autoplay: true,
              dots: true,
              loop: false,
              arrow: true,
              infinite: true,
              speed: 300,
              slidesToShow: 4,
              slidesToScroll: 5,
              responsive: [
                {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    dots: false,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          });
          $(window).resize(function () {
            // debugger;
            $(".customer-logos, .main_slider").slick("setPosition");
          });
          $(document).ready(function () {
            $(".customer-logos").slick({
              slidesToShow: 6,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 1200,
              arrows: false,
              dots: false,
              pauseOnHover: false,
              responsive: [
                {
                  breakpoint: 1450,
                  settings: {
                    slidesToShow: 4,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 4,
                  },
                },
                {
                  breakpoint: 520,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            });
          });
        }
        // this.getPrice();
      });
  }
  /**
   * Slider
   */
  getLiquiditydata: any = [];
  async getPrice() {
    this.getLiquiditydata = [];
    let getLiquidity;
    try {
      getLiquidity = await this.service.getLiquidity().toPromise();
    } catch (error) {
      console.log(error);
    }
    this.getLiquiditydata = getLiquidity["data"];
    // console.log(this.getLiquiditydata);

    // getLiquiditydata.forEach((element) => {
    //   var ind = this.coinList.findIndex(
    //     (x) => x.coinShortName == element.symbol
    //   );
    //   if (ind != -1) {
    //     this.coinList[ind].marketPriceInUsd24HourBefore =
    //       element.quote.USD.percent_change_24h;
    //     this.coinList[ind].marketPriceInUsdAWeekBefore =
    //       element.quote.USD.percent_change_7d;
    //     this.coinList[ind].price = element.quote.USD.price.toFixed(4);
    //     this.coinList[ind].volume_24h = element.quote.USD.volume_24h;
    //     this.coinList[ind].last_updated = element.last_updated;
    //   }
    //   console.log(this.coinList);

    // });
  }
  get24hour(e) {
    let data = this.getLiquiditydata.filter((element) => {
      let chotanaam = String(e).toLowerCase();

      return String(element.name).toLowerCase() == chotanaam;
    });
    // console.log(data);

    return data;
  }
  totalData: any;
  myId: any;
  // Get total wallet amount
  getTotalWalletAmount() {
    this.myId = localStorage.getItem("userId");

    var url = `wallet/wallet/total-balance-for-user?fkUserId=${this.myId}`;

    //  `${ApiEndPoint.deleteAddress + '?addressId=' + this.addressId}`

    // this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.totalData = res["data"];

          // this.stateList = this.arr[index].states;
          // this.mobileData = this.profileData.phoneNo.split('-');
          this.service.name = this.profileData.firstName;

          // if(this.profileData.country) {
          //   this.getStateList(this.profileData.country);
          // }

          //this.states =this.profileData.state
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        // this.service.showErrorMessage("Server error");
      }
    );
  }

  totalDataDetails: any = [];
  // Get total wallet amount
  getinvestedWalletAmount() {
    // this.myId =    localStorage.getItem('userId')

    var url = `wallet/wallet/total-total-blocked-balance-for-user?fkUserId=${this.myId}`;
    // this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.totalDataDetails = res["data"];

          // if(this.profileData.country) {
          //   this.getStateList(this.profileData.country);
          // }

          //this.states =this.profileData.state
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        // this.service.showErrorMessage("Server error");
      }
    );
  }

  //get loss...............................
  percentageData: any;
  // Get total wallet amount
  getPercentageAmount() {
    var url = `wallet/wallet/percertage-for-user?fkUserId=${this.myId}`;

    //  `${ApiEndPoint.deleteAddress + '?addressId=' + this.addressId}`

    // this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.percentageData = res["data"];

          // this.stateList = this.arr[index].states;
          // this.mobileData = this.profileData.phoneNo.split('-');
          this.service.name = this.profileData.firstName;

          // if(this.profileData.country) {
          //   this.getStateList(this.profileData.country);
          // }

          //this.states =this.profileData.state
        } else {
          this.service.hideSpinner();
          //  this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        // this.service.showErrorMessage("Server error");
      }
    );
  }

  // Get total gain amount wallet amount
  /***
   * @param get api integration of gain amount
   *
   */
  gainAmountData: any;
  getGainAmount() {
    var url = `wallet/wallet/total-balance-subtracted-from-total-blocked-balance-for-user?fkUserId=${this.myId}`;

    //  `${ApiEndPoint.deleteAddress + '?addressId=' + this.addressId}`

    // this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.gainAmountData = res["data"];

          // this.stateList = this.arr[index].states;
          // this.mobileData = this.profileData.phoneNo.split('-');
          this.service.name = this.profileData.firstName;

          // if(this.profileData.country) {
          //   this.getStateList(this.profileData.country);
          // }

          //this.states =this.profileData.state
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        // this.service.showErrorMessage("Server error");
      }
    );
  }

  // selectedCoin Functionality
  disclaimer_for_withdraw: any = [];
  selectedCoin(coinname, maxtotalbaln) {
    if (coinname == "BTC") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission";
    } else if (coinname == "AVT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "XRP") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "TRX") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "USD") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "POLKADOT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "MATIC") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "AVAX") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "SOLANA") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "INR") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "BNB") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "ETH") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "USDT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supportyed and will be lost forever. Withdrawl request cannot be cancelled after submission. Withdrawals are only supported for ERC-20 wallets, OMNI wallets are not supported";
    }

    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      /**
       * check kyc status
       * if PENDING then user cannot proceed further
       */
      // console.log(this.userKycStatus)
      if (
        !this.userKycStatus ||
        this.userKycStatus.kycStatus == "PENDING" ||
        this.userKycStatus.kycStatus == "REJECTED"
      ) {
        return this.service.showSuccessMessage("Please complete your KYC.");
      } else {
        this.selectedUserCoin = coinname;
        this.perCoinTotalBaln = maxtotalbaln;

        $("#sendModal").modal({ backdrop: "static" });
        this.sendCoin(coinname);
      }
    }
  }

  // deposit desclamer
  disclaimer: any = [];
  recieveCoin(coinShortName) {
    if (coinShortName == "BTC") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please deposit only to this address. If you deposit any other coins, it will be lost forever"),
        (this.disclaimer[1] =
          "Please send only BTC tokens on the Bitcoin (BTC) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[2] = "Minimum Deposit: 1$ worth of BTC"),
        (this.disclaimer[3] =
          "Minimum Network Confirmations: 2 (Usually takes ~30 mins)");
    } else if (coinShortName == "XRP") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "Please send only XRP tokens on the Ripple (XRP) network to this address. Sending other tokens or sending tokens without a tag will result in their permanent loss.";
      this.disclaimer[1] = "Minimum Deposit: 1$ worth of XRP";
      this.disclaimer[2] =
        "Minimum Network Confirmations: Usually takes ~1 mins";
    } else if (coinShortName == "BCH") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only BCH tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 BCH"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    } else if (coinShortName == "LTC") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only LTC tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 LTC'"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    }
    // else if (coinShortName == 'AVT') {
    //   this.disclaimer = []
    //   this.disclaimer[0] = "This is BEP2 deposit address type. Transferring to an unsupported network could result in loss of deposit."
    //   this.disclaimer[1] = "Please deposit only RPX to this address. Adding Memo is mandatory. If you do not add the correct memo or if you deposit any other coins, it will be lost forever."
    // }
    else if (coinShortName == "BNB") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "Please deposit only to this address. If you deposit any other coins, it will be lost forever";
    } else if (coinShortName == "TRX") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only TRX tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[0] = "Minimum Deposit: 1 TRX"),
        (this.disclaimer[0] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    } else if (coinShortName == "ETH") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
      this.disclaimer[1] =
        "Minimum deposit of 0.0 ETH, deposit below that cannot be recovered.";
      this.disclaimer[2] =
        "Please deposit only ETH to this address. If you deposit any other coins, it will be lost forever. Deposit from smart contract address is not supported and cannot be recovered.";
    } else if (coinShortName == "USDT") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
      this.disclaimer[1] =
        "Minimum deposit of 0.0 USDT, deposit below that cannot be recovered.";
      this.disclaimer[2] =
        "Please deposit only USDT on this address. If you deposit any other coin, it will be lost forever.";
      this.disclaimer[3] =
        "USDT ERC-20 support only, OMNI wallet is not supported.";
    } else if (coinShortName == "USD") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only USD tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 USD"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "POLKADOT") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only POLKADOT tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 POLKADOT"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "MATIC") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only MATIC tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 MATIC"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "AVAX") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only AVAX tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 AVAX"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "SOLANA") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only SOLANA tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 SOLANA"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "INR") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only INR tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 INR"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    }

    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      var url = "";
      this.tags = "";
      this.account = "";
      if (coinShortName == "XRP" || coinShortName == "XLM") {
        url = "wallet/wallet-type2/get-address?coinName=" + coinShortName;
        // this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.walletAddress = res["data"].walletAddress;
              this.tags = res["data"].tag;
              $("#recieveModal").modal({ backdrop: "static" });
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "401") {
              localStorage.clear();
              this.router.navigate(["/login"]);
              this.service.showErrorMessage("Session Expired!");
            } else {
              this.service.showErrorMessage("Kyc not done");
            }
          }
        );
      } else if (coinShortName == "OMG") {
        url = "wallet/wallet/get-address-for-erc20?coinName=" + coinShortName;
        // this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.walletAddress = res["data"];
              $("#recieveModal").modal({ backdrop: "static" });
            } else {
              this.service.showErrorMessage(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "401") {
              localStorage.clear();
              this.router.navigate(["/login"]);
              this.service.showErrorMessage("Session Expired!");
            } else {
              this.service.showErrorMessage("Kyc not done");
            }
          }
        );
      } else {
        url = "wallet/wallet/get-address?coinName=" + coinShortName;
        // this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.account = res["data"]["eosAccountName"];
              this.walletAddress = res["data"].walletAddress;
              $("#recieveModal").modal({ backdrop: "static" });
            } else {
              this.service.showErrorMessage(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "401") {
              localStorage.clear();
              this.router.navigate(["/login"]);
              this.service.showErrorMessage("Session Expired!");
            } else {
              this.service.showErrorMessage("Kyc not done");
            }
          }
        );
      }
    }
  }
  // getDisclamer(coinShortName){
  //   this.disclaimerText = []
  //   if(coinShortName == 'BTC'){
  //     this.disclaimerText = [
  //       {p : 'Please send only BTC tokens on the Bitcoin (BTC) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1$ worth of BTC'},
  //       {p : 'Minimum Network Confirmations: 2 (Usually takes ~30 mins)'},
  //     ]
  //   }
  //   else if(coinShortName == 'ETH'){
  //     this.disclaimerText = [
  //       {p : 'Please send only ETH tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 0.005 ETH'},
  //       {p : 'Minimum Network Confirmations: 7 (Usually takes ~2 mins)'},
  //     ]
  //   }
  //   else if(coinShortName == 'XRP'){
  //     this.disclaimerText = [
  //       {p : 'Please send only XRP tokens on the Ripple (XRP) network to this address. Sending other tokens or sending tokens without a tag will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1$ worth of XRP'},
  //       {p : 'Minimum Network Confirmations: Usually takes ~1 mins'},
  //     ]
  //   }
  //   else if(coinShortName == 'BCH'){
  //     this.disclaimerText = [
  //       {p : 'Please send only BCH tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1 BCH'},
  //       {p : 'Minimum Network Confirmations: 12 (Usually takes ~1 min)'},
  //     ]
  //   }
  //   else if(coinShortName == 'LTC'){
  //     this.disclaimerText = [
  //       {p : 'Please send only LTC tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1 LTC'},
  //       {p : 'Minimum Network Confirmations: 12 (Usually takes ~1 min)'},
  //     ]
  //   }
  //   else if(coinShortName == 'OMG'){
  //     this.disclaimerText = [
  //       {p : 'Please send only OMG tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1$ worth of OMG'},
  //       {p : 'Minimum Network Confirmations: 7 (Usually takes ~2 mins)'},
  //     ]
  //   }
  //   else if(coinShortName == 'XLM'){
  //     this.disclaimerText = [
  //       {p : 'Please send only XLM tokens on the Stellar (XLM) network to this address. Sending other tokens or sending tokens without a tag will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1$ worth of XLM'},
  //       {p : 'Minimum Network Confirmations: Usually takes ~1 mins'},
  //     ]
  //   }
  //   else if(coinShortName == 'TRX'){
  //     this.disclaimerText = [
  //       {p : 'Please send only TRX tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1 TRX'},
  //       {p : 'Minimum Network Confirmations: 12 (Usually takes ~1 min)'},
  //     ]
  //   }
  //   else if(coinShortName == 'DASH'){
  //     this.disclaimerText = [
  //       {p : 'Dash Is A Trade Only Token'},
  //       {p : 'You Can not Deposit, Withdraw & Earn Interest'},
  //       {p : 'You Can Buy, Sell & Trade With Other Tokens'},
  //     ]
  //   }
  //   else if(coinShortName == 'USDT'){
  //     this.disclaimerText = [
  //       {p : 'Please send only USDT tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1 USDT'},
  //       {p : 'Minimum Network Confirmations: 7 (Usually takes ~2 mins)'},
  //     ]
  //   }
  //   else if(coinShortName == 'USD'){
  //     this.disclaimerText = [
  //       {p : 'Please send only USD tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1 USD'},
  //       {p : 'Minimum Network Confirmations: 7 (Usually takes ~2 mins)'},
  //     ]
  //   }
  //   else if(coinShortName == 'INR'){
  //     this.disclaimerText = [
  //       {p : 'Please send only INR tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss.'},
  //       {p : 'Minimum Deposit: 1 INR'},
  //       {p : 'Minimum Network Confirmations: 7 (Usually takes ~2 mins)'},
  //     ]
  //   }
  // }
  data: any;
  getNetworkChainDetails(event) {
    this.data = event.target.value;
    this.data =
      "This deposit address supports ERC20 BBTC tokens. Please ensure your destination address supports BBTC tokens under the contract address ending in 22541.";
  }

  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  navigateToTransfer(coinShortName) {
    // this.router.navigate(['/feedback-view'], { queryParams: { feedbackId: feedbackId } })
    this.router.navigate(["/transfer"], {
      queryParams: { coinShortName: coinShortName },
    });
  }
}
