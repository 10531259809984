import { HttpClient } from '@angular/common/http';
import { Component, IterableDiffers, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { environment } from '../environments/environment';
import { currencies } from 'currencies.json';
import { Observable, of } from 'rxjs';


declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
// splesh
// The screen starts with the maximum opacity
// public opacityChange = 1;
// public splashTransition;
// // First access the splash is visible
// public showSplash = true;
// readonly ANIMATION_DURATION = 1;


  title = 'cryptocurrency';
  currUrl: string;
  isLoggedIn: boolean = false;
  selected: any = '/signup';
  selectedemailv: boolean = false;
  userImage: any = "assets/images/user_un.png";
  userName: any;
  profileData: any = {};
  chatArr: any = [];
  modeType: any = 'night';
  language: any = "Hindi"
  totalNotification: any;
  iterableDiffer
  totalMessage: any;
  products : any  = [];
  currencyListArray: any[] = []


//   private hideSplashAnimation() {
//     // Setting the transition
//     this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
//     this.opacityChange = 0;
//     setTimeout(() => {
//        // After the transition is ended the showSplash will be hided
//        this.showSplash = !this.showSplash;
//     }, 1000);
//  }
  constructor(public routes: Router, public service: ServiceService, private iterableDiffers: IterableDiffers,private httpClient: HttpClient) {
    localStorage.setItem("cooki", "cooki");

    var self = this;
    $(document).bind("mousedown", function (e) {
      if (e.button == 0) {
        self.checkSession();
      }
    });

    
    /*********** Routing Managed Start Here ***************/
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (this.currUrl == `login`) {
          this.selected = '/login';
        } else if (this.currUrl == `signup`) {
          this.selected = '/signup';
        }
        if (localStorage.credential) {
          this.service.changeLoginSub('login')
          if ((this.currUrl == `login` || this.currUrl == `register` || this.currUrl.includes('email-verify') || this.currUrl.includes('reset') || this.currUrl == `register2` || this.currUrl == `forgot`)) {
            this.routes.navigate([``])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl.includes('') || this.currUrl.includes('p2p-buy') || this.currUrl.includes('email-verify') || this.currUrl.includes('reset') ||
            this.currUrl == `terms` || this.currUrl == `aboutUs` || this.currUrl == `contactUs` || this.currUrl == `privacy`
            || this.currUrl == `signup` || this.currUrl == `signup-detail` || this.currUrl == `forgot` || this.currUrl == `faq` ||
            this.currUrl == `advance` || this.currUrl == `basic` || this.currUrl == `buy-sell` || this.currUrl == `marketInfo` ||
            this.currUrl == `support` || this.currUrl == 'advance-exchange' || this.currUrl == 'trade/:id' || this.currUrl == `wallet-transaction-status`)) {
            this.routes.navigate([``])
          }
          this.service.changeLoginSub('logout');
        }
      }
    })
    /*********** Routing Managed End Here ***************/

    // to show notification count
    this.service.getMessage().subscribe((res) => {
      if (res.text.message) {
        if ((res.text.message.includes('trade request from'))) {
          this.chatArr.push(res.text);
        }
      }
    })
    this.iterableDiffer = iterableDiffers.find([]).create(null); // for unseen notification count
  }


//   public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any>> {
//     return new Promise((resolve, reject) => {
//        setTimeout(() => {
//           this.service.stop();
//           resolve(of(['item1', 'item2']));
//        }, 5000);
//     });
//  }
  ngOnInit() {
   
    
    // Somewhere the stop method has been invoked
//    this.service.subscribe(res => {
//     this.hideSplashAnimation();
//     setTimeout(() => {
//       this.service.stop();
//     }, 5000);
//  });



    window.scrollTo(0, 0);
    this.service.initSocket();
    this.service.initSocketChat(); //chat
    this.service.initSocketNotification(); // notification
    this.checkSession();
    // this.service.changeLoginSub('login');
    this.service.loginObs.subscribe(response => {

      if ($(".mobileresposnsive").hasClass("menushowhide")) {
        $(".mobileresposnsive").removeClass("menushowhide");
      };

      $('main').click(function () {
        if ($(".mobileresposnsive").hasClass("menushowhide")) {
          $(".mobileresposnsive").removeClass("menushowhide");
        };
      });

      $('li.custom-space').click(function () {
        if ($(".sidenav").hasClass("onetwo")) {
          $(".sidenav").removeClass("onetwo");
        };
      });

      $('main').click(function () {
        $(".sidenav").addClass("onetwo");
      });

      if (response == 'login') {
        this.isLoggedIn = true;
        this.getProfile();
      }
      else {
        this.isLoggedIn = false;

      }
    })

    this.httpClient.get("assets/language.json").subscribe(data => {
      this.products = data;

    })
  
    this.currencyList();
  }



  /** Function to get diffrence between timestamp */
  diff_minutes(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  /** Function for check session */
  checkSession() {
    if (localStorage.getItem('token') != null && localStorage.getItem('session') != null) {
      let minDiff = this.diff_minutes(parseInt(localStorage.getItem('session')), new Date().getTime());
      localStorage.setItem('session', new Date().getTime().toString());
      if (minDiff >= 10) {
        this.logout();
      }
    } else {
      localStorage.setItem('session', new Date().getTime().toString());
    }
  }

  // Logout Functionality
  logout() {
    $('#logout').modal({ backdrop: 'static' });
  }

  logoutFunc() {
    $('#logout').modal('hide');
    localStorage.removeItem('credential')
    localStorage.removeItem('profiledata')
    this.routes.navigate(['/login'])
  }

  // <script>
  openNav() {
    document.getElementById("mySidenav").style.width = "250px";

  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  // </script>
  selectTab(tab) {
    this.selected = tab
  }


  navigateToBy(path) {
    this.routes.navigateByUrl(path);
  }

  navigateTo(path) {
    const token = localStorage.getItem('credential')
    if (token != null) {
      this.isLoggedIn = true
      this.routes.navigate(['/basic-buy-sell/sell'])
    }
    else {
      this.isLoggedIn = false
      this.routes.navigate(['/login'])
    }
    //this.routes.navigateByUrl(path)
  }

  // Get Profile Name-Email
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.showSpinner();
      this.service.getCandyPixelForms(`account/my-account`).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          var profileData = res['data'];
          this.profileData = res['data']
          localStorage.setItem('profiledata', JSON.stringify(this.profileData))
          this.userImage = profileData.imageUrl;
          this.userName = profileData.firstName
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        // this.service.showError(err['message']);
      })
    }
  }


  security(event) {
    if (event == 'wallet') {
      this.routes.navigate(['/my-wallet'])
    }
    else if (event == 'withdraw') {
      this.routes.navigate(['/withdraw-history'])
    }
    else if (event == 'deposit') {
      this.routes.navigate(['/deposit-history'])
    }
  }

  new(event) {
    if (event == 'profile') {
      this.routes.navigate(['/my-profile'])
    }
    else if (event == 'login') {
      this.routes.navigate(['/login-history'])
    }
    else if (event == 'logout') {
      // this.routes.navigate(['/login'])
      // $('#myModal').modal('show')
      $('#logout').modal({ backdrop: 'static' });
    }
  }

// dropdown
drop(){
  $('.dropdown-toggle').dropdown()
}

  // get notification
  notifications() {
    this.routes.navigate(['/notifications'])
  }

  // get unseen notification count
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.service.notificationArr);
    if (changes) {
      let unSeenNotifCount = this.service.notificationArr.filter(x => x.isSeen == false)
      this.totalNotification = unSeenNotifCount.length
      let unSeenMessageCount = this.service.messageArr.filter(x => x.isSeen == false)
      this.totalMessage = unSeenMessageCount.length
    }
  }

  modeChangeFunc(type) {
    this.modeType = type
    if (type == 'night') {
      $("body").attr("data-theme-version", "dark")
    } else {
      $("body").attr("data-theme-version", "light")
    }

  }
  // darkTheme : boolean = true
  // changeTheme(){
  //   this.darkTheme = !this.darkTheme
  //   this.service.darkTheme.next(this.darkTheme)
  // }

  currencyList() {
    this.currencyListArray = currencies
    // console.log(this.currencyListArray);
    
  }

  
 
}
