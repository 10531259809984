import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-withdraw-history',
  templateUrl: './withdraw-history.component.html',
  styleUrls: ['./withdraw-history.component.css']
})
export class WithdrawHistoryComponent implements OnInit {
  coinList: any = [];
  selectedUserCoin: any = {};
  withdrawList: any = [];
  total: any;
  withdrawPageNumber: number = 1;
  fiatCryptoData: any = "CRYPTO"
  transferForm: FormGroup
  pageNumber = 1
  pageSize = 10
  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getListOfCoin();
    this.checkTransferFormValidations()
    this.showList()
  }


  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'currency': new FormControl(''),
      'type': new FormControl(''),
      'searchTextTxnHash': new FormControl(''),
      'amount': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }
  // Get All The Coin Functionality
  getListOfCoin() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (let x = 0; x < coinList.length; x++) {

          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;


          }
        }
        let removeCoin = ['BCH', 'OMG', 'XLM', 'DASH', 'LTC']
        this.coinList = coinList.filter((ele) => {
          return !removeCoin.includes(ele.coinShortName)
        })
        // this.coinList = coinList;

        this.selectedUserCoin = this.coinList[0].coinShortName
        this.getTrasactionList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  selectcoin(coin) {
    this.selectedUserCoin = coin
    this.getTrasactionList()
  }
  // Get Transaction List Functionality
  getTrasactionList() {

    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    let hash = this.transferForm.value.searchTextTxnHash
    this.withdrawList = [];
    var url = `wallet/get-all-transaction-history-USER?page=${this.withdrawPageNumber - 1}&pageSize=10&txnType=WITHDRAW&coinName=${this.selectedUserCoin}${fromDate ? '&fromDate=' + fromDate : ''}${toDate ? '&toDate=' + toDate : ''}${hash ? '&txnHash=' + hash : ''}`

    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.withdrawList = res['data']['resultlist'];
        this.total = res['data']['totalCount'];
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Kyc not done!');
      }
    })

  }
  reset() {
    this.transferForm.reset()
    this.getTrasactionList()
  }

  getWithdrawPage(pageNumber) {
    this.withdrawPageNumber = pageNumber;
    this.getTrasactionList();
  }
  exportAsEXCEL() {
    let dataArr = [];
    if (this.withdrawList.length) {
      this.withdrawList.forEach((element, ind) => {

        dataArr.push({
          "S No": ind + 1,
          "Date and Time": element.txnTime ? element.txnTime.slice(0, 10) : 'N/A',
          "Amount": element.amount ? element.amount : 'N/A',
          "Address": element.address ? element.address : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : 'N/A',
          // "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
          // "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'Withdraw list history');
    }
  }
  copyText(text: string) {
    window.navigator.clipboard.writeText(text)
    this.service.showSuccessMessage('Copied to clipboard')
  }

  fiatCryptoList(datafiatCrypto, event) {
    this.fiatCryptoData = datafiatCrypto
  }
  showData:any = [];
  showList() {
    let url = `wallet/wallet/List-Withdraw-Inr?page=${this.pageNumber -1}&pageSize=${this.pageSize}`
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/List-Withdraw-Inr').subscribe((getLiquidity) => {
      if (getLiquidity['status'] == 200) {
        this.showData = getLiquidity['data']
        this.service.hideSpinner();
      }

      else {
        this.service.hideSpinner();
      }
    })
  }
  exportAsEXCELFait() {
    let dataArr = [];
    if (this.showData.length) {
      this.showData.forEach((element) => {

        dataArr.push({
          "AMOUNT": element.amount ? element.amount : 'N/A',
          "FIAT ID": element.fiatId ? element.fiatId : 'N/A',
          "UTR NO.": element.utrNo ? element.utrNo : 'N/A',
          "STATUS": element.fiatStatus ? element.fiatStatus : 'N/A',
          "DATE": element.createTime ? element.createTime : 'N/A',
          // "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
          // "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'Deposit list history');
    }
  }
}
