import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any
@Component({
  selector: 'app-home-before-login',
  templateUrl: './home-before-login.component.html',
  styleUrls: ['./home-before-login.component.css']
})
export class HomeBeforeLoginComponent implements OnInit {
  userLoginStatus: boolean = false
  isLoggedIn = false;
  getStarted: FormGroup
  coinList: any=[];
  showCookie :any='';
 
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: ServiceService,public fb: FormBuilder) 
  {
    this.service.getWalletCoins(); 
   }

  ngOnInit() {
   
      this.showCookie=localStorage.getItem('cooki')
    
    this.service.connectChatSocket();
    this.getStarted = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]]
    })
    window.scrollTo(0, 0)
    this.verifyEmail();
    this.getCoinList();
    // this.getPrice();
    const token = localStorage.getItem('credential')
    if (token != null) {
      this.isLoggedIn = true
    }
    else {
      this.isLoggedIn = false
    }
  }

  getPrice() {
    
    var getLiquiditydata = [];
    this.service.getLiquidity().subscribe((getLiquidity) => {
      getLiquiditydata = getLiquidity['data']
      getLiquiditydata.forEach((element) => {
        var ind = this.coinList.findIndex((x) => x.coinShortName == element.symbol)
        if (ind != -1) {
          this.coinList[ind].marketPriceInUsd24HourBefore = element.quote.USD.percent_change_24h
          this.coinList[ind].marketPriceInUsdAWeekBefore = element.quote.USD.percent_change_7d
          this.coinList[ind].price = element.quote.USD.price.toFixed(4);
          this.coinList[ind].volume_24h = element.quote.USD.volume_24h
          this.coinList[ind].last_updated = element.last_updated
          
        }
      })
    })
   
    
  }
  
  // verify email 
  verifyEmail() {
    this.activatedRoute.queryParams.subscribe(params => {
      let token = params['token'];
      if (token) {
        this.service.getCandyPixelForms('account/verify-user?token=' + token).subscribe(succ => {
          if (succ['status'] == 200) {
            this.service.showSuccessMessage(succ['message'])
            this.router.navigate(['/login'])
          }
        }, (error) => {
          this.service.showErrorMessage(error.message)
        })
      }
    })
  }

  register(){
    this.router.navigate(['/register'])
  }

  goToSignup(){
    this.service.email = this.getStarted.value.email;
    this.router.navigate(['/register'])
  }
  // tto get coin list
getCoinList() {
  this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe((getLiquidity) => {
    if (getLiquidity['status'] == 200) {
      let index = getLiquidity['data'].findIndex(x => x.coinShortName == 'USD')
      if (index != 1) {
        getLiquidity['data'].splice(index, 2)
        this.coinList = getLiquidity['data']
        
      }
      $(document).ready(function () {
        $('.main_slider').slick({
          // autoplay: true,
          dots: true,
          loop: false,
          arrow: true,
          infinite: true,
          speed: 300,
          slidesToShow: 5,
          slidesToScroll: 5,
          responsive: [{
            breakpoint: 1280,
            settings:
            {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 1024,
            settings:
            {
              slidesToShow: 3,
              dots: false,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 768,
            settings:
            {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 600,
            settings:
            {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings:
            {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
          ]
        });
      });
      $(window).resize(function(){
        // debugger;
        $('.customer-logos, .main_slider').slick('setPosition');
      });
      $(document).ready(function () {
        $('.customer-logos').slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1200,
            arrows: false,
            dots: false,
            pauseOnHover: false,
            responsive: [
              {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 4
                }
            },
              {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
             {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1
                }
            }]
        });
      });

      
    }
    this.getPrice();
  })
}

// to navigate depending on login status
navigateAsPerLoginStatus(coin) {
  if (localStorage.getItem('credential')) {
    localStorage.setItem('animatedCoin', coin)
    this.router.navigateByUrl('basic-buy-sell/buy')
  } else {
    this.router.navigate(['login'])
  }
}

// to navigate depending on login status
navigateAsPerLoginStatus1(coin) {
  if (localStorage.getItem('credential')) {
    localStorage.setItem('animatedCoin', coin)
    this.router.navigateByUrl('basic-buy-sell/buy')
  } else {
    this.router.navigate(['login'])
  }
}
buysell(){
  this.router.navigateByUrl('advanced-exchange')
}
navigateToUrl(urlvalue){
  if (urlvalue=='huobi'){
    window.open('https://www.huobi.com/en-us/')
  }
  else if (urlvalue=='okblockchain'){
    window.open('https://www.okcoin.com/')
  }
  else if(urlvalue=='nodecapital'){
    window.open('http://www.nodecap.com/')
  }
  else if(urlvalue=='genesis'){
    window.open('https://www.genesisblockchain.io/')
  }
  else if(urlvalue=='plumventures'){
    window.open('http://www.plumventures.cn/')
  }
  else if(urlvalue=='playstore'){
    window.open('https://play.google.com/store')
  }
  else if(urlvalue=='appstore'){
    window.open('https://www.apple.com/in/app-store/')
  }
}
closeCookie(){
  if(this.showCookie!=null){
    this.showCookie=localStorage.getItem('cooki')
  
  }
  else{
   localStorage.setItem('cooki','cooki' )
   this.showCookie=localStorage.getItem('cooki')
  }

  
}

}
