import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqData: any;

  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getFaq()
  }

  getFaq(){
    // this.faqData = [
    //   {question:'What is Faq?', answer:'Frequently Asked Question'},
    //   {question:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, recusandae', answer:'  Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, recusandae.'},
    //   {question:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur neque quam magni?', answer:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur neque quam magni'},
    //   {question:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus harum corporis eaque unde? Et, dicta', answer:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus harum corporis eaque unde? Et, dicta!'},
    //   {question:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, atque modi', answer:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, atque modi.'},
    //   {question:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate inventore possimus doloribus error, eaque recusandae molestiae sequi illum nobis rem.', answer:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate inventore possimus doloribus error, eaque recusandae molestiae sequi illum nobis rem.'},

    // ]
    // return
    this.service.getCandyPixelForms('static/get-faq-list').subscribe(res=>{
           if (res['status']==200){
             this.faqData=res['data'];
           }
    })
   }
}
