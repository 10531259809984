import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {
  addAccountForm: FormGroup;

  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
    // this.getBankDetails()
  }
// Form Validation 
formValidation(){
  this.addAccountForm = new FormGroup({
    'accountHolderName': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
    'accountNumber': new FormControl('',[Validators.required]),
    'contactNumber': new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/i)]),
    'bankName': new FormControl('', [Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
    'swiftNumber': new FormControl('',[Validators.required]),
    'ibanNumber':new FormControl('',[Validators.required]),
    'ifsc':new FormControl('',[Validators.required]),
    'reEnterAccountNumber' : new FormControl('',[Validators.required]),

  })
}
// Contact Us Functionality
submitFunc(){
  let data={
    'accountNo': this.addAccountForm.value.accountNumber,
    'bankName': this.addAccountForm.value.bankName,
    'contactNo': this.addAccountForm.value.contactNumber,
    'accountHolderName': this.addAccountForm.value.accountHolderName,
    'swiftNo':this.addAccountForm.value.swiftNumber,
    'ibanNo':this.addAccountForm.value.ibanNumber,
    "ifsc": this.addAccountForm.value.ifsc,
    "imageUrl": ""
  }
this.service.showSpinner();
//this.service.postCandyPixelForms('static/submit-contact-us-request',data).subscribe(res=>{
this.service.postCandyPixelForms('account/add-user-bank-account',data).subscribe(res=>{

  if (res['status']==200){
    this.router.navigate(['/bank-list'])
    this.service.hideSpinner();
    this.service.showSuccessMessage('Successfully submitted, we will get back to you shortly!')
    this.addAccountForm.reset();
  }
}, err=>{
  this.service.hideSpinner();
})
}

// getBankDetails(){
//   this.service.getCandyPixelForms('account/get-user-account-detail?bankDetailId=74').subscribe(res=>{
//   })
// }
stopEvent(event: any) {
  // event.stopPropagation();
  event.preventDefault();
}
onRightClick() {
  return false;
}
onCopyPaste(event:KeyboardEvent) {
  console.log(event)
  if ((event.ctrlKey || event.metaKey) && event.charCode == 67){
  return false;
}
  if ((event.ctrlKey || event.metaKey) && event.charCode == 86){
  return false;
}
}
}
